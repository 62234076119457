export const harmonySensor = [
  {
    index: 100,
    categoryEng: 'Sensor',
    key: '触摸传感器',
    picture: require('../../img/harmaony/sensor/touchsensor.png'),
    port: '',
    value: 0,
  },
  {
    index: 101,
    categoryEng: 'Sensor',
    key: '光线传感器',
    picture: require('../../img/harmaony/sensor/optical_line.png'),
    port: '',
    value: 0,
  },
  {
    index: 102,
    categoryEng: 'Sensor',
    key: '温湿度传感器',
    picture: require('../../img/harmaony/sensor/temperature_humidity.png'),
    port: '',
    value: 0,
  },
  {
    index: 103,
    categoryEng: 'Sensor',
    key: '双路寻迹传感器',
    enName: 'harmonySensor.twowayGrayscale',
    picture: require('../../img/harmaony/sensor/twoway_grayscale.png'),
    port: '',
    value: 0,
  },
  {
    index: 104,
    categoryEng: 'Sensor',
    key: '超声波传感器',
    picture: require('../../img/harmaony/sensor/ultrasonic.png'),
    port: '',
    value: 0,
  },
  {
    index: 105,
    categoryEng: 'Sensor',
    key: '声音传感器',
    picture: require('../../img/harmaony/sensor/sound.png'),
    port: '',
    value: 0,
  },
  {
    index: 106,
    categoryEng: 'Sensor',
    key: '火焰传感器',
    picture: require('../../img/harmaony/sensor/flame.png'),
    port: '',
    value: 0,
  },
  {
    index: 107,
    categoryEng: 'Sensor',
    key: '土壤湿度传感器',
    picture: require('../../img/harmaony/sensor/soil.png'),
    port: '',
    value: 0,
  },
  {
    index: 108,
    categoryEng: 'Sensor',
    key: '颜色传感器',
    picture: require('../../img/harmaony/sensor/color.png'),
    port: '',
    value: 0,
  },
  {
    index: 109,
    categoryEng: 'Sensor',
    key: '倾斜传感器',
    picture: require('../../img/harmaony/sensor/incline.png'),
    port: '',
    value: 0,
  },
  // {
  //     id: 10,
  //     index: 110,
  //     category: "传感器",
  //     categoryEng: "Sensor",
  //     key: "红外测障传感器",
  //     judgeName: ["红外测障传感器"],
  //     picture: require("../../img/harmaony/sensor/infraredObstacle.png"),
  //     value: 0,
  // },
  {
    index: 111,
    categoryEng: 'Sensor',
    key: '烟雾传感器',
    picture: require('../../img/harmaony/sensor/smoke.png'),
    port: '',
    value: 0,
  },
  {
    index: 112,
    categoryEng: 'Sensor',
    key: '碰撞传感器',
    picture: require('../../img/harmaony/sensor/collision.png'),
    port: '',
    value: 0,
  },
  {
    index: 113,
    categoryEng: 'Sensor',
    key: '磁敏传感器',
    picture: require('../../img/harmaony/sensor/magnet_switch.png'),
    port: '',
    value: 0,
  },
  {
    index: 114,
    categoryEng: 'Sensor',
    key: '水位传感器',
    picture: require('../../img/harmaony/sensor/waterLevel.png'),
    port: '',
    value: 0,
  },
  {
    index: 115,
    categoryEng: 'Sensor',
    key: '人体红外传感器',
    picture: require('../../img/harmaony/sensor/human_infrared.png'),
    port: '',
    value: 0,
  },
  {
    index: 116,
    categoryEng: 'Sensor',
    key: '按钮传感器',
    picture: require('../../img/harmaony/sensor/button.png'),
    port: '',
    value: 0,
  },
  {
    index: 117,
    categoryEng: 'Sensor',
    key: '旋钮传感器',
    picture: require('../../img/harmaony/sensor/spin_button.png'),
    port: '',
    value: 0,
  },
  {
    index: 118,
    categoryEng: 'Sensor',
    key: '水滴传感器',
    picture: require('../../img/harmaony/sensor/waterDrop.png'),
    port: '',
    value: 0,
  },
  {
    index: 119,
    categoryEng: 'Sensor',
    key: '水流量传感器',
    picture: require('../../img/harmaony/sensor/spin_button.png'),
    port: '',
    value: 0,
  },
  {
    index: 120,
    categoryEng: 'Sensor',
    key: '薄膜压力传感器',
    picture: require('../../img/harmaony/sensor/thinFilmPressure.png'),
    port: '',
    value: 0,
  },
  {
    index: 121,
    categoryEng: 'Sensor',
    key: '大气压力传感器',
    picture: require('../../img/harmaony/sensor/atmosphericPressure.png'),
    port: '',
    value: 0,
  },
  {
    index: 122,
    categoryEng: 'Sensor',
    key: '粉尘浓度传感器',
    picture: require('../../img/harmaony/sensor/dust.png'),
    port: '',
    value: 0,
  },
  {
    index: 123,
    categoryEng: 'Sensor',
    key: '电子秤传感器',
    picture: require('../../img/harmaony/sensor/scale.png'),
    port: '',
    value: 0,
  },
  {
    index: 124,
    categoryEng: 'Sensor',
    key: '风速传感器',
    picture: require('../../img/harmaony/sensor/windSpeed.png'),
    port: '',
    value: 0,
  },
  {
    index: 125,
    categoryEng: 'Sensor',
    key: '风向传感器',
    picture: require('../../img/harmaony/sensor/windDirection.png'),
    port: '',
    value: 0,
  },
  {
    index: 126,
    categoryEng: 'Sensor',
    key: '单向通讯电表',
    picture: require('../../img/harmaony/sensor/electricMeter.png'),
    port: '',
    value: 0,
  },
  {
    index: 127,
    categoryEng: 'Sensor',
    key: '远传水表',
    picture: require('../../img/harmaony/sensor/waterMeter.png'),
    port: '',
    value: 0,
  },
]
