export const green_light = {
  App: [
    '#E5F0EF', // 0 | .-webkit-scrollbar-thumb
    '#ffffff', // 1 | .el-notification
    '#000000', // 2 | .el-notification__title
  ],
  index: [
    '#f6f7f6', // 0 | #index
    '#00695f', // 1 | .logo .st0
    '#FFFFFF', // 2 | .skinChange .st0 fill
    '#EBEBEB', // 3 | stroke
    '#00695F', // 4 | .skinChange .st1
    '#ffffff', // 5 | .login .st0 fill
    '#ebebeb', // 6 | stroke
    '#B9BDC1', // 7 | .login .st1
    '#00695F', // 8 | .login .st2
    '#ffffff', // 9 | .loggedDropdownMenu / .popper__arrow::after
    '#000000', // 10 | .el-dropdown-menu__item / &:not(.is-disabled):hover
    '#d5d5d5', // 11 | .item-box-color
    '#00695F', // 12 | .el-dialog / .square
    '#ffffff', // 13 | .dialog-title
    '#ffffff', // 14 | svg .st0
    '#F0F9F8', // 15 | .st1
    '#00695F', // 16 | .st2
    '#ffffff', // 17 | .el-dialog__close / &:hover
    '#F0F9F8', // 18 | .el-dialog__body
    '#aaaaaa', // 19 | .el-form-item__label
    '#606266', // 20 | .el-input__inner color
    '#ffffff', // 21 | background-color
    '#C0C4CC', // 22 | .el-input.is-disabled color
    '#F5F7FA', // 23 | background-color
    '#808080', // 24 | ::-webkit-input-placeholder
    '#00695F', // 25 | .el-button
    '#00695F', // 26 | .el-button--danger color
    '#C6E2DF', // 27 | background-color
    '#ffffff', // 28 | .el-select-dropdown / .popper__arrow
    '#000000', // 29 | .el-select-dropdown__item
    '#00695F', // 30 | .hover / .selected
    '#E5F0EF', // 31 | background-color
    '#ffffff', // 32 | .qosSelect / .hostSelect   .el-select-dropdown / .popper__arrow
    '#000000', // 33 | .el-select-dropdown__item
    '#00695F', // 34 | .hover / .selected
    '#E5F0EF', // 35 | background-color
    '#E5F0EF', // 36 | .confirm
    '#909399', // 37 | .el-message-box__close
    '#00695F', // 38 | .el-button--small
    '#ffffff', // 39 | .el-button--small
    '#ffffff', // 40 | .rightClickMenu.menu
    '#000000', // 41 | .menu_item
    '#00695F', // 42 | &:hover color
    '#CCE1DF', // 43 | background-color
  ],
  loginView: [
    '#00695F', // 0 | .login_box / .toRegBtn / .el-button / .el-dialog / .square / .el-tabs__item.is-active / .el-button bgc / .resetBtnList color、bgc / .register.el-form-item
    '#ffffff', // 1 | .login_box_right
    '#909399', // 2 | .el-icon-close
    '#1a1a1a', // 3 | .el-input__inner color
    '#F0F9F8', // 4 | background-color
    '#ffffff', // 5 | .dialog-title
    '#ffffff', // 6 | svg .st0
    '#F0F9F8', // 7 | .st1
    '#00695F', // 8 | .st2
    '#F0F9F8', // 9 | .el-dialog__body
    '#000000', // 10 | .el-tabs__item
    '#ffffff', // 11 | .el-button
    '#c0c4cc', // 12 | .el-button.is-disabled color
    '#ffffff', // 13 | background-color
    '#1a1a1a', // 14 | el-input__inner color
    '#ffffff', // 15 | background-color
    '#ffffff', // 16 | .resetBtnList.el-button
    '#ffffff', // 17 | &:first-child color
  ],
  leftbar: [
    '#ffffff', // 0 | .central
    '#ffffff', // 1 | i color
    '#EBEBEB', // 2 | background-color
    '#00695F', // 3 | &:hover
    '#EBEBEB', // 4 | .centralLine
    '#000000', // 5 | .joinGroup / .mqttAndTopic
    '#ffffff', // 6 | .title color
    '#00695F', // 7 | background-color
    '#ffffff', // 8 | svg
    '#ffffff', // 9 | .connectionList
    '#00695F', // 10 | .connectionType
    '#00695F', // 11 | .svg .st0
    '#A7A7A7', // 12 | .connectionItem color
    '#EBEBEB', // 13 | background-color
    '#A7A7A7', // 14 | .connectionLine / .el-icon-more
    '#00695F', // 15 | .groupActive color / .connectionActive / .connected / .connectedLine
    '#CCE1DF', // 16 | background-color
    '#E5F0EF', // 17 | .addBtn
    '#00695F', // 18 | .el-icon-plus
    '#EBEBEB', // 19 | .mqttAndTopicLine
    '#ffffff', // 20 | .connectionDropdownMenu
    '#000000', // 21 | .el-dropdown-menu__item
    '#00695F', // 22 | .el-dropdown-menu__item:focus color
    '#CCE1DF', // 23 | background-color
  ],
  mainview: [
    '#00695F', // 0 | .tab  color
    '#00695F', // 1 | background-color
    '#ffffff', // 2 | .tabname
    '#00695F', // 3 | .st0
    '#00695F', // 4 | .tabnameLine
    '#ffffff', // 5 | .active
    '#00695F', // 6 | .active > div
    '#ffffff', // 7 | .st0
  ],
  dataMonitor: [
    '#ffffff', // 0 | > div
    '#A7A2A2', // 1 | .time
    '#ffffff', // 2 | .clientId color
    '#ABAFB4', // 3 | background-color
    '#000000', // 4 | .keyBox color
    '#EBEBEB', // 5 | background-color / .triangle
    '#A7A2A2', // 6 | .name
    '#ffffff', // 7 | .send .clientId color
    '#00695F', // 8 | background-color
    '#E5F0EF', // 9 | .send .keyBox / .triangle
    '#E5F0EF', // 10 | #send
    '#898B90', // 11 | .formLabel
    '#000000', // 12 | .el-input.is-focus .el-input__inner color
    '#ffffff', // 13 | background-color
    '#00695F', // 14 | .el-button
  ],
  dataDispose: [
    '#ffffff', // 0 | .main
    '#00695F', // 1 | .title / .st0
    '#E5F0EF', // 2 | .sensorItem
    '#00695F', // 3 | .el-button
    '#00695F', // 4 | .value
    '#00695F', // 5 | .name / .key
    '#d9d9d9', // 6 | .line
    '#00695F', // 7 | .el-dialog.addExtendNew
    '#ffffff', // 8 | .dialog-title
    '#ffffff', // 9 | svg .st0
    '#F0F9F8', // 10 | .st1
    '#00695F', // 11 | .st2
    '#00695F', // 12 | .square
    '#ffffff', // 13 | .el-dialog__close
    '#F0F9F8', // 14 | .el-dialog__body
    '#ffffff', // 15 | .tabName
    '#F0F9F8', // 16 | .tabNameBox
    '#F0F9F8', // 17 | .nameImg border
    '#ffffff', // 18 | background-color
    '#F0F9F8', // 19 | &.tabActive
    '#ffffff', // 20 | .tabNameBox
    '#ffffff', // 21 | .tabContent
    '#F0F9F8', // 22 | #equ_inner_itemes
    '#00695F', // 23 | svg .st0
    '#ffffff', // 24 | .st1
    '#000000', // 25 | .itemTitles
    '#00695F', // 26 | .borders
    '#ffffff', // 27 | .cancel_btn / .confirm_btn color
    '#00695F', // 28 | background-color
    '#00695F', // 29 | .cancel_btn
    '#ffffff', // 30 | background-color
  ],
  dataQuery: [
    '#E5F0EF', // 0 | > div / .el-form background-color
    '#ffffff', // 1 | border-bottom
    '#00695F', // 2 | .el-form-item__label
    '#000000', // 3 | .el-range-editor .el-range-input / .el-input__inner color
    '#ffffff', // 4 | background-color
    '#303133', // 5 | .el-date-editor .el-range-separator
    '#00695F', // 6 | .el-button
    '#000000', // 7 | .queryTableBox .el-input__inner color
    '#dcdfe6', // 8 | border-color
    '#000000', // 9 | .el-select__caret
    '#00695F', // 10 | .el-input.is-focus .el-input__inner
    '#000000', // 11 | > button
    '#00695F', // 12 | &:hover
    '#000000', // 13 | .el-pager li
    '#00695F', // 14 | &:hover
    '#00695F', // 15 | .active
    '#ebeef5', // 16 | .el-table.el-table--border
    '#ebeef5', // 17 | .is-leaf / &.el-table--border::after / th.el-table__cell.gutter:last-of-type
    '#dcdfe6', // 18 | .el-checkbox__inner border
    '#ffffff', // 19 | background-color
    '#00695F', // 20 | .el-checkbox__input.is-checked .el-checkbox__inner
    '#dcdfe6', // 21 | .el-checkbox__inner:hover
    '#ffffff', // 22 | .el-checkbox__inner::after
    '#686868', // 23 | th.el-table__cell color
    '#E5F0EF', // 24 | background-color
    '#ebeef5', // 25 | .el-table__cell
    '#686868', // 26 | td.el-table__cell color
    '#ffffff', // 27 | background-color
    '#ebeef5', // 28 | border-bottom
    '#fafafa', // 29 | tr.el-table__row--striped td.el-table__cell
    '#f5f7fa', // 30 | tr:hover > td.el-table__cell
    '#ffffff', // 31 | .el-table__body-wrapper
    '#606266', // 32 | .el-picker-panel.el-date-range-picker color
    '#ffffff', // 33 | background-color / .popper__arrow / &::after
    '#e4e4e4', // 34 | .el-picker-panel__body-wrapper .el-picker-panel__sidebar order-color
    '#ffffff', // 35 | background-color
    '#606266', // 36 | .el-picker-panel__shortcut
    '#00695F', // 37 | &:hover
    '#e4e4e4', // 38 | .el-picker-panel__body .el-date-range-picker__time-header
    '#000000', // 39 | .el-input__inner color
    '#ffffff', // 40 | background-color / border-color
    '#00695F', // 41 | &:focus
    '#e4e7ed', // 42 | .el-time-panel border-color
    '#ffffff', // 43 | background-color
    '#e4e7ed', // 44 | el-time-panel__content &::before
    '#606266', // 45 | .el-time-spinner__item
    '#303133', // 46 | &.active
    '#f5f7fa', // 47 | &:hover:not(.disabled):not(.active)
    '#e4e4e4', // 48 | .el-time-panel__footer
    '#303133', // 49 | .el-time-panel__btn
    '#00695F', // 50 | &.confirm
    '#303133', // 51 | .el-icon-arrow-right
    '#e4e4e4', // 52 | .el-date-range-picker__content &.is-left
    '#303133', // 53 | .el-picker-panel__icon-btn
    '#00695F', // 54 | &:hover
    '#ebeef5', // 55 | .el-date-table th
    '#606266', // 56 | color
    '#00695F', // 57 | td &.available:hover
    '#c0c4cc', // 58 | &.next-month
    '#00695F', // 59 | &.today
    '#ffffff', // 60 | &.start-date color
    '#00695F', // 61 | background-color
    '#f2f6fc', // 62 | &.in-range
    '#e4e4e4', // 63 | .el-picker-panel__footer border-color
    '#ffffff', // 64 | background-color
    '#00695F', // 65 | .el-button
    '#606266', // 66 | .el-button.is-plain color
    '#dcdfe6', // 67 | border-color
    '#ffffff', // 68 | background-color
    '#00695F', // 69 | &:hover color
    '#00695F', // 70 | border-color
    '#ffffff', // 71 | background-color
    '#ffffff', // 72 | .queryPage / .popper__arrow / &::after
    '#000000', // 73 | .el-scrollbar__wrap .el-select-dropdown__item
    '#00695F', // 74 | &.hover color
    '#f5f7fa', // 75 | background-color
    '#00695F', // 76 | .el-select-dropdown__item.selected
  ],
  logModule: [
    '#ffffff', // 0 | i color
    '#EBEBEB', // 1 | background-color
    '#00695F', // 2 | &:hover
    '#ffffff', // 3 | .tabname color
    '#00695F', // 4 | background-color
    '#ffffff', // 5 | .st0
    '#ffffff', // 6 | .btnList
    '#6c948e', // 7 | svg .st0
    '#00695F', // 8 | svg:hover .st0
    '#828fc3', // 9 | div
    '#ffffff', // 10 | .logTableBox
  ],
}
