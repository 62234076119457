export const harmonyActuator = [
  {
    index: 200,
    categoryEng: 'Actuator',
    key: '9g舵机',
    picture: require('../../img/harmaony/actuator/steerengine.png'),
    port: '',
    value: 0,
  },
  {
    index: 201,
    categoryEng: 'Actuator',
    key: 'TT马达',
    picture: require('../../img/harmaony/actuator/TTMotor.png'),
    port: '',
    value: 0,
  },
  {
    index: 202,
    categoryEng: 'Actuator',
    key: '风扇',
    picture: require('../../img/harmaony/actuator/fan.png'),
    port: '',
    value: 0,
  },
  {
    index: 203,
    categoryEng: 'Actuator',
    key: '蜂鸣器',
    picture: require('../../img/harmaony/actuator/buzzer.png'),
    port: '',
    value: 0,
  },
  {
    index: 204,
    categoryEng: 'Actuator',
    key: 'LED',
    picture: require('../../img/harmaony/actuator/LED.png'),
    port: '',
    value: 0,
  },
  {
    index: 205,
    categoryEng: 'Actuator',
    key: '数码管',
    picture: require('../../img/harmaony/actuator/nixieTube.png'),
    port: '',
    value: 0,
  },
  {
    index: 206,
    categoryEng: 'Actuator',
    key: '全彩灯',
    picture: require('../../img/harmaony/actuator/multicolorLight.png'),
    port: '',
    value: 0,
  },
  {
    index: 207,
    categoryEng: 'Actuator',
    key: '4x4RGB点阵',
    picture: require('../../img/harmaony/actuator/RGB4.png'),
    port: '',
    value: 0,
  },
  {
    index: 208,
    categoryEng: 'Actuator',
    key: '灯串',
    picture: require('../../img/harmaony/actuator/SL_drive.png'),
    port: '',
    value: 0,
  },
  {
    index: 209,
    categoryEng: 'Actuator',
    key: '扬声器',
    picture: require('../../img/harmaony/actuator/speaker.png'),
    port: '',
    value: 0,
  },
  {
    index: 210,
    categoryEng: 'Actuator',
    key: '水泵',
    picture: require('../../img/harmaony/actuator/waterPump.png'),
    port: '',
    value: 0,
  },
  {
    index: 211,
    categoryEng: 'Actuator',
    key: 'Geekservo舵机',
    picture: require('../../img/harmaony/actuator/Geekservo.png'),
    port: '',
    value: 0,
  },
  {
    index: 212,
    categoryEng: 'Actuator',
    key: '四位时钟数码管',
    picture: require('../../img/harmaony/actuator/clockNixieTube.png'),
    port: '',
    value: 0,
  },
  {
    index: 213,
    categoryEng: 'Actuator',
    key: 'OLED显示屏',
    picture: require('../../img/harmaony/actuator/displayScreen.png'),
    port: '',
    value: 0,
  },
  {
    index: 214,
    categoryEng: 'Actuator',
    key: '继电器',
    picture: require('../../img/harmaony/actuator/relay.png'),
    port: '',
    value: 0,
  },
  {
    index: 215,
    categoryEng: 'Actuator',
    key: '加湿器',
    picture: require('../../img/harmaony/actuator/humidifier.png'),
    port: '',
    value: 0,
  },
]
