export const blue_dark = {
  App: [
    '#434755', // 0 | .-webkit-scrollbar-thumb
    '#4E5174', // 1 | .el-notification
    '#ffffff', // 2 | .el-notification__title
  ],
  index: [
    '#0e0e2c', // 0 | #index
    '#ffffff', // 1 | .logo .st0
    '#33354D', // 2 | .skinChange .st0 fill
    '#474B68', // 3 | stroke
    '#014fff', // 4 | .skinChange .st1
    '#33354D', // 5 | .login .st0 fill
    '#474B68', // 6 | stroke
    '#5C647A', // 7 | .login .st1
    '#014fff', // 8 | .login .st2
    '#5C6479', // 9 | .loggedDropdownMenu / .popper__arrow::after
    '#ffffff', // 10 | .el-dropdown-menu__item / &:not(.is-disabled):hover
    '#E8EBEF', // 11 | .item-box-color
    '#014fff', // 12 | .el-dialog / .square
    '#ffffff', // 13 | .dialog-title
    '#ffffff', // 14 | svg .st0
    '#C8C8D1', // 15 | .st1
    '#014fff', // 16 | .st2
    '#ffffff', // 17 | .el-dialog__close / &:hover
    '#eeeeee', // 18 | .el-dialog__body
    '#000000', // 19 | .el-form-item__label
    '#1a1a1a', // 20 | .el-input__inner color
    '#FFFFFF', // 21 | background-color
    '#8C8E93', // 22 | .el-input.is-disabled color
    '#F5F5F9', // 23 | background-color
    '#8C8E93', // 24 | ::-webkit-input-placeholder
    '#014fff', // 25 | .el-button
    '#ffffff', // 26 | .el-button--danger color
    '#aaaaaa', // 27 | background-color
    '#4E5174', // 28 | .el-select-dropdown / .popper__arrow
    '#ffffff', // 29 | .el-select-dropdown__item
    '#ffffff', // 30 | .hover / .selected
    '#434755', // 31 | background-color
    '#ffffff', // 32 | .qosSelect / .hostSelect   .el-select-dropdown / .popper__arrow
    '#000000', // 33 | .el-select-dropdown__item
    '#014fff', // 34 | .hover / .selected
    '#F5F5F9', // 35 | background-color
    '#eeeeee', // 36 | .confirm
    '#909399', // 37 | .el-message-box__close
    '#014fff', // 38 | .el-button--small
    '#ffffff', // 39 | .el-button--small
    '#4E5174', // 40 | .rightClickMenu.menu
    '#ffffff', // 41 | .menu_item
    '#ffffff', // 42 | &:hover color
    '#0e0e2c', // 43 | background-color
  ],
  loginView: [
    '#014fff', // 0 | .login_box / .toRegBtn / .el-button / .el-dialog / .square / .el-tabs__item.is-active / .el-button bgc / .resetBtnList color、bgc / .register.el-form-item
    '#eeeeee', // 1 | .login_box_right
    '#8C8E93', // 2 | .el-icon-close
    '#000000', // 3 | .el-input__inner color
    '#ffffff', // 4 | background-color
    '#ffffff', // 5 | .dialog-title
    '#ffffff', // 6 | svg .st0
    '#C8C8D1', // 7 | .st1
    '#014fff', // 8 | .st2
    '#eeeeee', // 9 | .el-dialog__body
    '#000000', // 10 | .el-tabs__item
    '#ffffff', // 11 | .el-button
    '#c0c4cc', // 12 | .el-button.is-disabled color
    '#f5f5f9', // 13 | background-color
    '#1a1a1a', // 14 | el-input__inner color
    '#ffffff', // 15 | background-color
    '#ffffff', // 16 | .resetBtnList.el-button
    '#ffffff', // 17 | &:first-child color
  ],
  leftbar: [
    '#17192C', // 0 | .central
    '#ffffff', // 1 | i color
    '#3e4360', // 2 | background-color
    '#014fff', // 3 | &:hover
    '#5C6574', // 4 | .centralLine
    '#ffffff', // 5 | .joinGroup / .mqttAndTopic
    '#ffffff', // 6 | .title color
    '#014fff', // 7 | background-color
    '#ffffff', // 8 | svg
    '#17192C', // 9 | .connectionList
    '#ffffff', // 10 | .connectionType
    '#ffffff', // 11 | .svg .st0
    '#8C8E93', // 12 | .connectionItem color
    '#33354D', // 13 | background-color
    '#8C8E93', // 14 | .connectionLine / .el-icon-more
    '#ffffff', // 15 | .groupActive color / .connectionActive / .connected / .connectedLine
    '#00219B', // 16 | background-color
    '#33354D', // 17 | .addBtn
    '#ffffff', // 18 | .el-icon-plus
    '#5C6574', // 19 | .mqttAndTopicLine
    '#4E5174', // 20 | .connectionDropdownMenu
    '#ffffff', // 21 | .el-dropdown-menu__item
    '#ffffff', // 22 | .el-dropdown-menu__item:focus color
    '#0e0e2c', // 23 | background-color
  ],
  mainview: [
    '#ffffff', // 0 | .tab  color
    '#014fff', // 1 | background-color
    '#3e4360', // 2 | .tabname
    '#ffffff', // 3 | .st0
    '#ffffff', // 4 | .tabnameLine
    '#ffffff', // 5 | .active
    '#014fff', // 6 | .active > div
    '#ffffff', // 7 | .st0
  ],
  dataMonitor: [
    '#17192C', // 0 | > div
    '#A7A2A2', // 1 | .time
    '#ffffff', // 2 | .clientId color
    '#5D6477', // 3 | background-color
    '#ffffff', // 4 | .keyBox color
    '#33354D', // 5 | background-color / .triangle
    '#AAA5A5', // 6 | .name
    '#ffffff', // 7 | .send .clientId color
    '#014FFF', // 8 | background-color
    '#00219B', // 9 | .send .keyBox / .triangle
    '#33354D', // 10 | #send
    '#8C8E93', // 11 | .formLabel
    '#ffffff', // 12 | .el-input.is-focus .el-input__inner
    '#17192C', // 13 | background-color
    '#014fff', // 14 | .el-button
  ],
  dataDispose: [
    '#17192C', // 0 | .main
    '#ffffff', // 1 | .title / .st0
    '#33354D', // 2 | .sensorItem
    '#014fff', // 3 | .el-button
    '#ffffff', // 4 | .value
    '#ffffff', // 5 | .name / .key
    '#33354D', // 6 | .line
    '#014fff', // 7 | .el-dialog.addExtendNew
    '#ffffff', // 8 | .dialog-title
    '#ffffff', // 9 | svg .st0
    '#C8C8D1', // 10 | .st1
    '#014fff', // 11 | .st2
    '#014fff', // 12 | .square
    '#ffffff', // 13 | .el-dialog__close
    '#eeeeee', // 14 | .el-dialog__body
    '#ffffff', // 15 | .tabName
    '#eeeeee', // 16 | .tabNameBox
    '#eeeeee', // 17 | .nameImg border
    '#ffffff', // 18 | background-color
    '#eeeeee', // 19 | &.tabActive
    '#ffffff', // 20 | .tabNameBox
    '#ffffff', // 21 | .tabContent
    '#eeeeee', // 22 | #equ_inner_itemes
    '#014fff', // 23 | svg .st0
    '#ffffff', // 24 | .st1
    '#000000', // 25 | .itemTitles
    '#014fff', // 26 | .borders
    '#ffffff', // 27 | .cancel_btn / .confirm_btn color
    '#014fff', // 28 | background-color
    '#014fff', // 29 | .cancel_btn
    '#ffffff', // 30 | background-color
  ],
  dataQuery: [
    '#33354d', // 0 | > div / .el-form background-color
    '#5c6574', // 1 | border-bottom
    '#8c8e93', // 2 | .el-form-item__label
    '#8c8e93', // 3 | .el-range-editor .el-range-input / .el-input__inner color
    '#0e0f2a', // 4 | background-color
    '#8c8e93', // 5 | .el-date-editor .el-range-separator
    '#014fff', // 6 | .el-button
    '#ffffff', // 7 | .queryTableBox .el-input__inner color
    '#8c8e93', // 8 | border-color
    '#ffffff', // 9 | .el-select__caret
    '#ffffff', // 10 | .el-input.is-focus .el-input__inner
    '#8c8e93', // 11 | > button
    '#ffffff', // 12 | &:hover
    '#8c8e93', // 13 | .el-pager li
    '#ffffff', // 14 | &:hover
    '#ffffff', // 15 | .active
    '#33354d', // 16 | .el-table.el-table--border
    '#5c6574', // 17 | .is-leaf / &.el-table--border::after / th.el-table__cell.gutter:last-of-type
    '#abafb4', // 18 | .el-checkbox__inner border
    '#8c8e93', // 19 | background-color
    '#014fff', // 20 | .el-checkbox__input.is-checked .el-checkbox__inner
    '#abafb4', // 21 | .el-checkbox__inner:hover
    '#ffffff', // 22 | .el-checkbox__inner::after
    '#898b90', // 23 | th.el-table__cell color
    '#33354d', // 24 | background-color
    '#5c6574', // 25 | .el-table__cell
    '#898b90', // 26 | td.el-table__cell color
    '#1d1f33', // 27 | background-color
    '#5c6574', // 28 | border-bottom
    '#17192c', // 29 | tr.el-table__row--striped td.el-table__cell
    '#23263f', // 30 | tr:hover > td.el-table__cell
    '#0e0e2c', // 31 | .el-table__body-wrapper
    '#B0B5EF', // 32 | .el-picker-panel.el-date-range-picker color
    '#33354F', // 33 | background-color / .popper__arrow / &::after
    '#17192D', // 34 | .el-picker-panel__body-wrapper .el-picker-panel__sidebar order-color
    '#33354F', // 35 | background-color
    '#B0B5EF', // 36 | .el-picker-panel__shortcut
    '#ffffff', // 37 | &:hover
    '#17192D', // 38 | .el-picker-panel__body .el-date-range-picker__time-header
    '#ffffff', // 39 | .el-input__inner color
    '#17192D', // 40 | background-color / border-color
    '#17192D', // 41 | &:focus
    '#4B4E7C', // 42 | .el-time-panel border-color
    '#4B4E7C', // 43 | background-color
    '#B0B5EF', // 44 | el-time-panel__content &::before
    '#B0B5EF', // 45 | .el-time-spinner__item
    '#ffffff', // 46 | &.active
    '#33354F', // 47 | &:hover:not(.disabled):not(.active)
    '#B0B5EF', // 48 | .el-time-panel__footer
    '#B0B5EF', // 49 | .el-time-panel__btn
    '#ffffff', // 50 | &.confirm
    '#ffffff', // 51 | .el-icon-arrow-right
    '#17192D', // 52 | .el-date-range-picker__content &.is-left
    '#B0B5EF', // 53 | .el-picker-panel__icon-btn
    '#ffffff', // 54 | &:hover
    '#17192D', // 55 | .el-date-table th
    '#B0B5EF', // 56 | color
    '#ffffff', // 57 | td &.available:hover
    '#6168AF', // 58 | &.next-month
    '#ffffff', // 59 | &.today
    '#ffffff', // 60 | &.start-date color
    '#014fff', // 61 | background-color
    '#17192D', // 62 | &.in-range
    '#17192D', // 63 | .el-picker-panel__footer border-color
    '#33354F', // 64 | background-color
    '#B0B5EF', // 65 | .el-button
    '#ffffff', // 66 | .el-button.is-plain color
    '#4B4E7C', // 67 | border-color
    '#4B4E7C', // 68 | background-color
    '#ffffff', // 69 | &:hover color
    '#6168AF', // 70 | border-color
    '#6168AF', // 71 | background-color
    '#4e5174', // 72 | .queryPage / .popper__arrow / &::after
    '#8c8e93', // 73 | .el-scrollbar__wrap .el-select-dropdown__item
    '#ffffff', // 74 | &.hover color
    '#0e0e2c', // 75 | background-color
    '#ffffff', // 76 | .el-select-dropdown__item.selected
  ],
  logModule: [
    '#ffffff', // 0 | i color
    '#3e4360', // 1 | background-color
    '#014fff', // 2 | &:hover
    '#ffffff', // 3 | .tabname color
    '#014fff', // 4 | background-color
    '#ffffff', // 5 | .st0
    '#3e4360', // 6 | .btnList
    '#898b90', // 7 | svg .st0
    '#ffffff', // 8 | svg:hover .st0
    '#898b90', // 9 | div
    '#17192c', // 10 | .logTableBox
  ],
}
