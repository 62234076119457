<template>
  <div id="dataMonitor">
    <div>
      <div id="receive">
        <div v-for="(item, index) in mqttMessage" :key="index">
          <div class="message" v-if="item.type == 'receive' && item.topic == nowTopic">
            <div class="time">{{ item.time }}</div>
            <div class="clientId" :title="item.clientId" style="left: 4.8%;">
              {{ item.clientId ? item.clientId : '未知的Client ID' }}
            </div>
            <div class="keyBox" @contextmenu.prevent="onContextmenu(index)">
              <div>
                <div><span class="name">Topic:</span>{{ item.topic }}</div>
                <div><span class="name">QoS:</span>{{ item.qos }}</div>
                <div><span class="name">Port:</span>{{ item.port }}</div>
                <div><span class="name">Key:</span>{{ item.key }}</div>
              </div>
              <div>
                <div class="name">Value:</div>
                <div>{{ item.value }}</div>
              </div>
              <div class="triangle" style="left: -0.1rem;"></div>
            </div>
          </div>
          <div class="message send" v-else-if="item.type == 'send' && item.topic == nowTopic">
            <div class="time">{{ item.time }}</div>
            <div class="clientId" :title="item.clientId">
              {{ item.clientId ? item.clientId : '发送格式有误' }}
            </div>
            <div class="keyBox" @contextmenu.prevent="onContextmenu(index)">
              <div>
                <div><span class="name">Topic:</span>{{ item.topic }}</div>
                <div><span class="name">QoS:</span>{{ item.qos }}</div>
                <div><span class="name">Port:</span>{{ item.port }}</div>
                <div><span class="name">Key:</span>{{ item.key }}</div>
              </div>
              <div>
                <div class="name">Value:</div>
                <div>{{ item.value }}</div>
              </div>
              <div class="triangle" style="right: -0.1rem;"></div>
            </div>
          </div>
        </div>
      </div>
      <div id="send">
        <div class="sendForm">
          <div class="formTop">
            <div class="formLabel">Topic:</div>
            <el-input v-model="publish.topic"></el-input>
            <div class="formLabel">Qos:</div>
            <el-select popper-class="qosSelect1" v-model="publish.qos">
              <el-option :label="0" :value="0"></el-option>
              <el-option :label="1" :value="1"></el-option>
              <el-option :label="2" :value="2"></el-option>
            </el-select>
            <div class="formLabel">Port:</div>
            <el-input v-model="publish.port" style="width: 50%"></el-input>
            <div class="formLabel">Key:</div>
            <el-autocomplete
              v-model="publish.key"
              :fetch-suggestions="querySearchAsync"
              style="width: 80%"
            ></el-autocomplete>
          </div>
          <div class="formBottom">
            <div class="formLabel">Value:</div>
            <el-input ref="publishValueInput" @keyup.enter.native="doPublish()" v-model="publish.value"></el-input>
          </div>
        </div>

        <el-button type="primary" @click="doPublish()">发 送</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { sensorActuatorName } from '../assets/js/harmonyAdvance/sensorActuatorName'

export default {
  name: 'dataMonitor',
  data() {
    return {
      restaurants: sensorActuatorName,
      publish: {
        time: '',
        qos: 0,
        topic: '',
        port: '',
        key: '',
        value: '',
      },
      qosList: [0, 1, 2],
      // 滚动条默认最下
      oldScrollTop: 0,
      scrollFlag: true,
      Intervall: null,
    }
  },
  props: ['receiveNews', 'connectionListIndex'],
  watch: {
    receiveNews(value) {
      value['type'] = 'receive'
      this.setMqttMessage(value)
      // 调用保持滚动条方法
      if (this.scrollFlag) {
        this.scrollToBottom()
      }
    },
    nowTopic(val) {
      this.publish.topic = val
    },
  },
  computed: {
    ...mapState(['mqttList', 'nowMqttIndex', 'nowTopic', 'mqttMessage']),
    receivefilter: function() {
      return this.mqttMessage.filter(item => {
        return item.type == 'receive'
      })
    },
    sendfilter: function() {
      return this.mqttMessage.filter(item => {
        return item.type == 'send'
      })
    },
  },
  mounted() {
    //监听滚动事件
    document.querySelector('#receive').addEventListener('scroll', this.scrolling)
  },
  methods: {
    ...mapMutations(['setMqttMessage', 'delMqttMessage']),

    getNowDate() {
      let myDate = new Date()
      return (
        myDate.getFullYear() +
        '-' +
        [myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1] +
        '-' +
        [myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()] +
        ' ' +
        [myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours()] +
        ':' +
        [myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes()] +
        ':' +
        [myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds()]
      )
    },

    //滚动条保持最底部方法
    scrollToBottom() {
      this.$nextTick(() => {
        let container = this.$el.querySelector('#receive')
        container.scrollTop = container.scrollHeight
      })
    },

    scrolling() {
      let scrollTop = document.getElementById('receive').scrollTop
      // 更新——滚动前，滚动条距文档顶部的距离
      let scrollStep = scrollTop - this.oldScrollTop
      this.oldScrollTop = scrollTop
      //判断当前是向上or向下滚动
      if (scrollStep < 0) {
        //向上
        this.scrollFlag = false
      } else {
        //向下
        this.scrollFlag = true
      }
    },

    // 搜索过滤
    querySearchAsync(queryString, cb) {
      var restaurants = this.restaurants
      var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants

      cb(results)
    },
    createStateFilter(queryString) {
      return state => {
        return state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },

    // 右键菜单
    onContextmenu(data) {
      this.$contextmenu({
        items: [
          {
            label: '删除',
            onClick: () => {
              // 删除该条消息
              this.delMqttMessage(data)
            },
          },
        ],
        event,
        x: event.clientX,
        y: event.clientY,
        customClass: 'rightClickMenu',
        zIndex: 3,
        minWidth: 50,
      })
      return false
    },

    // 发布消息到···主题
    doPublish() {
      if (this.mqttList.length > 0 && this.mqttList[this.nowMqttIndex].client.connected) {
        this.publish.server =
          this.mqttList[this.nowMqttIndex].protocol +
          '://' +
          this.mqttList[this.nowMqttIndex].host +
          ':' +
          this.mqttList[this.nowMqttIndex].port
        this.publish.time = this.getNowDate()
        this.publish.clientId = this.mqttList[this.nowMqttIndex].clientId
        let obj = JSON.parse(JSON.stringify(this.publish))
        const { topic, qos } = obj
        this.$post('/concentreview/text', { text: JSON.stringify(obj.value) })
          .then(res => {
            if (res.code == 200) {
              if (res.data.conclusion_type == 1) {
                this.mqttList[this.nowMqttIndex].client.publish(topic, JSON.stringify(obj), { qos }, error => {
                  if (error) {
                    this.$notify.error({
                      title: '发送失败',
                      message: error,
                    })
                  } else {
                    obj.type = 'send'
                    this.setMqttMessage(obj)
                    this.publish.value = ''
                    this.$refs.publishValueInput.blur()
                  }
                })
              } else {
                this.$notify({
                  type: 'warning',
                  title: '警告',
                  message: '发送内容存在敏感词汇',
                })
              }
            } else {
              this.$notify.error({
                title: '报错',
                message: '内容审核失败',
              })
            }
          })
          .catch(err => {
            this.$notify.error({
              title: '报错',
              message: err,
            })
          })
      } else {
        this.$notify({
          type: 'warning',
          title: '警告',
          message: '客户端未连接',
        })
      }
    },
    handleClick() {},
    removeTab() {},
  },
}
</script>
<style lang="scss" scoped>
#dataMonitor {
  height: calc(100% - 0.625rem);
  width: 100%;
  padding: 0 0.125rem 0.125rem 0;
  margin-top: 0.625rem;
  font-size: 0.2rem;
  > div {
    height: 100%;
    background-color: var(--dataMonitor0);
    box-shadow: 0.0125rem 0.0125rem 0.0625rem 0 rgba(0, 0, 0, 0.25);
    border-radius: 0 0.0625rem 0.0625rem 0.0625rem;
    #receive {
      height: calc(100% - 1.5rem);
      overflow: auto;
      border-radius: 0 0.0625rem 0 0;
      .message {
        position: relative;
        min-height: 1.75rem;
        .time {
          font-size: 0.2rem;
          color: var(--dataMonitor1);
          margin: 0.125rem 0 0.125rem;
        }
        .clientId {
          position: absolute;
          top: 0.375rem;
          width: 15.9%;
          height: 0.75rem;
          line-height: 0.75rem;
          font-size: 0.2rem;
          padding: 0 0.125rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          border-radius: 0.0625rem;
          color: var(--dataMonitor2);
          background-color: var(--dataMonitor3);
        }
        .keyBox {
          position: relative;
          min-height: 1rem;
          font-size: 0.2rem;
          margin: 0 auto;
          padding: 0.225rem 0.3125rem;
          width: 55%;
          border-radius: 0.0625rem;
          color: var(--dataMonitor4);
          background-color: var(--dataMonitor5);
          .name {
            color: var(--dataMonitor6);
            margin-right: 0.125rem;
          }
          > div:first-child {
            display: flex;
            justify-content: space-between;
            flex-flow: row wrap;
            margin-bottom: 0.1875rem;
          }
          > div:nth-child(2) {
            display: flex;
            text-align: left;
            > div:last-child {
              width: 90%;
              word-break: break-all;
            }
          }
        }
        .triangle {
          position: absolute;
          top: 0.25rem;
          height: 0.25rem;
          width: 0.25rem;
          background-color: var(--dataMonitor5);
          transform: rotate(45deg);
        }
      }
      .send {
        .clientId {
          right: 4.8%;
          color: var(--dataMonitor7);
          background-color: var(--dataMonitor8);
        }
        .keyBox {
          background-color: var(--dataMonitor9);
          .triangle {
            background-color: var(--dataMonitor9);
          }
        }
      }
    }
    #send {
      position: relative;
      width: 100%;
      height: 1.5rem;
      background-color: var(--dataMonitor10);
      border-radius: 0 0 0.0625rem 0.0625rem;
      .sendForm {
        height: 100%;
        width: calc(100% - 2.625rem);
        font-size: 0.2rem;
        padding-top: 0.25rem;
        .formLabel {
          color: var(--dataMonitor11);
          line-height: 0.4375rem;
          margin-right: 0.125rem;
        }
        .formTop {
          display: flex;
          justify-content: flex-end;
          padding-left: 0.25rem;
          .formLabel {
            margin-left: 0.25rem;
          }
        }
        .formBottom {
          display: flex;
          justify-content: flex-end;
          margin-top: 0.125rem;
          .el-input {
            width: calc(100% - 1.175rem);
          }
        }
        .el-input::v-deep,
        .el-select::v-deep,
        .el-autocomplete::v-deep {
          font-size: 0.2rem;
          .el-input__inner,
          .el-input.is-focus .el-input__inner {
            font-size: 0.2rem;
            height: 0.4375rem;
            line-height: 0.4375rem;
            color: var(--dataMonitor12);
            background-color: var(--dataMonitor13);
            padding: 0 0.1875rem;
            border: none;
            border-radius: 0.0625rem;
          }
          .el-input__icon {
            width: 0.3125rem;
            font-size: 0.175rem;
            line-height: 0.275rem;
          }
        }
        .el-select {
          min-width: 0.75rem;
        }
      }
      .el-button {
        position: absolute;
        right: 0.5rem;
        top: 0.25rem;
        width: 1.875rem;
        height: 1rem;
        background-color: var(--dataMonitor14);
        padding: 0;
        font-size: 0.25rem;
        word-spacing: 0.1875rem;
        border-radius: 0.125rem;
        border: none;
        box-shadow: 0.025rem 0.025rem 0.05rem rgba(0, 0, 0, 0.25);
      }
    }
  }
}
</style>
