<template>
  <div id="logModule" :style="logDisplay ? 'width: 6.25rem' : 'width: 0;padding: 0'">
    <div :style="logDisplay ? 'width: 100%' : 'width: 0;padding: 0'">
      <i
        v-show="iconDisplay"
        :class="logDisplay ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"
        @click="logDisplay = !logDisplay"
      ></i>
      <div class="tab">
        <div class="tabname">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 30 30">
            <g>
              <g>
                <rect x="11.44" y="9.49" class="st0" width="8.32" height="1.78" />
              </g>
              <g>
                <rect x="11.44" y="14.24" class="st0" width="8.32" height="1.78" />
              </g>
              <g>
                <rect x="11.44" y="18.99" class="st0" width="8.32" height="1.78" />
              </g>
              <g>
                <rect x="9.06" y="9.49" class="st0" width="1.19" height="1.78" />
              </g>
              <g>
                <rect x="9.06" y="14.24" class="st0" width="1.19" height="1.78" />
              </g>
              <g>
                <rect x="9.06" y="18.99" class="st0" width="1.19" height="1.78" />
              </g>
              <g>
                <path
                  class="st0"
                  d="M20.69,25.53H9.31c-1.94,0-3.52-1.58-3.52-3.52V8.26c0-1.94,1.58-3.52,3.52-3.52h11.38    c1.94,0,3.52,1.58,3.52,3.52v13.75C24.21,23.95,22.63,25.53,20.69,25.53z M9.31,6.52c-0.96,0-1.74,0.78-1.74,1.74v13.75    c0,0.96,0.78,1.74,1.74,1.74h11.38c0.96,0,1.74-0.78,1.74-1.74V8.26c0-0.96-0.78-1.74-1.74-1.74H9.31z"
                />
              </g>
            </g>
          </svg>
          <div>日 志</div>
        </div>
        <div class="btnList">
          <svg @click="updownload" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 30 30">
            <g>
              <path
                class="st0"
                d="M22.34,10.29c-1.06-3.06-3.97-5.16-7.23-5.16s-6.18,2.1-7.24,5.16c-2.68,1.07-4.39,3.35-4.39,5.9   c0,3.37,3.09,6.23,7.03,6.5l0.12-1.7c-3.05-0.21-5.45-2.32-5.45-4.8c0-1.94,1.43-3.69,3.65-4.44l0.42-0.14l0.12-0.43   c0.72-2.56,3.08-4.34,5.73-4.34c2.65,0,5.01,1.79,5.73,4.34l0.12,0.43l0.42,0.14c2.22,0.76,3.66,2.5,3.66,4.44   c0,2.48-2.39,4.59-5.45,4.8l0.12,1.7c3.94-0.27,7.03-3.13,7.03-6.5C26.74,13.64,25.03,11.37,22.34,10.29z"
              />
              <polygon
                class="st0"
                points="15.11,9.91 11.48,16.2 14.26,16.2 14.26,25.27 15.96,25.27 15.96,16.2 18.74,16.2  "
              />
            </g>
            <title>上传</title>
          </svg>
          <div></div>
          <svg
            v-if="stopUpdata"
            @click="stopUpdata = !stopUpdata"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 30 30"
          >
            <g>
              <g>
                <g>
                  <g>
                    <path
                      class="st0"
                      d="M15.5,25.27c-5.55,0-10.07-4.52-10.07-10.07S9.95,5.13,15.5,5.13S25.57,9.65,25.57,15.2      S21.05,25.27,15.5,25.27z M15.5,6.86c-4.6,0-8.34,3.74-8.34,8.34s3.74,8.34,8.34,8.34s8.34-3.74,8.34-8.34S20.1,6.86,15.5,6.86z      "
                    />
                  </g>
                  <g>
                    <g>
                      <rect x="11.76" y="10.6" class="st0" width="1.73" height="9.21" />
                    </g>
                    <g>
                      <rect x="17.51" y="10.6" class="st0" width="1.73" height="9.21" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <title>暂停</title>
          </svg>
          <svg
            v-else
            @click="stopUpdata = !stopUpdata"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 30 30"
          >
            <g>
              <path
                class="st0"
                d="M15,7.13c4.45,0,8.07,3.62,8.07,8.07s-3.62,8.07-8.07,8.07s-8.07-3.62-8.07-8.07S10.55,7.13,15,7.13 M15,5.13   C9.44,5.13,4.93,9.64,4.93,15.2S9.44,25.27,15,25.27s10.07-4.51,10.07-10.07S20.56,5.13,15,5.13L15,5.13z"
              />
              <g>
                <polygon class="st0" points="20,15.2 11.64,20.03 11.64,10.38   " />
              </g>
            </g>
            <title>开始</title>
          </svg>
          <div></div>
          <svg @click="deleteLog" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 30 30">
            <g>
              <path
                class="st0"
                d="M19.47,8.61V7.09c0-1.06-0.81-1.92-1.81-1.92h-5.32c-1,0-1.81,0.86-1.81,1.92v1.53H5.71v1.72h18.57V8.61H19.47   z M12.25,7.09c0-0.13,0.07-0.19,0.09-0.2h5.32c0.02,0,0.09,0.07,0.09,0.2v1.53h-5.5V7.09z"
              />
              <path
                class="st0"
                d="M20.33,22.69c0,0.48-0.39,0.87-0.87,0.87h-8.93c-0.48,0-0.87-0.39-0.87-0.87V11.77H7.95v10.91   c0,1.43,1.16,2.59,2.59,2.59h8.93c1.43,0,2.59-1.16,2.59-2.59V11.77h-1.72V22.69z"
              />
              <rect x="12.41" y="11.77" class="st0" width="1.72" height="10.34" />
              <rect x="15.86" y="11.77" class="st0" width="1.72" height="10.34" />
            </g>
            <title>删除</title>
          </svg>
        </div>
      </div>
      <div class="logTableBox">
        <div id="sendLine"></div>
        <el-table
          ref="logTable"
          :data="tableData"
          border
          stripe
          height="100%"
          style="width: 100%;"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" align="center" width="35"></el-table-column>
          <el-table-column type="index" label="No" width="35" align="center"> </el-table-column>
          <el-table-column prop="time" label="Time" min-width="160" align="center"></el-table-column>
          <el-table-column prop="topic" label="Topic" min-width="130" align="center"></el-table-column>
          <el-table-column prop="client_id" label="Client ID" width="250" align="center"></el-table-column>
          <el-table-column prop="port" label="Port" width="50" align="center"></el-table-column>
          <el-table-column prop="key" label="Key" width="65" align="center"></el-table-column>
          <el-table-column prop="value" label="Value" width="75" align="center"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'logModule',
  data() {
    return {
      zoom: 1,
      iconDisplay: true,
      logDisplay: false,
      tableData: [
        // {
        //   server: 'ws://mqtt-cn-20p35tzwv02.mqtt.aliyuncs.com:80',
        //   time: '2023-05-05T10:43:05+08:00',
        //   topic: 'group/group1/1',
        //   client_id: 'GID_TIANQI@@@1-4934640704446587267',
        //   port: 'P0',
        //   key: '光线传感器',
        //   value: '777',
        // },
      ],
      multipleSelection: [],
      uploadInfoFlag: true,
      stopUpdata: true,
    }
  },
  props: ['tabName'],
  watch: {
    tabName(val) {
      if (val == 'dataMonitor') {
        this.iconDisplay = true
        this.logDisplay = false
      } else {
        this.iconDisplay = false
        this.logDisplay = false
      }
    },
    logDisplay(val) {
      this.$emit('logDisplay', val)
      setTimeout(() => {
        this.zoom = 500 / document.getElementById('logModule').offsetWidth
        this.zoom = Math.round(this.zoom * 100) / 100
      }, 1000)
    },
    mqttMessage(val) {
      let array = []
      val.forEach((item, index) => {
        if (item.type == 'receive' && item.topic == this.nowTopic) {
          array.push({
            id: index,
            time: item.time,
            topic: item.topic,
            client_id: item.clientId,
            port: item.port,
            key: item.key,
            value: item.value ? item.value : '',
            server: item.server,
          })
        }
      })
      if (this.stopUpdata) {
        this.tableData = array
      }
    },
    nowTopic(val) {
      let array = []
      this.mqttMessage.forEach((item, index) => {
        if (item.type == 'receive' && item.topic == val) {
          array.push({
            id: index,
            time: item.time,
            topic: item.topic,
            client_id: item.clientId,
            port: item.port,
            key: item.key,
            value: item.value ? item.value : '',
            server: item.server,
          })
        }
      })
      this.tableData = array
    },
  },
  computed: {
    ...mapState(['mqttMessage', 'nowTopic']),
  },
  created() {},
  mounted() {
    // 拖拽改变元素大小
    this.addSerialResize()
  },
  methods: {
    addSerialResize() {
      let logModuleDiv = document.getElementById('logModule')
      let sendLineDiv = document.getElementById('sendLine')
      sendLineDiv.onmousedown = e => {
        let logModuleDivw = logModuleDiv.offsetWidth
        document.onmousemove = event => {
          let logModuleMove = logModuleDivw + e.clientX - event.clientX
          if (logModuleMove >= Math.round(800 / this.zoom)) {
            logModuleMove = Math.round(800 / this.zoom)
          }
          if (logModuleMove <= Math.round(500 / this.zoom)) {
            logModuleMove = Math.round(500 / this.zoom)
          }
          logModuleDiv.style.cssText = 'width: ' + Math.round(logModuleMove * this.zoom) / 80 + 'rem;'
        }
      }
      document.onmouseup = () => {
        document.onmousemove = null
      }
    },

    // 获取选中行
    handleSelectionChange(val) {
      // console.log(val)
      // this.multipleSelection = val;
    },

    updownload() {
      // let exampleData = [
      //   {
      //     server: 'ws://mqtt-cn-20p35tzwv02.mqtt.aliyuncs.com:80',
      //     time: '2023-05-05T10:43:05+08:00',
      //     topic: 'group/group1/1',
      //     client_id: 'GID_TIANQI@@@1-4934640704446587267',
      //     port: 'P0',
      //     key: '光线传感器',
      //     value: '888',
      //   },
      // ]
      if (!this.uploadInfoFlag) {
        return this.$notify({
          type: 'warning',
          title: '警告',
          message: '正在上传，请勿再次点击',
        })
      }
      this.uploadInfoFlag = false
      // 创建并填写Blob数据
      let exampleData = this.$refs.logTable.selection
      if (0 < exampleData.length && exampleData.length <= 200) {
        let str = 'server,time,topic,client_id,port,key,value\n'
        exampleData.forEach((item, index) => {
          let obj = {
            server: item.server,
            time: item.time.replaceAll(' ', 'T') + '+08:00',
            topic: item.topic,
            client_id: item.client_id,
            port: item.port,
            key: item.key,
            value: item.value,
          }
          str = str + Object.values(obj).toString() + [index == exampleData.length - 1 ? '' : '\n']
        })
        this.uploadInfoFlag = true
        const file = new Blob([str])
        this.$get('/oss/upload_info')
          .then(res => {
            if (res.code == 200) {
              let formData = new FormData()
              let myDate = new Date()
              formData.append('key', res.data.dir + myDate.getHours() + '.txt')
              formData.append('OSSAccessKeyId', res.data.accessid)
              formData.append('policy', res.data.policy)
              formData.append('Signature', res.data.signature) //签名
              formData.append('callback', res.data.callback) //回调
              formData.append('success_action_status', 200)
              formData.append('file', file)
              this.$http
                .post('https://iot-cp-be.oss-cn-beijing.aliyuncs.com', formData, {
                  'Content-Type': 'multipart/form-data',
                })
                .then(res => {
                  if (res.data.code == 200) {
                    this.$notify({
                      type: 'success',
                      title: '上传成功',
                    })
                  } else {
                    this.$notify.error({
                      title: '报错',
                      message: res.data.data,
                    })
                  }
                  this.uploadInfoFlag = true
                })
                .catch(err => {
                  this.$notify.error({
                    title: '报错',
                    message: err,
                  })
                  this.uploadInfoFlag = true
                })
            } else {
              this.$notify.error({
                title: '报错',
                message: res,
              })
              this.uploadInfoFlag = true
            }
          })
          .catch(err => {
            this.$notify.error({
              title: '报错',
              message: err,
            })
            this.uploadInfoFlag = true
          })
      } else {
        this.$notify({
          type: 'warning',
          title: '警告',
          message: '未选择日志，或所选日志超过200条',
        })
        this.uploadInfoFlag = true
      }
    },

    deleteLog() {
      this.$refs.logTable.selection.forEach(item1 => {
        this.tableData = this.tableData.filter(item2 => item1.id !== item2.id)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
#logModule {
  position: relative;
  height: 100%;
  padding: 0 0.125rem 0.125rem 0;
  // transition: 0.3s width ease-in-out;
  > div {
    height: 100%;
    i {
      position: absolute;
      left: -0.325rem;
      top: 50%;
      padding: 0.1rem 0;
      border-radius: 0.05rem 0 0 0.05rem;
      transform: translateY(-185%);
      font-size: 0.2rem;
      font-weight: 800;
      color: var(--logModule0);
      background-color: var(--logModule1);
      &:hover {
        background: var(--logModule2);
      }
    }
    .tab {
      display: flex;
      align-items: flex-end;
      font-size: 0.25rem;
      .tabname {
        display: flex;
        justify-content: center;
        width: 1.5rem;
        height: 0.625rem;
        line-height: 0.625rem;
        color: var(--logModule3);
        background-color: var(--logModule4);
        border-radius: 0.0625rem 0.0625rem 0 0;
        text-align: left;
        box-shadow: 0.0125rem 0.0125rem 0.0625rem 0 rgba(0, 0, 0, 0.25);
        svg {
          height: 0.375rem;
          width: 0.375rem;
          margin-top: 0.125rem;
          margin-right: 0.125rem;
          .st0 {
            fill: var(--logModule5);
          }
        }
      }
      .btnList {
        display: flex;
        align-items: center;
        height: 0.5rem;
        line-height: 0.5rem;
        background-color: var(--logModule6);
        border-radius: 0 0.0625rem 0 0;
        box-shadow: 0.0125rem 0.0125rem 0.0625rem 0 rgba(0, 0, 0, 0.25);
        svg {
          height: 0.375rem;
          width: 0.375rem;
          margin: 0 0.1875rem;
          cursor: pointer;
          .st0 {
            fill: var(--logModule7);
          }
          &:hover {
            .st0 {
              fill: var(--logModule8);
            }
          }
        }
        div {
          height: 0.275rem;
          width: 0.0125rem;
          background-color: var(--logModule9);
          border-radius: 0.05rem;
        }
      }
    }
    .logTableBox {
      height: calc(100% - 0.625rem);
      background-color: var(--logModule10);
      border-radius: 0 0 0.0625rem 0.0625rem;
      box-shadow: 0.0125rem 0.0125rem 0.0625rem 0 rgba(0, 0, 0, 0.25);
      #sendLine {
        position: absolute;
        left: 0;
        height: calc(100% - 1.5625rem);
        width: 2px;
        cursor: col-resize;
        z-index: 1;
      }
      .el-table--scrollable-x::v-deep {
        .el-table__body-wrapper {
          &::-webkit-scrollbar {
            height: 6px;
          }
        }
      }
    }
  }
}
</style>
