<template>
  <div id="loginView">
    <div class="login_box">
      <div class="login_box_left">
        <img src="../assets/img/login.png" />
      </div>
      <div class="login_box_right">
        <i class="el-icon-close" @click="handleClose"></i>
        <el-form ref="login" class="login_from" hide-required-asterisk :model="loginFrom">
          <el-form-item prop="account">
            <el-input v-model="loginFrom.username" placeholder="请输入账号" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              @keyup.enter.native="login()"
              v-model="loginFrom.password"
              type="password"
              placeholder="请输入密码"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="login()">登 录</el-button>
          </el-form-item>
          <el-form-item>
            <div class="forgetPassBtn" @click="forgetPass">忘记密码</div>
            <div class="toRegBtn" @click="toReg">注册账号</div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :visible.sync="forgetPassDialog"
      class="forgetPass"
      :before-close="forgetPassDialogClose"
    >
      <div slot="title" class="dialog-title">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 60 60">
          <circle class="st0" cx="30.02" cy="30" r="29.5" />
          <path
            class="st1"
            d="M30.02,60c-16.54,0-30-13.46-30-30c0-16.54,13.46-30,30-30s30,13.46,30,30C60.02,46.54,46.56,60,30.02,60z   M30.02,1c-15.99,0-29,13.01-29,29s13.01,29,29,29c15.99,0,29-13.01,29-29S46.01,1,30.02,1z"
          />
          <g>
            <path
              class="st2"
              d="M39.87,23.52h-17.8v-4.4c0-4.39,3.57-7.95,7.95-7.95c4.38,0,7.95,3.57,7.95,7.95c0,0.97,0.78,1.75,1.75,1.75   s1.75-0.78,1.75-1.75c0-6.31-5.14-11.45-11.45-11.45s-11.45,5.14-11.45,11.45v4.57c-3.41,0.74-5.98,3.78-5.98,7.41v11.94   c0,4.18,3.4,7.58,7.59,7.58h19.7c4.18,0,7.59-3.4,7.59-7.58V31.11C47.45,26.93,44.05,23.52,39.87,23.52z M43.95,43.05   c0,2.25-1.83,4.08-4.09,4.08h-19.7c-2.25,0-4.09-1.83-4.09-4.08V31.11c0-2.25,1.83-4.09,4.09-4.09h19.7c2.25,0,4.09,1.83,4.09,4.09   V43.05z"
            />
            <path
              class="st2"
              d="M30.46,29.22c-3.39,0-6.16,2.76-6.16,6.16c0,2.79,1.86,5.14,4.41,5.9v3.07c0,0.97,0.78,1.75,1.75,1.75   s1.75-0.78,1.75-1.75v-3.07c2.54-0.76,4.41-3.11,4.41-5.9C36.61,31.98,33.85,29.22,30.46,29.22z M30.46,38.03   c-1.46,0-2.66-1.19-2.66-2.66s1.19-2.66,2.66-2.66s2.66,1.19,2.66,2.66S31.92,38.03,30.46,38.03z"
            />
          </g>
        </svg>
        <span class="title-text">找回密码</span>
        <div class="square"></div>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="手机找回" name="phone">
          <el-form ref="phoneResetPass" :model="phoneResetPass" :rules="phoneResetRules">
            <el-row>
              <el-col>
                <el-form-item label prop="phoneAccount">
                  <el-input
                    v-model="phoneResetPass.phoneAccount"
                    placeholder="请输入手机号"
                    @input="varifyDisabled(phoneResetPass.phoneAccount)"
                  ></el-input>
                </el-form-item>
                <el-form-item label prop="phoneVerification">
                  <el-input v-model="phoneResetPass.phoneVerification" placeholder="请输入手机验证码"></el-input>
                  <el-button @click.prevent="verificationCode(phoneResetPass.phoneAccount)" :disabled="btnDisAbled">{{
                    getVerify
                  }}</el-button>
                </el-form-item>
                <el-form-item prop="password" label>
                  <el-input
                    v-model="phoneResetPass.password"
                    placeholder="请输入密码（不少于6位）"
                    type="password"
                  ></el-input>
                </el-form-item>
                <el-form-item label prop="checkPass">
                  <el-input v-model="phoneResetPass.checkPass" placeholder="请确认密码" type="password"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="resetBtnList">
              <el-button type="primary" @click="dialogAmendFirst('phoneResetPass')">确 定</el-button>
              <el-button @click="resetForm('phoneResetPass')">重 置</el-button>
            </div>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="邮箱找回" name="Email">
          <el-form ref="emailResetPass" :model="emailResetPass" :rules="emailResetRules" class="forget_from">
            <el-form-item label prop="emailAccount">
              <el-input
                v-model="emailResetPass.emailAccount"
                placeholder="请输入邮箱"
                @input="varifyEmailDisabled(emailResetPass.emailAccount)"
              ></el-input>
            </el-form-item>
            <el-form-item label prop="emailVerification">
              <el-input v-model="emailResetPass.emailVerification" placeholder="请输入邮箱验证码"></el-input>
              <el-button
                @click.prevent="verificationEmailCode(emailResetPass.emailAccount)"
                :disabled="btnEmailDisAbled"
                >{{ getVerify }}</el-button
              >
            </el-form-item>
            <el-form-item prop="password" label>
              <el-input
                v-model="emailResetPass.password"
                placeholder="请输入密码（不少于6位）"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item label prop="checkPass">
              <el-input v-model="emailResetPass.checkPass" placeholder="请确认密码" type="password"></el-input>
            </el-form-item>
            <div class="resetBtnList">
              <el-button type="primary" @click="dialogAmendEmail('emailResetPass')">确 定</el-button>
              <el-button @click="resetForm('emailResetPass')">重 置</el-button>
            </div>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :visible.sync="registerDialog"
      class="register"
      :before-close="registerDialogClose"
    >
      <div slot="title" class="dialog-title">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 60 60">
          <circle class="st0" cx="30" cy="30" r="29.5" />
          <path
            class="st1"
            d="M30,60C13.46,60,0,46.54,0,30C0,13.46,13.46,0,30,0s30,13.46,30,30C60,46.54,46.54,60,30,60z M30,1  C14.01,1,1,14.01,1,30s13.01,29,29,29c15.99,0,29-13.01,29-29S45.99,1,30,1z"
          />
          <g>
            <path
              class="st2"
              d="M47.6,41.35H44v-3.6c0-0.83-0.67-1.5-1.5-1.5s-1.5,0.67-1.5,1.5v3.6h-3.6c-0.83,0-1.5,0.67-1.5,1.5   s0.67,1.5,1.5,1.5H41v3.6c0,0.83,0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5v-3.6h3.6c0.83,0,1.5-0.67,1.5-1.5S48.42,41.35,47.6,41.35z"
            />
            <path
              class="st2"
              d="M26.36,46.41c-5.84,0-11.88,0-11.88-2.18v-0.79c0-6.44,5.33-11.68,11.88-11.68h8.26   c2.77,0.05,5.32,1.05,7.32,2.69c0.68,0.56,1.66,0.56,2.31-0.03l0.1-0.09c0.79-0.72,0.73-1.97-0.1-2.64   c-1.9-1.55-4.19-2.67-6.73-3.18c3.17-2.34,5.1-6.07,5.1-10.01c0-6.88-5.67-12.48-12.63-12.48c-6.97,0-12.64,5.6-12.64,12.48   c0,4.03,2,7.82,5.29,10.15c-6.87,1.66-11.73,7.7-11.73,14.8v0.79c0,5.75,7.16,5.75,15.45,5.75c0,0,5.03,0,7.82,0   c0.99,0,1.78-0.8,1.78-1.79v0c0-0.99-0.8-1.79-1.78-1.79C31.39,46.41,26.36,46.41,26.36,46.41z M20.91,18.49   c0-4.91,4.07-8.91,9.07-8.91c5,0,9.07,4,9.07,8.91c0,4.91-4.07,8.91-9.07,8.91C24.98,27.4,20.91,23.41,20.91,18.49z"
            />
          </g>
        </svg>
        <span class="title-text">注册账号</span>
        <div class="square"></div>
      </div>
      <el-form ref="registerForm" :model="registerForm" :rules="regisetrRules">
        <el-form-item label prop="phoneNumber">
          <el-input
            v-model="registerForm.phoneNumber"
            placeholder="手机号"
            @input="registerVarifyDisabled(registerForm.phoneNumber)"
          ></el-input>
        </el-form-item>
        <el-form-item label prop="phoneCode">
          <el-input v-model="registerForm.phoneCode" placeholder="验证码"></el-input>
          <el-button
            @click.prevent="registerVerificationCode(registerForm.phoneNumber)"
            :disabled="btnRegisterDisAbled"
            >{{ registerGetVerify }}</el-button
          >
        </el-form-item>
        <el-form-item label prop="pass">
          <el-input v-model="registerForm.pass" placeholder="设置6-12位密码" type="password"></el-input>
        </el-form-item>
        <el-form-item label prop="checkPass">
          <el-input v-model="registerForm.checkPass" placeholder="再次确认密码" type="password"></el-input>
          <div class="text">已有账号？立即<span class="toLog" @click="toLogin('ruleForm')">登录</span></div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('registerForm')">注册</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'loginView',
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.phoneResetPass.checkPass !== '') {
          this.$refs.phoneResetPass.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.phoneResetPass.password) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    var validatePass3 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.emailResetPass.checkPass !== '') {
          this.$refs.emailResetPass.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass4 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.emailResetPass.password) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    var validatePass5 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.checkPass !== '') {
          this.$refs.registerForm.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass6 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.registerForm.pass) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    return {
      // 登录
      loginFrom: {
        username: '',
        password: '',
      },
      // 忘记密码
      forgetPassDialog: false,
      activeName: 'phone',
      getVerify: '找回密码',
      btnDisAbled: true,
      btnEmailDisAbled: true,
      phoneResetPass: {
        phoneAccount: null,
        phoneVerification: null,
        password: '',
        checkPass: '',
      },
      phoneResetRules: {
        phoneAccount: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '长度为11位数字', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '请输入正确的手机号！',
          },
        ],
        phoneVerification: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 4, message: '长度为4位数字', trigger: 'blur' },
          {
            pattern: /^[0-9]*$/,
            message: '请输入正确的验证码！',
          },
        ],
        password: [
          {
            validator: validatePass,
            required: true,
            message: '请输入新密码',
            trigger: 'blur',
          },
          { min: 6, max: 12, message: '长度在 6 到 12 个字符' },
          {
            pattern: /^[a-zA-Z0-9]+$/,
            message: '请输入数字或字母',
          },
        ],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }],
      },
      emailResetPass: {
        emailAccount: '',
        emailVerification: '',
        password: '',
        checkPass: '',
      },
      emailResetRules: {
        emailAccount: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: '请输入正确的邮箱！',
          },
        ],
        emailVerification: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 4, message: '长度为4位数字', trigger: 'blur' },
          {
            pattern: /^[0-9]*$/,
            message: '请输入正确的验证码！',
          },
        ],
        password: [
          {
            validator: validatePass3,
            required: true,
            message: '请输入新密码',
            trigger: 'blur',
          },
          { min: 6, max: 12, message: '长度在 6 到 12 个字符' },
          {
            pattern: /^[a-zA-Z0-9]+$/,
            message: '请输入数字或字母',
          },
        ],
        checkPass: [{ validator: validatePass4, trigger: 'blur' }],
      },
      // 账号注册
      registerDialog: false,
      registerGetVerify: '发送验证码',
      btnRegisterDisAbled: true,
      registerForm: {
        userName: '',
        phoneNumber: '',
        phoneCode: '',
        pass: '',
        checkPass: '',
        type: 2,
      },
      regisetrRules: {
        phoneNumber: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '长度为11位数字', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '请输入正确的手机号！',
          },
        ],
        phoneCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 4, message: '长度为4位数字', trigger: 'blur' },
          {
            pattern: /^[0-9]*$/,
            message: '请输入正确格式的验证码！',
          },
        ],
        pass: [
          {
            validator: validatePass5,
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
          { min: 6, max: 12, message: '长度在 6 到 12 个字符' },
          {
            pattern: /^[a-zA-Z0-9]+$/,
            message: '请输入数字',
          },
        ],
        checkPass: [{ validator: validatePass6, trigger: 'blur' }],
      },
    }
  },
  mounted() {},
  methods: {
    ...mapMutations(['updateLoginStatus']),

    handleClose() {
      this.$emit('closeLogin')
    },
    forgetPass() {
      this.forgetPassDialog = true
    },
    toReg() {
      this.registerDialog = true
    },
    login() {
      this.$post('/user/login', this.loginFrom)
        .then(res => {
          if (res.code == 200) {
            this.$notify({
              title: '登录成功',
              type: 'success',
            })
            sessionStorage.setItem('csrfToken', res.token)
            sessionStorage.setItem('nowMqttIndex', 0)
            this.updateLoginStatus(true)
            this.handleClose()
            this.$refs['login'].resetFields()
            // 查询已加入小组
            this.$get('/group/list')
              .then(res => {
                if (res.code == 200) {
                  this.$emit('getGroupList', res.data.groups)
                }
              })
              .catch(err => {
                this.$notify.error({
                  title: '报错',
                  message: err,
                })
              })
          } else {
            this.$notify({
              type: 'warning',
              title: '警告',
              message: '登录失败',
            })
          }
        })
        .catch(err => {
          this.$notify.error({
            title: '报错',
            message: err,
          })
        })
    },
    // 密码找回
    forgetPassDialogClose() {
      this.forgetPassDialog = false
      this.$refs['phoneResetPass'].resetFields()
      this.$refs['emailResetPass'].resetFields()
    },
    handleClick(data) {
      this.btnDisAbled = true
      this.$refs['phoneResetPass'].resetFields()
      this.$refs['emailResetPass'].resetFields()
    },
    forbiddenBtn() {
      this.getVerify = 60
      let stopTime = setInterval(() => {
        this.getVerify--
        if (this.getVerify == 0) {
          this.btnDisAbled = false
          this.btnEmailDisAbled = false
          this.btnPhoneDisAbled = false
          this.getVerify = '获取验证码'
          clearInterval(stopTime)
        }
      }, 1000)
    },
    // 手机找回
    varifyDisabled(data) {
      if (!/^1[3456789]\d{9}$/.test(data)) {
        this.btnDisAbled = true
      } else {
        this.btnDisAbled = false
      }
    },
    verificationCode(phoneNumber) {
      this.btnDisAbled = true
      this.promiseAjax('https://ai.stemedu.cn/dfck/blocklyCMS/checkUserExist', 'POST', {
        loginid: phoneNumber,
      })
        .then(res => {
          if (res.data) {
            this.$notify({
              type: 'warning',
              title: '警告',
              message: '这个手机号未注册，前往注册',
            })
            this.btnDisAbled = true
          } else {
            this.promiseAjax('https://ai.stemedu.cn/dfck/blocklyCMS/sendFindPassWordByPhoneCode', 'POST', {
              phoneNumber: phoneNumber,
            })
              .then(res => {
                if (res.errCode == 0) {
                  this.forbiddenBtn()
                } else {
                  this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: res,
                  })
                }
              })
              .catch(err => {
                this.$notify.error({
                  title: '报错',
                  message: err,
                })
              })
          }
        })
        .catch(err => {
          this.$notify.error({
            title: '报错',
            message: err,
          })
        })
    },
    dialogAmendFirst(data) {
      this.$refs[data].validate(valid => {
        if (valid) {
          this.promiseAjax('https://ai.stemedu.cn/dfck/blocklyCMS/findPassWordByPhone', 'POST', {
            phoneNumber: this.phoneResetPass.phoneAccount,
            findPasswordPhoneCode: this.phoneResetPass.phoneVerification,
            password: this.phoneResetPass.password,
          })
            .then(res => {
              if (res.errCode == 0) {
                this.$notify({
                  type: 'success',
                  title: res.data.msg,
                })
                this.forgetPassDialogClose()
              } else {
                this.$notify({
                  type: 'warning',
                  title: '警告',
                  message: res,
                })
              }
            })
            .catch(err => {
              this.$notify.error({
                title: '报错',
                message: err,
              })
            })
        } else {
          this.$notify({
            type: 'warning',
            title: '警告',
            message: 'error submit',
          })
          return false
        }
      })
    },
    // 邮箱找回
    varifyEmailDisabled(data) {
      if (!/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(data)) {
        this.btnEmailDisAbled = true
      } else {
        this.btnEmailDisAbled = false
      }
    },
    verificationEmailCode(email) {
      this.btnEmailDisAbled = true
      this.promiseAjax('https://ai.stemedu.cn/dfck/blocklyCMS/sendFindPasswordEmailCode', 'POST', {
        email: email,
      })
        .then(res => {
          if (res.errCode == 0) {
            this.forbiddenBtn()
          } else {
            // this.$notify.info({
            //   title: '消息',
            //   message: res,
            // })
          }
        })
        .catch(err => {
          this.$notify.error({
            title: '报错',
            message: err,
          })
        })
    },
    dialogAmendEmail(data) {
      this.$refs[data].validate(valid => {
        if (valid) {
          this.promiseAjax('https://ai.stemedu.cn/dfck/blocklyCMS/findPasswordByEmail', 'POST', {
            email: this.emailResetPass.emailAccount,
            emailCode: this.emailResetPass.emailVerification,
            password: this.emailResetPass.password,
          })
            .then(res => {
              if (res.errCode == 0) {
                this.$notify({
                  type: 'success',
                  title: res.data.msg,
                })
                this.forgetPassDialogClose()
              } else {
                // console.log(res)
              }
            })
            .catch(err => {
              this.$notify.error({
                title: '报错',
                message: err,
              })
            })
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.varifyDisabled('')
      this.varifyEmailDisabled('')
    },
    // 账号注册
    registerDialogClose() {
      this.$refs['registerForm'].resetFields()
      this.registerDialog = false
    },
    registerVarifyDisabled(data) {
      if (!/^1[3456789]\d{9}$/.test(this.registerForm.phoneNumber)) {
        this.btnRegisterDisAbled = true
      } else {
        this.promiseAjax('https://ai.stemedu.cn/dfck/blocklyCMS/checkUserExist', 'POST', {
          loginid: data,
        })
          .then(res => {
            if (!res.data) {
              this.$notify({
                type: 'warning',
                title: '警告',
                message: '这个手机号已经注册，请换一个吧！',
              })
              this.btnRegisterDisAbled = true
            } else {
              this.btnRegisterDisAbled = false
            }
          })
          .catch(err => {
            this.$notify.error({
              title: '报错',
              message: err,
            })
          })
      }
    },
    registerVerificationCode(phoneNumber) {
      this.promiseAjax('https://ai.stemedu.cn/dfck/blocklyCMS/sendPhoneCode', 'POST', {
        phoneNumber: phoneNumber,
      })
        .then(res => {
          if (res.errCode == 0) {
            this.btnRegisterDisAbled = true
            let num = 60
            this.registerGetVerify = num + '秒后重发送'
            let stopTime = setInterval(() => {
              num--
              this.registerGetVerify = num + '秒后重发送'
              if (num == 0) {
                this.btnRegisterDisAbled = false
                this.registerGetVerify = '发送验证码'
                clearInterval(stopTime)
              }
            }, 1000)
          } else {
            this.$notify.error({
              title: '报错',
              message: res,
            })
          }
        })
        .catch(err => {
          this.$notify.error({
            title: '报错',
            message: err,
          })
        })
    },
    toLogin() {
      this.registerDialog = false
    },
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          this.promiseAjax('https://ai.stemedu.cn/dfck/blocklyCMS/registered', 'POST', {
            loginid: this.registerForm.phoneNumber,
            password: this.registerForm.pass,
            phoneCode: this.registerForm.phoneCode,
            type: 2,
          })
            .then(res => {
              if (res.errCode == 0) {
                if (!res.data.success) {
                  this.$notify.error({
                    title: '报错',
                    message: res.data.msg,
                  })
                } else {
                  this.$notify({
                    type: 'success',
                    title: '注册成功',
                  })
                  this.registerDialogClose()
                }
              } else {
                this.$notify.error({
                  title: '报错',
                  message: res,
                })
              }
            })
            .catch(err => {
              this.$notify.error({
                title: '报错',
                message: err,
              })
            })
        } else {
          return false
        }
      })
    },
    promiseAjax(url, type, data) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: url,
          type: type,
          data: data,
          dataType: 'json',
          crossDomain: true,
          xhrFields: {
            withCredentials: true,
          },
          success: function(data) {
            resolve(data)
          },
          error: function(err) {
            reject(err)
          },
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
#loginView {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
  .login_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 4.25rem;
    width: 9.625rem;
    background-color: var(--loginView0);
    z-index: 2000;
    border-radius: 0.375rem;
    box-shadow: 0.025rem 0.025rem 0.0375rem rgba(0, 0, 0, 0.25);
    display: flex;
    .login_box_left {
      height: 100%;
      width: 4.625rem;
      img {
        position: absolute;
        top: 50%;
        left: 25%;
        transform: translate(-50%, -50%);
        width: 2.475rem;
      }
    }
    .login_box_right {
      height: 100%;
      width: 5rem;
      border-radius: 0.3rem;
      background-color: var(--loginView1);
      .el-icon-close {
        float: right;
        font-size: 0.25rem;
        margin-top: 0.1875rem;
        margin-right: 0.1875rem;
        color: var(--loginView2);
      }
      .login_from::v-deep {
        height: 100%;
        padding: 0.8125rem 0.5625rem;
        .el-form-item {
          &:first-child {
            margin-bottom: 0.1875rem;
          }
          &:nth-child(2) {
            margin-bottom: 0.5rem;
          }
          &:nth-child(3) {
            margin-bottom: 0.175rem;
            .el-form-item__content {
              line-height: 0.3125rem;
            }
          }
          &:last-child {
            margin-bottom: 0;
            .el-form-item__content {
              line-height: 0.3125rem;
            }
          }
          .el-form-item__content {
            font-size: 0.175rem;
            line-height: 0.5rem;
            .el-input {
              font-size: 0.175rem;
              height: 0.5rem;
              line-height: 0.5rem;
              .el-input__inner {
                font-size: 0.2rem;
                height: 0.5rem;
                line-height: 0.5rem;
                border: none;
                border-radius: 0.0625rem;
                color: var(--loginView3);
                background-color: var(--loginView4);
                padding: 0 0.1875rem;
              }
            }
            .el-form-item__error {
              font-size: 0.15rem;
              padding-top: 0.05rem;
            }
            .forgetPassBtn,
            .toRegBtn {
              float: left;
              font-size: 0.2rem;
              margin-left: 0.5rem;
              cursor: pointer;
            }
            .toRegBtn {
              float: right;
              color: var(--loginView0);
              margin-right: 0.5rem;
            }
            .el-button {
              width: 100%;
              background-color: var(--loginView0);
              padding: 0;
              height: 0.5rem;
              font-size: 0.2rem;
              word-spacing: 0.1875rem;
              border: none;
              border-radius: 0.0625rem;
            }
          }
        }
      }
    }
  }
  .forgetPass::v-deep,
  .register::v-deep {
    font-size: 0.25rem;
    .el-dialog {
      top: 50%;
      transform: translateY(-50%);
      width: 7.5rem;
      margin-top: 0 !important;
      border-radius: 0.375rem;
      background-color: var(--loginView0);
      .el-dialog__header {
        padding: 0;
        height: 1.375rem;
        line-height: 1.375rem;
        .dialog-title {
          position: relative;
          display: flex;
          justify-content: center;
          font-size: 0.25rem;
          color: var(--loginView5);
          height: 100%;
          svg {
            height: 0.75rem;
            width: 0.75rem;
            margin-top: 0.3125rem;
            margin-right: 0.25rem;
            .st0 {
              fill: var(--loginView6);
            }
            .st1 {
              fill: var(--loginView7);
            }
            .st2 {
              fill: var(--loginView8);
            }
          }
          .square {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 50%) rotate(45deg);
            height: 0.25rem;
            width: 0.25rem;
            background-color: var(--loginView0);
          }
        }
        .el-dialog__headerbtn {
          font-size: 0.25rem;
          top: 0.25rem;
          right: 0.25rem;
          .el-dialog__close {
            color: var(--loginView5);
          }
        }
        .el-dialog__headerbtn:focus .el-dialog__close,
        .el-dialog__headerbtn:hover .el-dialog__close {
          color: var(--loginView5);
        }
      }
      .el-dialog__body {
        padding: 0.625rem 1.25rem;
        height: 5rem;
        font-size: 0.175rem;
        overflow: hidden;
        border-radius: 0.375rem;
        background-color: var(--loginView9);
        .el-tabs {
          height: 100%;
          .el-tabs__header {
            margin-bottom: 0.25rem;
            .el-tabs__item {
              font-size: 0.2rem;
              height: 0.3125rem;
              line-height: 0.25rem;
              color: var(--loginView10);
            }
            .el-tabs__item.is-active {
              color: var(--loginView0);
            }
            .el-tabs__active-bar {
              background-color: var(--loginView0);
            }
            .el-tabs__nav-wrap::after {
              background-color: transparent;
            }
          }
        }
        .el-form {
          .el-form-item {
            margin-bottom: 0.25rem;
            &:nth-child(2) {
              .el-input {
                width: calc(100% - 1.5rem);
              }
              .el-button {
                width: 1.25rem;
                padding: 0;
                margin-left: 0.25rem;
                height: 0.375rem;
                font-size: 0.2rem;
                color: var(--loginView11);
                background-color: var(--loginView0);
                border-radius: 0.0625rem;
                border: none;
              }
              .el-button.is-disabled {
                color: var(--loginView12);
                background-color: var(--loginView13);
                border: none;
              }
            }
            &:nth-child(4) {
              margin-bottom: 0.4375rem;
            }
            .el-form-item__content {
              font-size: 0.2rem;
              line-height: 0.375rem;
              .el-input {
                font-size: 0.2rem;
                height: 0.375rem;
                line-height: 0.375rem;
                .el-input__inner {
                  font-size: 0.2rem;
                  height: 0.375rem;
                  line-height: 0.375rem;
                  border: none;
                  border-radius: 0.05rem;
                  color: var(--loginView14);
                  background-color: var(--loginView15);
                  padding: 0 0.1875rem;
                }
              }
              .el-form-item__error {
                font-size: 0.15rem;
                padding-top: 0.05rem;
              }
            }
          }
          .resetBtnList {
            text-align: center;
            .el-button {
              width: calc(50% - 0.125rem);
              height: 0.475rem;
              padding: 0;
              font-size: 0.225rem;
              border-radius: 0.075rem;
              margin: 0;
              color: var(--loginView0);
              background-color: var(--loginView16);
              border: none;
              &:first-child {
                margin-right: 0.25rem;
                color: var(--loginView17);
                background-color: var(--loginView0);
              }
            }
          }
        }
      }
    }
  }
  .register::v-deep {
    .el-dialog {
      .el-dialog__body {
        height: 4.875rem;
        .el-form-item {
          &:nth-child(4) {
            text-align: right;
            .text {
              margin-top: 0.25rem;
              height: 0.225rem;
              line-height: 0.225rem;
              .toLog {
                cursor: pointer;
                color: var(--loginView0);
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
            .el-button {
              width: 100%;
              background-color: var(--loginView0);
              padding: 0;
              height: 0.5rem;
              font-size: 0.2rem;
              word-spacing: 0.1875rem;
              border: none;
              border-radius: 0.0625rem;
            }
          }
        }
      }
    }
  }
}
</style>
