export const harmonySensorS1 = [
  {
    index: 100,
    categoryEng: 'Sensor',
    key: '触摸传感器',
    picture: require('../../img/harmaony/sensor/touchsensor.png'),
    port: '',
    value: 0,
  },
  {
    index: 101,
    categoryEng: 'Sensor',
    key: '温湿度传感器',
    picture: require('../../img/harmaony/sensor/temperature_humidity.png'),
    port: '',
    value: 0,
  },
  {
    index: 102,
    categoryEng: 'Sensor',
    key: '按钮传感器',
    picture: require('../../img/harmaony/sensor/button.png'),
    port: '',
    value: 0,
  },
  {
    index: 103,
    categoryEng: 'Sensor',
    key: '光线传感器',
    picture: require('../../img/harmaony/sensor/optical_line.png'),
    port: '',
    value: 0,
  },
  {
    index: 104,
    categoryEng: 'Sensor',
    key: '声音传感器',
    picture: require('../../img/harmaony/sensor/sound.png'),
    port: '',
    value: 0,
  },
]
