export const sensorActuatorName = [
  // sensor
  {
    value: '触摸传感器',
  },
  {
    value: '光线传感器',
  },
  {
    value: '温湿度传感器',
  },
  {
    value: '寻迹传感器',
  },
  {
    value: '双路寻迹传感器',
  },
  {
    value: '超声波传感器',
  },
  {
    value: '声音传感器',
  },
  {
    value: '火焰传感器',
  },
  {
    value: '数字火焰传感器',
  },
  {
    value: '土壤湿度传感器',
  },
  {
    value: '颜色传感器',
  },
  {
    value: '倾斜传感器',
  },
  // {
  //   value: "红外测障传感器",
  // },
  {
    value: '烟雾传感器',
  },
  {
    value: '数字烟雾传感器',
  },
  {
    value: '碰撞传感器',
  },
  {
    value: '磁敏传感器',
  },
  {
    value: '水位传感器',
  },
  {
    value: '人体红外传感器',
  },
  {
    value: '按钮传感器',
  },
  {
    value: '旋钮传感器',
  },
  {
    value: '水滴传感器',
  },
  {
    value: '数字水滴传感器',
  },
  {
    value: '水流量传感器',
  },
  {
    value: '薄膜压力传感器',
  },
  {
    value: '大气压力传感器',
  },
  {
    value: '粉尘浓度传感器',
  },
  {
    value: '电子秤传感器',
  },
  {
    value: '风速传感器',
  },
  {
    value: '风向传感器',
  },
  {
    value: '单向通讯电表',
  },
  {
    value: '远传水表',
  },
  {
    value: '2.4g 无线遥控器',
  },
  // actuator
  {
    value: '9g舵机',
  },
  {
    value: '数字舵机',
  },
  {
    value: 'TT马达',
  },
  {
    value: '直流电机',
  },
  {
    value: '风扇',
  },
  {
    value: '蜂鸣器',
  },
  {
    value: 'LED',
  },
  {
    value: '数码管',
  },
  {
    value: '全彩灯',
  },
  {
    value: '4x4RGB点阵',
  },
  {
    value: '灯串',
  },
  {
    value: '扬声器',
  },
  {
    value: '水泵',
  },
  {
    value: 'Geekservo舵机',
  },
  {
    value: '四位时钟数码管',
  },
  {
    value: 'OLED显示屏',
  },
  {
    value: '继电器',
  },
  {
    value: '加湿器',
  },
  {
    value: '8*16点阵',
  },
]
