export const harmonyActuatorE2 = [
  {
    index: 200,
    categoryEng: 'Actuator',
    key: '4x4RGB点阵',
    picture: require('../../img/harmaony/actuator/RGB4.png'),
    port: '',
    value: 0,
  },
  {
    index: 201,
    categoryEng: 'Actuator',
    key: '数字舵机',
    picture: require('../../img/harmaony/actuator/digitalSteerengine.png'),
    port: '',
    value: 0,
  },
  {
    index: 202,
    categoryEng: 'Actuator',
    key: '直流电机',
    picture: require('../../img/harmaony/actuator/motor.png'),
    port: '',
    value: 0,
  },
  {
    index: 203,
    categoryEng: 'Actuator',
    key: '四位时钟数码管',
    picture: require('../../img/harmaony/actuator/clockNixieTube.png'),
    port: '',
    value: 0,
  },
  {
    index: 204,
    categoryEng: 'Actuator',
    key: 'OLED显示屏',
    picture: require('../../img/harmaony/actuator/displayScreen.png'),
    port: '',
    value: 0,
  },
  {
    index: 205,
    categoryEng: 'Actuator',
    key: '风扇',
    picture: require('../../img/harmaony/actuator/fan.png'),
    port: '',
    value: 0,
  },
  {
    index: 206,
    categoryEng: 'Actuator',
    key: '全彩灯',
    picture: require('../../img/harmaony/actuator/multicolorLight.png'),
    port: '',
    value: 0,
  },
  {
    index: 207,
    categoryEng: 'Actuator',
    key: 'LED',
    picture: require('../../img/harmaony/actuator/LED.png'),
    port: '',
    value: 0,
  },
  {
    index: 208,
    categoryEng: 'Actuator',
    key: '灯串',
    picture: require('../../img/harmaony/actuator/SL_drive.png'),
    port: '',
    value: 0,
  },
  {
    index: 209,
    categoryEng: 'Actuator',
    key: 'Geekservo舵机',
    picture: require('../../img/harmaony/actuator/Geekservo.png'),
    port: '',
    value: 0,
  },
  {
    index: 210,
    categoryEng: 'Actuator',
    key: '8*16点阵',
    picture: require('../../img/harmaony/actuator/Matrix8x16.png'),
    port: '',
    value: 0,
  },
]
