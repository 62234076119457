<template>
  <div id="index">
    <div class="header">
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="logo" viewBox="0 0 183 30">
        <g>
          <g>
            <polygon
              class="st0"
              points="10.38,10.05 3.14,10.05 4.08,8.06 12.04,8.06 16.29,2.94 7.71,2.94 8.43,1.36 30.47,1.36 30.37,2.94     23.57,2.94 19.96,8.06 29.88,8.06 29.75,10.05 24.54,10.05 29.23,17.05 30.34,17.05 30.14,29.94 17.73,11.22 9.72,22.56     -0.01,22.56   "
            />
            <polygon
              class="st0"
              points="41.96,18.06 56.11,18.06 54.6,12.35 42.01,12.35 41.99,10.84 60.36,10.84 62.95,18.06 64.56,22.56     42.1,22.56   "
            />
            <path
              class="st0"
              d="M57,1.45l-0.04-0.12h-8.79l-0.11-1.18h-5.53l-0.01,1.18h-7.8l0.03,0.98l-1.63,27.64l5.7-7.38l0.25-14.35    l7.22,0l7.22,0.01h5.92L57,1.45z M39.1,6.42l0.07-4h12.8l1.06,4H39.1z"
            />
            <path
              class="st0"
              d="M48.25,26.94l0.68-0.15c0.21,0.52,0.56,0.78,1.06,0.77c0.4-0.01,0.57-0.16,0.51-0.46    c-0.05-0.22-0.24-0.38-0.59-0.49c-0.04-0.01-0.1-0.03-0.18-0.05c-0.22-0.07-0.39-0.11-0.5-0.14c-0.72-0.15-1.14-0.48-1.26-1.01    c-0.11-0.61,0.24-0.94,1.04-0.97c0.85-0.01,1.43,0.32,1.75,1.01l-0.7,0.14c-0.15-0.36-0.43-0.55-0.84-0.55    c-0.35,0.01-0.5,0.14-0.46,0.38c0.03,0.23,0.29,0.39,0.79,0.48c0.37,0.08,0.65,0.17,0.84,0.26c0.48,0.2,0.77,0.51,0.89,0.92    c0.15,0.68-0.22,1.04-1.1,1.06C49.25,28.13,48.61,27.74,48.25,26.94z"
            />
            <path
              class="st0"
              d="M54.95,28.05h-2.8l-0.95-3.57h2.73l0.16,0.61h-1.98l0.22,0.84h1.86l0.16,0.61H52.5l0.24,0.92h2.05    L54.95,28.05z"
            />
            <path
              class="st0"
              d="M58.77,28.05h-0.96l-1.29-1.4h-0.55l0.37,1.4H55.6l-0.95-3.57h1.64c0.86,0.02,1.4,0.38,1.63,1.06    c0.16,0.59-0.05,0.94-0.62,1.06L58.77,28.05z M55.56,25.06l0.27,1.02h0.75c0.48,0,0.67-0.17,0.56-0.51    c-0.08-0.34-0.36-0.51-0.84-0.51H55.56z"
            />
            <path class="st0" d="M59.18,28.05l-0.95-3.57h0.75l0.95,3.57H59.18z" />
            <path
              class="st0"
              d="M63.5,28.05h-2.8l-0.95-3.57h2.73l0.16,0.61h-1.98l0.22,0.84h1.86l0.16,0.61h-1.86l0.24,0.92h2.05L63.5,28.05    z"
            />
            <path
              class="st0"
              d="M63.7,26.94l0.68-0.15c0.21,0.52,0.56,0.78,1.06,0.77c0.4-0.01,0.57-0.16,0.51-0.46    c-0.05-0.22-0.24-0.38-0.59-0.49c-0.04-0.01-0.1-0.03-0.18-0.05c-0.22-0.07-0.39-0.11-0.5-0.14c-0.72-0.15-1.14-0.48-1.26-1.01    c-0.11-0.61,0.24-0.94,1.04-0.97c0.85-0.01,1.43,0.32,1.75,1.01l-0.7,0.14c-0.15-0.36-0.43-0.55-0.84-0.55    c-0.35,0.01-0.5,0.14-0.46,0.38c0.03,0.23,0.29,0.39,0.79,0.48c0.37,0.08,0.65,0.17,0.84,0.26c0.48,0.2,0.77,0.51,0.89,0.92    c0.15,0.68-0.22,1.04-1.1,1.06C64.7,28.13,64.06,27.74,63.7,26.94z"
            />
          </g>
        </g>
        <g>
          <path class="st0" d="M79.56,22.17h-3.86V5.05h3.86V22.17z" />
          <path
            class="st0"
            d="M88.91,22.47c-2.04,0-3.64-0.57-4.8-1.71c-1.17-1.14-1.75-2.69-1.75-4.65c0-2.02,0.6-3.6,1.81-4.75   c1.21-1.14,2.85-1.71,4.91-1.71c2.03,0,3.62,0.57,4.77,1.71c1.15,1.14,1.73,2.65,1.73,4.53c0,2.03-0.59,3.63-1.78,4.81   C92.61,21.88,90.98,22.47,88.91,22.47z M89.01,12.54c-0.89,0-1.58,0.31-2.08,0.92s-0.74,1.48-0.74,2.6c0,2.35,0.95,3.52,2.84,3.52   c1.81,0,2.71-1.21,2.71-3.62C91.74,13.68,90.83,12.54,89.01,12.54z"
          />
          <path class="st0" d="M110.42,8.19h-4.88v13.98h-3.87V8.19h-4.86V5.05h13.61V8.19z" />
          <path
            class="st0"
            d="M131.74,21.56c-1.25,0.6-2.88,0.91-4.89,0.91c-2.63,0-4.69-0.77-6.2-2.32c-1.5-1.54-2.26-3.6-2.26-6.17   c0-2.74,0.85-4.96,2.54-6.66s3.89-2.55,6.58-2.55c1.67,0,3.08,0.21,4.23,0.63v3.71c-1.15-0.68-2.45-1.03-3.92-1.03   c-1.61,0-2.9,0.51-3.89,1.52c-0.99,1.01-1.48,2.38-1.48,4.11c0,1.66,0.47,2.97,1.4,3.96c0.93,0.98,2.18,1.47,3.76,1.47   c1.5,0,2.88-0.37,4.13-1.1V21.56z"
          />
          <path class="st0" d="M138.13,22.17h-3.77V4.07h3.77V22.17z" />
          <path
            class="st0"
            d="M147.13,22.47c-2.04,0-3.64-0.57-4.81-1.71c-1.17-1.14-1.75-2.69-1.75-4.65c0-2.02,0.6-3.6,1.81-4.75   c1.21-1.14,2.84-1.71,4.91-1.71c2.03,0,3.62,0.57,4.77,1.71c1.15,1.14,1.73,2.65,1.73,4.53c0,2.03-0.59,3.63-1.78,4.81   C150.83,21.88,149.2,22.47,147.13,22.47z M147.22,12.54c-0.89,0-1.58,0.31-2.08,0.92c-0.49,0.61-0.74,1.48-0.74,2.6   c0,2.35,0.95,3.52,2.84,3.52c1.81,0,2.71-1.21,2.71-3.62C149.96,13.68,149.05,12.54,147.22,12.54z"
          />
          <path
            class="st0"
            d="M167.86,22.17h-3.76v-1.86h-0.06c-0.93,1.44-2.18,2.16-3.74,2.16c-2.83,0-4.25-1.71-4.25-5.14V9.94h3.76v7.04   c0,1.73,0.68,2.59,2.05,2.59c0.68,0,1.22-0.24,1.62-0.71c0.41-0.47,0.61-1.12,0.61-1.93v-7h3.76V22.17z"
          />
          <path
            class="st0"
            d="M183,22.17h-3.77v-1.68h-0.05c-0.86,1.32-2.12,1.98-3.78,1.98c-1.53,0-2.76-0.54-3.69-1.62   c-0.93-1.08-1.4-2.58-1.4-4.52c0-2.02,0.51-3.64,1.54-4.86c1.03-1.22,2.37-1.83,4.03-1.83c1.58,0,2.67,0.57,3.29,1.7h0.05V4.07H183   V22.17z M179.3,16.22V15.3c0-0.8-0.23-1.46-0.69-1.98c-0.46-0.53-1.06-0.79-1.8-0.79c-0.84,0-1.49,0.33-1.96,0.98   c-0.47,0.66-0.7,1.56-0.7,2.7c0,1.07,0.23,1.9,0.68,2.48c0.45,0.58,1.08,0.87,1.89,0.87c0.76,0,1.39-0.31,1.87-0.92   C179.06,18.05,179.3,17.23,179.3,16.22z"
          />
        </g>
      </svg>

      <!-- <div class="test" type="primary" @click="test"></div> -->

      <el-dropdown class="skinChange">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 30 30">
            <circle class="st0" cx="15" cy="15" r="14.5" />
            <path
              class="st1"
              d="M24.77,10.37l-4.15-4.75L20.6,5.6c-0.38-0.41-0.93-0.61-1.48-0.52c-0.56,0.09-1.03,0.47-1.26,1.01  c-0.47,1.08-1.53,1.73-2.85,1.73h-0.05c-1.32,0.02-2.36-0.6-2.85-1.71c-0.24-0.53-0.71-0.91-1.27-1c-0.54-0.09-1.1,0.1-1.48,0.52  L9.36,5.63l-4.14,4.74c-0.61,0.67-0.94,1.55-0.94,2.48c0,0.94,0.34,1.83,0.95,2.49l0.01,0.01c0.63,0.69,1.51,1.07,2.41,1.07  c0.07,0,0.15,0,0.22-0.01v5.17c0,1.85,1.51,3.36,3.36,3.36h7.53c1.85,0,3.36-1.51,3.36-3.36v-5.17c0.08,0.01,0.17,0.01,0.25,0.01  c0.9,0,1.77-0.38,2.4-1.07l0,0c0.61-0.67,0.95-1.55,0.95-2.49C25.72,11.92,25.38,11.04,24.77,10.37z M23.59,14.27L23.59,14.27  c-0.4,0.44-0.95,0.63-1.5,0.54c-0.4-0.07-0.8,0.04-1.11,0.3c-0.3,0.26-0.48,0.63-0.48,1.03v5.44c0,0.97-0.79,1.76-1.76,1.76h-7.53  c-0.97,0-1.76-0.79-1.76-1.76v-5.43c0-0.4-0.17-0.77-0.47-1.03c-0.3-0.26-0.69-0.38-1.1-0.31c-0.55,0.09-1.08-0.11-1.48-0.53  C5.7,13.5,5.7,12.21,6.4,11.44l4.18-4.79l0.22,0.41c0.36,0.66,0.87,1.23,1.5,1.63c0.77,0.49,1.72,0.74,2.72,0.72  c1.82-0.01,3.36-0.89,4.15-2.37l0.22-0.41l4.19,4.8C24.29,12.21,24.3,13.5,23.59,14.27z"
            />
          </svg>
        </div>
        <el-dropdown-menu class="skinDropdownMenu" slot="dropdown">
          <el-dropdown-item @click.native="skinChange('1')">
            <div class="item-box">
              <div
                class="item-box-color"
                style="background-image: linear-gradient(135deg, #ffffff 50%, #016B5F 50%);"
              ></div>
              <div>亮-绿</div>
            </div>
          </el-dropdown-item>
          <el-dropdown-item @click.native="skinChange('2')">
            <div class="item-box">
              <div
                class="item-box-color"
                style="background-image: linear-gradient(135deg, #000000 50%, #016B5F 50%);"
              ></div>
              <div>暗-绿</div>
            </div>
          </el-dropdown-item>
          <el-dropdown-item @click.native="skinChange('3')">
            <div class="item-box">
              <div
                class="item-box-color"
                style="background-image: linear-gradient(135deg, #ffffff 50%, #014fff 50%);"
              ></div>
              <div>亮-蓝</div>
            </div>
          </el-dropdown-item>
          <el-dropdown-item @click.native="skinChange('4')">
            <div class="item-box">
              <div
                class="item-box-color"
                style="background-image: linear-gradient(135deg, #000000 50%, #014fff 50%);"
              ></div>
              <div>暗-蓝</div>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-dropdown class="login" :disabled="!loginStatus">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 30 30"
            @click="!loginStatus ? (loginViewShow = true) : ''"
          >
            <circle class="st0" cx="15" cy="15" r="14.5" />
            <g>
              <path
                :class="!loginStatus ? 'st1' : 'st2'"
                d="M16.87,23.98h-3.74c-3.73,0-6.95,0-6.95-2.44v-0.37c0-3.4,2.53-6.23,5.83-6.76c-1.69-0.96-2.84-2.76-2.84-4.83   c0-3.07,2.53-5.56,5.64-5.56s5.64,2.5,5.64,5.56c0,2.03-1.11,3.81-2.76,4.78c3.45,0.4,6.13,3.3,6.13,6.8v0.37   C23.81,23.98,20.44,23.98,16.87,23.98z M13.13,15.52c-3.17,0-5.75,2.54-5.75,5.66v0.37c0,1.24,2.67,1.24,5.75,1.24h3.74   c2.96,0,5.75,0,5.75-1.24v-0.37c0-3.09-2.53-5.6-5.65-5.66L13.13,15.52z M14.81,5.22c-2.45,0-4.44,1.96-4.44,4.37   c0,2.41,1.99,4.37,4.44,4.37c2.45,0,4.44-1.96,4.44-4.37C19.26,7.18,17.26,5.22,14.81,5.22z"
              />
            </g>
          </svg>
        </div>
        <el-dropdown-menu class="loggedDropdownMenu" slot="dropdown">
          <el-dropdown-item @click.native="exit()">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <leftbar
      ref="leftbar"
      :groupList="groupList"
      @openDialog="openDialogFun"
      @updateGroupList="handleGroupList"
      @updataTopicPrefix="handleTopicPrefix"
    />
    <mainview ref="mainview" :receiveNews="receiveNews" />
    <!-- 连接 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :custom-class="formName + 'Dialog'"
      :before-close="handleClose"
    >
      <div slot="title" class="dialog-title">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 60 60">
          <circle class="st0" cx="30.02" cy="30.03" r="29.5" />
          <path
            class="st1"
            d="M30.02,60.03c-16.54,0-30-13.46-30-30c0-16.54,13.46-30,30-30s30,13.46,30,30  C60.02,46.57,46.57,60.03,30.02,60.03z M30.02,1.03c-15.99,0-29,13.01-29,29s13.01,29,29,29c15.99,0,29-13.01,29-29  S46.01,1.03,30.02,1.03z"
          />
          <g>
            <g>
              <path
                class="st2"
                d="M33.84,26.5c-0.24-0.24-0.49-0.45-0.75-0.64l0,0c-0.27-0.26-0.63-0.41-1.03-0.41c-0.82,0-1.48,0.66-1.48,1.48    c0,0.36,0.13,0.69,0.35,0.95c0,0,0,0,0,0c0.1,0.12,0.23,0.23,0.37,0.31c0.14,0.12,0.28,0.23,0.41,0.36l0.12,0.12    c1.54,1.53,1.08,4.18-0.46,5.71l-6.55,6.55c-1.54,1.53-4.03,1.53-5.57,0l-0.12-0.12c-1.54-1.54-1.54-4.03,0-5.57l2.9-2.89    c0.37-0.29,0.61-0.75,0.61-1.26c0-0.89-0.72-1.61-1.61-1.61c-0.34,0-0.65,0.1-0.9,0.28c0,0,0,0,0-0.01l-0.03,0.03    c-0.11,0.08-0.22,0.18-0.31,0.29l-3.01,2.82c-2.83,2.83-2.83,7.46,0,10.29l0.12,0.12c2.83,2.83,7.46,2.83,10.29,0l6.55-6.55    c2.82-2.83,3.06-7.29,0.24-10.12L33.84,26.5z"
              />
            </g>
            <g>
              <path
                class="st2"
                d="M43.29,16.89l-0.12-0.12c-2.83-2.83-7.46-2.83-10.29,0l-6.55,6.55c-2.83,2.83-2.98,6.91-0.16,9.74l0.12,0.12    c0.13,0.13,0.26,0.25,0.4,0.37c0.1,0.11,0.21,0.2,0.34,0.28c0,0,0,0,0,0l0,0c0.21,0.12,0.44,0.19,0.7,0.19    c0.77,0,1.4-0.63,1.4-1.4c0-0.22-0.05-0.42-0.14-0.61c-0.18-0.42-0.53-0.67-0.77-0.91l-0.12-0.12c-1.54-1.54-0.95-3.77,0.58-5.3    l6.56-6.55c1.53-1.54,4.03-1.54,5.57,0l0.12,0.12c1.54,1.54,1.54,4.04,0,5.57l-2.89,2.89c-0.39,0.29-0.65,0.76-0.65,1.28    c0,0.88,0.72,1.6,1.6,1.6c0.31,0,0.59-0.09,0.84-0.24c0,0,0,0.01,0.01,0.01l0.04-0.04c0.14-0.09,0.26-0.21,0.36-0.34l3.04-2.81    C46.12,24.35,46.12,19.72,43.29,16.89L43.29,16.89z"
              />
            </g>
          </g>
        </svg>
        <span class="title-text">连接配置</span>
        <div class="square"></div>
      </div>
      <!-- 创建/加入小组 -->
      <el-form v-if="formName == 'jionGroup'" ref="group" class="jionGroup" hide-required-asterisk :model="groupFrom">
        <el-form-item prop="name" label="Group Name :">
          <el-input v-model="groupFrom.group_name" placeholder="请输入小组名称"></el-input>
        </el-form-item>
        <el-form-item prop="password" label="Group PWD :">
          <el-input v-model="groupFrom.password" placeholder="请输入密码" show-password></el-input>
        </el-form-item>
        <div>
          <!-- <el-button type="primary" @click="creatGroup">创 建</el-button> -->
          <el-button type="primary" @click="jionGroup">加 入</el-button>
        </div>
      </el-form>
      <!-- 连接MQTT -->
      <el-form v-else-if="formName == 'MQTTconnect'" ref="configForm" class="MQTTconnect" :model="connection">
        <el-form-item prop="host" label="Host :">
          <el-select
            popper-class="hostSelect"
            v-model="connection.protocol"
            @change="handleProtocolChange"
            style="width: 1.125rem;margin-right: 0.125rem"
          >
            <el-option label="wss://" value="wss"></el-option>
            <!-- <el-option label="ws://" value="ws"></el-option>
            <el-option label="mqtt://" value="mqtt"></el-option>
            <el-option label="mqtts://" value="mqtts"></el-option> -->
          </el-select>
          <el-input
            v-model="connection.host"
            :disabled="mqttFormDisable"
            style="width: calc(100% - 1.25rem)"
          ></el-input>
        </el-form-item>
        <el-form-item prop="port" label="Port :">
          <el-input v-model.number="connection.port" :disabled="mqttFormDisable" placeholder="1884"></el-input>
        </el-form-item>
        <el-form-item prop="endpoint" label="Mountpoint :">
          <el-input v-model="connection.endpoint" :disabled="mqttFormDisable" placeholder="/mqtt"></el-input>
        </el-form-item>
        <el-form-item prop="clientId" label="Client ID :">
          <el-input v-model="connection.clientId"> </el-input>
        </el-form-item>
        <el-form-item prop="username" label="User ID :">
          <el-input
            v-model="connection.username"
            :type="mqttFormDisable ? 'password' : ''"
            :disabled="mqttFormDisable"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password" label="User PWD :">
          <el-input v-model="connection.password" type="password" :disabled="mqttFormDisable"></el-input>
        </el-form-item>
        <el-button
          type="primary"
          class="conn-btn"
          :disabled="connection.client.connected"
          @click="
            createConnection(dialogData.handle == 'creat' ? connection : mqttList[nowMqttIndex], dialogData.handle)
          "
          :loading="connection.connecting"
        >
          {{ connection.client.connected ? '已连接' : '连 接' }}
        </el-button>
        <el-button
          v-if="connection.client.connected"
          type="danger"
          class="conn-btn"
          @click="destroyConnection(mqttList[nowMqttIndex])"
          style="margin-left: 0.25rem"
        >
          断开连接
        </el-button>
      </el-form>
      <!-- 订阅主题 -->
      <el-form
        v-else-if="formName == 'subscribeTopic'"
        ref="subscription"
        class="subscribeTopic"
        hide-required-asterisk
        :model="subscription"
      >
        <el-form-item prop="topic" label="Topic :">
          <el-input v-model="topicPrefix" disabled></el-input>
          <el-input v-model="subscription.topic"></el-input>
        </el-form-item>
        <el-form-item prop="qos" label="QoS :">
          <el-select v-model="subscription.qos" popper-class="qosSelect" style="width: 100%">
            <el-option v-for="qos in qosList" :key="qos" :label="qos" :value="qos"></el-option>
          </el-select>
        </el-form-item>
        <el-button
          type="primary"
          class="subscribe-btn"
          :disabled="subscription.subscribeSuccess"
          @click="doSubscribe(mqttList[nowMqttIndex])"
          :loading="subscription.subscribing"
          >{{ subscription.subscribeSuccess ? '已订阅' : '订阅' }}</el-button
        >
        <el-button
          v-if="subscription.subscribeSuccess"
          :disabled="!connection.client.connected"
          type="danger"
          class="subscribe-btn"
          @click="doUnSubscribe"
          style="margin-left: 0.25rem"
          >退订</el-button
        >
      </el-form>
    </el-dialog>

    <!-- 登录 -->
    <loginView
      ref="loginView"
      v-show="loginViewShow"
      @closeLogin="loginViewShow = false"
      @getGroupList="handleGroupList"
    />
  </div>
</template>

<script>
import { blue_light } from '../assets/style/blue_light'
import { blue_dark } from '../assets/style/blue_dark'
import { green_light } from '../assets/style/green_light'
import { green_dark } from '../assets/style/green_dark'

import { mapState, mapMutations } from 'vuex'
import leftbar from '@/component/leftbar'
import mainview from '@/component/mainview'
import loginView from '@/component/loginView'
import mqtt from 'mqtt'

export default {
  name: 'index',
  data() {
    return {
      dialogVisible: false,
      mqttFormDisable: true,
      formName: 'jionGroup',
      dialogData: '',
      beforEditClientId: '',
      connection: {
        keepalive: 10,
        protocol: 'wss',
        // host: 'ai.stemedu.cn',
        host: 'broker.emqx.io',
        port: 8084,
        endpoint: '/mqtt',
        clean: true,
        connectTimeout: 30 * 1000, // 在收到 CONNACK 之前等待的时间(ms)
        reconnectPeriod: 0, // 两次重新连接之间的间隔(ms)  0禁止重连
        clientId:
          'aieducation_' +
          Math.random()
            .toString(16)
            .substring(2, 8),
        username: '',
        password: '',
        connecting: false, // 正在连接状态标识
        client: {
          connected: false, // 是否连接成功
        },
        topicList: [],
        thirdparty: false,
      },
      port: {},
      groupFrom: {
        group_name: '',
        password: '',
      },
      groupList: {
        admin: [
          // {
          //   group_name: 'group1',
          //   is_admin: true
          // },
        ],
        common: [
          // {
          //   group_name: 'group2',
          //   is_admin: false
          // },
        ],
      },
      topicPrefix: '',
      subscription: {
        clientId: '',
        topic: '',
        qos: 0,
        subscribeSuccess: false,
        subscribing: false,
      },
      receiveNews: '',
      qosList: [0, 1, 2],
      retryTimes: 0,
      loginViewShow: false,
    }
  },
  computed: {
    ...mapState(['loginStatus', 'mqttList', 'nowGroup', 'nowMqttIndex', 'nowTopic']),
  },
  components: {
    leftbar,
    mainview,
    loginView,
  },
  watch: {
    nowMqttIndex(value) {},
    'connection.clientId': {
      handler(val1, val2) {
        if (val1.length < 10) {
          this.connection.clientId = val2
        }
      },
    },
  },
  updated() {},
  created() {
    let token = window.location.hash.split('?')[1]
    let urlFlag = sessionStorage.getItem('urlFlag')
    if (token && token.indexOf('token') != -1 && urlFlag != 'true') {
      token = token.substring(token.indexOf('=') + 1, token.length)
      sessionStorage.setItem('csrfToken', token)
      sessionStorage.setItem('nowMqttIndex', 0)
      this.updateLoginStatus(true)
    } else {
      token = window.sessionStorage.getItem('csrfToken')
      if (token == null || token == 'null') {
        sessionStorage.setItem('csrfToken', null)
        this.updateLoginStatus(false)
      } else {
        this.updateLoginStatus(true)
      }
    }
    this.skinChange(localStorage.getItem('themeColor'))
  },
  mounted() {
    // this.$notify({
    //   type: 'success',
    //   title: '创建小组成功',
    //   duration: 0
    // })
    // this.$notify({
    //   type: 'warning',
    //   title: '创建小组成功',
    //   duration: 0
    // })
    // this.$notify({
    //   type: 'info',
    //   title: '创建小组成功',
    //   duration: 0
    // })
    // this.$notify({
    //   type: 'error',
    //   title: '创建小组成功',
    //   duration: 0
    // })
    // rem适配
    this.rem()
    window.onresize = () => {
      this.rem()
    }

    // 监听页面刷新
    window.addEventListener('unload', this.handleUnload)

    if (this.loginStatus) {
      // 查询已加入小组
      this.$get('/group/list')
        .then(res => {
          if (res.code == 200) {
            this.handleGroupList(res.data.groups)
          }
        })
        .catch(err => {
          this.$notify.error({
            title: '报错',
            message: err,
          })
        })
    }
  },
  methods: {
    ...mapMutations([
      'updateThemeColor',
      'updateLoginStatus',
      'updateMqttList',
      'setMqttList',
      'updateNowGroup',
      'updateMqttIndex',
      'updateNowTopic',
      'setTopicList',
      'clearTopicList',
    ]),

    rem() {
      var docEl = document.documentElement
      if (
        navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|IPad|Android|wOSBrowser|BrowserNG|WebOS)/i) ||
        /mobile/i.test(navigator.userAgent) ||
        'ontouchstart' in window ||
        'onorientationchange' in window
      ) {
        window.rem = docEl.getBoundingClientRect().width / 24
      } else {
        window.rem = docEl.getBoundingClientRect().width / 24
      }
      docEl.style.fontSize = window.rem + 'px'
    },

    handleUnload() {
      let arr = []
      this.mqttList.forEach(item => {
        arr.push({
          keepalive: item.keepalive,
          protocol: item.protocol,
          host: item.host,
          port: item.port,
          endpoint: item.endpoint,
          clean: item.clean,
          connectTimeout: item.connectTimeout,
          reconnectPeriod: item.reconnectPeriod,
          clientId: item.clientId,
          username: item.username,
          password: item.password,
          connecting: false,
          client: {
            connected: false,
          },
          topicList: [],
          thirdparty: item.thirdparty,
        })
      })
      sessionStorage.setItem('mqttList', JSON.stringify(arr))
    },

    test() {},

    exit() {
      // 移除页面刷新的监听
      window.removeEventListener('unload', this.handleUnload)
      sessionStorage.clear()
      location.reload()
    },

    skinChange(data) {
      switch (data) {
        case '1': // 亮-绿
          this.updateThemeColor(green_light)
          break
        case '2': // 暗-绿
          this.updateThemeColor(green_dark)
          break
        case '3': // 亮-蓝
          this.updateThemeColor(blue_light)
          break
        case '4': // 暗-蓝
          this.updateThemeColor(blue_dark)
          break
        default:
          this.updateThemeColor(green_light)
          break
      }
      localStorage.setItem('themeColor', data)
      this.$parent.changeColor()
    },

    getNowDate() {
      let myDate = new Date()
      return (
        myDate.getFullYear() +
        '-' +
        [myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1] +
        '-' +
        [myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()] +
        ' ' +
        [myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours()] +
        ':' +
        [myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes()] +
        ':' +
        [myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds()]
      )
    },

    initData() {
      this.connection = {
        protocol: 'wss',
        host: 'broker.emqx.io',
        port: 8084,
        endpoint: '/mqtt',
        clean: true,
        connectTimeout: 30 * 1000, // 在收到 CONNACK 之前等待的时间(ms)
        reconnectPeriod: 0, // 两次重新连接之间的间隔(ms)
        clientId:
          'aieducation_' +
          Math.random()
            .toString(16)
            .substring(2, 8),
        username: '',
        password: '',
        connecting: false,
        client: {
          connected: false,
        },
        topicList: [],
        thirdparty: false,
      }
    },

    openDialogFun(data, config) {
      this.dialogData = data
      this.formName = data.name
      if (this.dialogData.handle == 'creat') {
        if (this.formName == 'MQTTconnect') {
          this.initData()
          if (this.dialogData.type == 'my') {
            // 获取MQTT连接信息
            if (this.nowGroup.length > 0) {
              this.$get('/mqtt/connect_info', {
                group_name: this.nowGroup,
              })
                .then(res => {
                  if (res.code == 200) {
                    this.connection.host = res.data.host
                    this.connection.clientId = res.data.client_id
                    this.connection.port = res.data.wss_port
                    this.connection.username = res.data.username
                    this.connection.password = res.data.password
                    this.subscription.topic = res.data.group_topic
                    this.port = {
                      ws_port: res.data.ws_port, // 80
                      wss_port: res.data.wss_port, // 443
                      mqtt_port: res.data.mqtt_port, // 1883
                      mqtts_port: res.data.mqtts_port, // 8883
                    }
                    this.mqttFormDisable = true
                    this.dialogVisible = true
                  } else {
                    this.$notify({
                      type: 'warning',
                      title: '警告',
                      message: '获取MQTT连接信息失败',
                    })
                  }
                })
                .catch(err => {
                  this.$notify.error({
                    title: '报错',
                    message: '未加入小组',
                  })
                })
            } else {
              this.$notify.error({
                title: '报错',
                message: '未选择小组',
              })
            }
          } else {
            this.connection.thirdparty = true
            this.mqttFormDisable = false
            this.dialogVisible = true
          }
        } else if (this.formName == 'subscribeTopic') {
          this.subscription = {
            clientId: '',
            topic: '',
            qos: 0,
            subscribeSuccess: false,
            subscribing: false,
          }
          try {
            this.handleTopicPrefix(this.mqttList[this.nowMqttIndex].thirdparty)
          } catch (error) {
            this.handleTopicPrefix(true)
          }
          this.dialogVisible = true
        } else if (this.formName == 'jionGroup') {
          this.groupFrom = {
            group_name: '',
            password: '',
          }
          this.dialogVisible = true
        }
      } else {
        if (this.formName == 'MQTTconnect') {
          this.beforEditClientId = config.clientId
          this.connection = JSON.parse(JSON.stringify(config))
          this.connection.clientId = JSON.parse(JSON.stringify(config.clientId))
          if (this.dialogData.type == 'my') {
            this.mqttFormDisable = true
          } else {
            this.mqttFormDisable = false
          }
        } else if (this.formName == 'subscribeTopic') {
          this.connection = JSON.parse(JSON.stringify(this.mqttList[this.nowMqttIndex]))
          this.subscription = {
            clientId: '',
            topic: Object.keys(data.data)[0],
            qos: data.data[Object.keys(data.data)[0]].qos,
            subscribeSuccess: data.data[Object.keys(data.data)[0]].subscribeSuccess,
          }
        }
        this.dialogVisible = true
      }
    },

    handleClose(done) {
      // this.initData()
      this.groupFrom = {
        group_name: '',
        password: '',
      }
      this.subscription = {
        clientId: '',
        topic: '',
        qos: 0,
        subscribeSuccess: false,
      }
      done()
    },

    handleProtocolChange(value) {
      if (this.dialogData.type == 'my') {
        switch (value) {
          case 'ws':
            this.connection.port = this.port.ws_port
            break
          case 'wss':
            this.connection.port = this.port.wss_port
            break
          case 'mqtt':
            this.connection.port = this.port.mqtt_port
            break
          case 'mqtts':
            this.connection.port = this.port.mqtts_port
            break
          default:
            break
        }
      } else {
        if (value == 'ws') {
          this.connection.port = 8083
        } else {
          this.connection.port = 8084
        }
      }
    },

    // 可用小组分类
    handleGroupList(list, flag) {
      this.groupList = {
        admin: [],
        common: [],
      }
      if (list.length > 0) {
        list.forEach(item => {
          if (item.is_admin) {
            this.groupList.admin.push(item)
          } else {
            this.groupList.common.push(item)
          }
        })
      }
      if (!flag) {
        let group_name = ''
        if (this.groupList.admin.length > 0) {
          group_name = this.groupList.admin[0].group_name
        } else if (this.groupList.common.length > 0) {
          group_name = this.groupList.common[0].group_name
        }
        try {
          let name = sessionStorage.getItem('nowGroup')
          let mqttHistory = sessionStorage.getItem('mqttList') ? sessionStorage.getItem('mqttList') : '[]'
          let mqttIndex = sessionStorage.getItem('nowMqttIndex')
          this.updateMqttList(JSON.parse(mqttHistory))
          this.updateMqttIndex(parseInt(mqttIndex))
          if (name.length > 0) {
            this.updateNowGroup(name)
            this.groupFrom.group_name = name
            this.topicPrefix = 'group/' + name + '/'
          } else {
            this.updateNowGroup(group_name)
            this.groupFrom.group_name = group_name
            this.topicPrefix = 'group/' + group_name + '/'
          }
        } catch (error) {
          this.updateNowGroup(group_name)
          this.groupFrom.group_name = group_name
          this.topicPrefix = 'group/' + group_name + '/'
        }
      }
    },

    // 修改主题前缀
    handleTopicPrefix(data) {
      if (data) {
        this.topicPrefix = ''
      } else {
        this.topicPrefix = 'group/' + this.nowGroup + '/'
      }
    },

    // 创建小组
    creatGroup() {
      this.$post('/group/create', this.groupFrom)
        .then(res => {
          if (res.code == 200) {
            this.$notify({
              type: 'success',
              title: '创建小组成功',
            })
            this.dialogVisible = false
            this.$refs['group'].resetFields()
          } else {
            this.$notify({
              type: 'warning',
              title: '警告',
              message: '该组名已存在或存在违规字符，请修改',
            })
          }
        })
        .catch(err => {
          this.$notify.error({
            title: '报错',
            message: err,
          })
        })
    },

    // 加入小组
    jionGroup() {
      this.$post('/group/join', this.groupFrom)
        .then(res => {
          if (res.code == 200) {
            this.updateNowGroup(this.groupFrom.group_name)
            this.topicPrefix = 'group/' + this.groupFrom.group_name + '/'
            this.$notify({
              title: '加入小组成功',
              type: 'success',
            })
            this.dialogVisible = false
            this.$refs['group'].resetFields()
            // 查询已加入小组
            this.$get('/group/list')
              .then(res => {
                if (res.code == 200) {
                  this.handleGroupList(res.data.groups, true)
                } else {
                  this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '获取小组失败',
                  })
                }
              })
              .catch(err => {
                this.$notify.error({
                  title: '报错',
                  message: err,
                })
              })
          } else {
            this.$notify({
              type: 'warning',
              title: '警告',
              message: '加入小组失败',
            })
          }
        })
        .catch(err => {
          this.$notify.error({
            title: '该小组名称已存在，请输入正确密码',
            message: err.response.data.message,
          })
        })
    },

    // 创建MQTT连接
    createConnection(config, type) {
      try {
        config.connecting = true
        const { protocol, host, port, endpoint, ...options } = config
        const connectUrl = `${protocol}://${host}:${port}${endpoint}`
        let flag = true
        if (this.mqttList.length > 0) {
          this.mqttList.forEach(item => {
            if (item.clientId == this.connection.clientId) {
              flag = false
            }
          })
        }
        if (type == 'reconnection' || (type == 'edit' && config.clientId == this.connection.clientId)) {
          flag = true
        }
        if (!flag) {
          config.connecting = false
          this.connection.clientId = config.clientId
          return this.$notify({
            type: 'warning',
            title: '警告',
            message: '当前Client ID已使用，请修改',
          })
        }
        if (type == 'edit') {
          config.clientId = this.connection.clientId
        }
        config.client = mqtt.connect(connectUrl, options)
        if (config.client.on) {
          config.client.on('connect', () => {
            config.connecting = false
            // 是否为新增连接
            if (type == 'creat') {
              this.setMqttList(config)
              this.updateMqttIndex(this.mqttList.length - 1)
            }
            this.listenMqttMessage(config)
            this.$notify({
              title: '连接mqtt成功',
              type: 'success',
            })
            this.initData()
            this.dialogVisible = false
          })
          // config.client.on('reconnect', this.handleOnReConnect)
          config.client.on('error', error => {
            this.$notify.error({
              title: '连接失败',
              message: error,
            })
          })
        }
      } catch (error) {
        config.connecting = false
        this.$notify.info({
          title: '连接中断',
          message: error,
        })
        this.dialogVisible = false
        this.initData()
      }
    },

    // 尝试重新连接MQTT
    handleOnReConnect() {
      this.retryTimes += 1
      if (this.retryTimes > 5) {
        try {
          this.client.end()
          this.initData()
          this.$notify({
            type: 'warning',
            title: '警告',
            message: '超过最大自动重连次数',
          })
        } catch (error) {
          this.$notify.error({
            title: '报错',
            message: error,
          })
        }
      }
    },

    // 断开MQTT连接
    destroyConnection(data) {
      if (data.client.connected) {
        try {
          data.client.end(false, () => {
            this.retryTimes = 0
            data.client = {
              connected: false,
            }
            this.connection.client = {
              connected: false,
            }
            this.clearTopicList()
            this.$notify({
              title: '断开连接成功',
              type: 'success',
            })
          })
        } catch (error) {
          this.$notify.error({
            title: '断开连接失败',
            message: error.toString(),
          })
        }
      }
    },

    // 创建/订阅 主题
    doSubscribe(nowConnection) {
      let topicName = this.topicPrefix + this.subscription.topic
      try {
        this.subscription.subscribing = true
        if (nowConnection.client.connected) {
          if (nowConnection.clientId.indexOf('GID--TQ') != -1) {
            if (this.nowGroup.length > 0) {
              nowConnection.client.subscribe(topicName, this.subscription.qos, (error, res) => {
                if (error) {
                  this.$notify.error({
                    title: '订阅失败',
                    message: error,
                  })
                } else {
                  this.setTopicList({
                    clientId: nowConnection.clientId,
                    topicName: topicName,
                    qos: this.subscription.qos,
                    subscribeSuccess: true,
                  })
                  this.updateNowTopic(topicName)
                  this.$notify({
                    type: 'success',
                    title: '订阅成功',
                  })
                  this.dialogVisible = false
                }
                this.subscription.subscribing = false
              })
            } else {
              this.subscription.subscribing = false
              this.$notify.error({
                title: '订阅失败',
                message: '未加入小组',
              })
            }
          } else {
            nowConnection.client.subscribe(topicName, this.subscription.qos, (error, res) => {
              if (error) {
                this.$notify.error({
                  title: '订阅失败',
                  message: error,
                })
              } else {
                this.setTopicList({
                  clientId: nowConnection.clientId,
                  topicName: topicName,
                  qos: this.subscription.qos,
                  subscribeSuccess: true,
                })
                this.updateNowTopic(topicName)
                this.$notify({
                  type: 'success',
                  title: '订阅成功',
                })
                this.dialogVisible = false
              }
              this.subscription.subscribing = false
            })
          }
        } else {
          this.$notify.error({
            title: '订阅失败',
            message: '未连接MQTT服务器',
          })
          this.subscription.subscribing = false
        }
      } catch (err) {
        this.$notify.error({
          title: '订阅失败',
          message: err,
        })
        this.subscription.subscribing = false
      }
    },

    // 监听订阅的主题信息
    listenMqttMessage(data) {
      data.client.on('message', (topic, message) => {
        // console.log(topic, `${message}`, data.host)
        try {
          let array = Object.keys(JSON.parse(message))
          let num = 0
          array.forEach(item => {
            if (
              item == 'time' ||
              item == 'qos' ||
              item == 'topic' ||
              item == 'port' ||
              item == 'key' ||
              item == 'value' ||
              item == 'clientId' ||
              item == 'server'
            ) {
              num = ++num
            }
          })
          if (num == 8) {
            this.receiveNews = JSON.parse(message)
            // this.receiveNews.clientId = data.clientId
            this.receiveNews.server = data.host
            this.receiveNews.topic = topic
            this.receiveNews.time = this.getNowDate()
          } else {
            message = JSON.parse(message)
            this.receiveNews = {
              clientId: message.clientId ? message.clientId : '',
              topic: topic,
              qos: message.qos ? message.qos : '',
              time: this.getNowDate(),
              port: message.port ? message.port : '',
              key: message.key ? message.key : '',
              value: message.value ? message.value : JSON.stringify(message),
              server: message.server ? message.server : '',
            }
          }
        } catch (error) {
          this.receiveNews = {
            clientId: '',
            topic: topic,
            qos: '',
            time: this.getNowDate(),
            port: '',
            key: '',
            value: `${message}`,
            server: '',
          }
        }
      })
    },
  },
  beforeDestroy() {
    // 移除页面刷新的监听
    window.removeEventListener('unload', this.handleUnload)
  },
}
</script>
<style lang="scss" scoped>
#index {
  height: 100%;
  text-align: center;
  background-color: var(--index0);
  .header {
    position: relative;
    height: 0.75rem;
    width: 100%;
    .logo {
      position: absolute;
      top: 0.1875rem;
      left: 0.2875rem;
      width: 2.2875rem;
      height: 0.375rem;
      .st0 {
        fill: var(--index1);
      }
    }
    .test {
      position: absolute;
      top: 0.25rem;
      right: 3rem;
      height: 0.625rem;
      width: 2.5rem;
      font-size: 0.2rem;
      background-color: yellowgreen;
    }
    .skinChange {
      position: absolute;
      top: 0.25rem;
      right: 0.75rem;
      width: 0.375rem;
      height: 0.375rem;
      font-size: 0;
      cursor: pointer;
      .st0 {
        stroke-miterlimit: 10;
        fill: var(--index2);
        stroke: var(--index3);
      }
      .st1 {
        fill: var(--index4);
      }
    }
    .login {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      width: 0.375rem;
      height: 0.375rem;
      font-size: 0;
      cursor: pointer;
      .st0 {
        stroke-miterlimit: 10;
        fill: var(--index5);
        stroke: var(--index6);
      }
      .st1 {
        fill: var(--index7);
      }
      .st2 {
        fill: var(--index8);
      }
    }
    .el-dropdown [disabled] {
      cursor: pointer;
    }
  }
  .content {
    position: relative;
    height: calc(100% - 0.9rem);
    overflow: auto;
  }
  .el-dialog__wrapper::v-deep {
    font-size: 0.25rem;
    .el-dialog {
      top: 50%;
      transform: translateY(-50%);
      width: 10rem;
      margin-top: 0 !important;
      border-radius: 0.375rem;
      overflow: hidden;
      background-color: var(--index12);
      .el-dialog__header {
        padding: 0;
        height: 1.375rem;
        line-height: 1.375rem;
        .dialog-title {
          position: relative;
          display: flex;
          justify-content: center;
          font-size: 0.25rem;
          color: var(--index13);
          height: 100%;
          svg {
            height: 0.75rem;
            width: 0.75rem;
            margin-top: 0.3125rem;
            margin-right: 0.25rem;
            .st0 {
              fill: var(--index14);
            }
            .st1 {
              fill: var(--index15);
            }
            .st2 {
              fill: var(--index16);
            }
          }
          .square {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 50%) rotate(45deg);
            height: 0.25rem;
            width: 0.25rem;
            background-color: var(--index12);
          }
        }
        .el-dialog__headerbtn {
          font-size: 0.25rem;
          top: 0.25rem;
          right: 0.25rem;
          .el-dialog__close {
            color: var(--index17);
          }
        }
        .el-dialog__headerbtn:focus .el-dialog__close,
        .el-dialog__headerbtn:hover .el-dialog__close {
          color: var(--index17);
        }
      }
      .el-dialog__body {
        padding: 0;
        height: 3.125rem;
        overflow: hidden;
        border-radius: 0.375rem;
        background-color: var(--index18);
        .MQTTconnect,
        .jionGroup,
        .subscribeTopic {
          height: 100%;
          padding-top: 0.625rem;
          .el-form-item {
            display: flex;
            justify-content: center;
            height: 0.375rem;
            line-height: 0.375rem;
            margin-bottom: 0.25rem;
            font-size: 0.2rem;
            .el-form-item__label {
              flex: 1;
              font-size: 0.2rem;
              color: var(--index19);
              line-height: 0.375rem;
              padding: 0 0.1625rem 0 0;
            }
            .el-form-item__content {
              display: inline-block;
              font-size: 0.2rem;
              width: 6.25rem;
              line-height: 0.375rem;
              margin-right: 1.25rem;
              .el-input {
                font-size: 0.2rem;
                height: 0.375rem;
                line-height: 0.375rem;
                .el-input__suffix {
                  right: 0.0625rem;
                  .el-input__icon {
                    width: 0.3125rem;
                  }
                }
                .el-input__inner {
                  font-size: 0.2rem;
                  height: 0.375rem;
                  line-height: 0.375rem;
                  border: none;
                  border-radius: 0.0625rem;
                  color: var(--index20);
                  background-color: var(--index21);
                  padding: 0 0.1875rem;
                }
              }
              .el-input.is-disabled {
                .el-input__inner {
                  color: var(--index22);
                  background-color: var(--index23);
                }
              }
              .el-input__icon {
                font-size: 0.2rem;
                line-height: 0.375rem;
              }
            }
          }
          .el-button {
            font-size: 0.2rem;
            width: 2.5rem;
            height: 0.5rem;
            background-color: var(--index25);
            border: none;
            border-radius: 0.0625rem;
            word-spacing: 0.125rem;
            margin-top: 0.25rem;
            padding: 0.15rem 0.25rem;
          }
          .el-button--danger {
            color: var(--index26);
            background-color: var(--index27);
          }
        }
        .subscribeTopic {
          .el-form-item {
            .el-form-item__content {
              width: 6.875rem;
            }
            &:first-child {
              .el-input {
                width: calc(50% - 0.0625rem);
                &:first-child {
                  margin-right: 0.125rem;
                }
              }
            }
          }
        }
      }
    }
    .MQTTconnectDialog {
      .el-dialog__body {
        height: 5.625rem;
      }
    }
    .subscribeTopicDialog {
      .el-dialog__body {
        height: 3.125rem;
      }
    }
  }
  ::-webkit-input-placeholder {
    color: var(--index24);
  }
}
.loggedDropdownMenu::v-deep,
.skinDropdownMenu::v-deep {
  padding: 0;
  width: 3.125rem;
  border: none;
  border-radius: 0.0625rem;
  background-color: var(--index9);
  .popper__arrow {
    border-bottom-color: var(--index9);
    &::after {
      border-bottom-color: var(--index9);
    }
  }
  .el-dropdown-menu__item {
    padding: 0.125rem 0.0625rem;
    color: var(--index10);
    font-size: 0.2rem;
    line-height: 1;
    border-radius: 0.0625rem;
    &:focus,
    &:hover {
      color: var(--index10);
      background-color: var(--index31);
    }
    .item-box {
      display: flex;
      align-items: center;
      padding: 0 0.1875rem;
      .item-box-color {
        display: flex;
        flex: 1;
        height: 0.125rem;
        margin-right: 0.275rem;
        border: 0.0125rem solid var(--index11);
        border-radius: 0.0625rem;
      }
    }
  }
}
.loggedDropdownMenu::v-deep {
  width: auto;
}
</style>

<style lang="scss">
.qosSelect.el-select-dropdown.el-popper,
.hostSelect.el-select-dropdown.el-popper,
.qosSelect1.el-select-dropdown.el-popper,
.el-autocomplete-suggestion.el-popper,
.advSelect.el-select-dropdown.el-popper {
  border: none;
  border-radius: 0.0625rem;
  box-shadow: 0.0125rem 0.0125rem 0.0625rem 0 rgba(0, 0, 0, 0.25);
  background-color: var(--index28);
  .popper__arrow {
    border-top-color: var(--index28);
    &::after {
      border-top-color: var(--index28);
      border-bottom-color: var(--index28);
    }
  }
  .el-select-dropdown__list,
  .el-autocomplete-suggestion__wrap {
    padding: 0;
  }
  .el-select-dropdown__item,
  .el-scrollbar__view li {
    font-size: 0.2rem;
    padding: 0.125rem;
    height: auto;
    line-height: 1;
    border-radius: 0.0625rem;
    color: var(--index29);
    &.hover {
      color: var(--index30);
      background-color: var(--index31);
    }
    &.selected {
      color: var(--index30);
    }
  }
  .el-scrollbar__view {
    > li {
      &:hover {
        color: var(--index30);
        background-color: var(--index31);
      }
    }
  }
}
.qosSelect.el-select-dropdown.el-popper,
.hostSelect.el-select-dropdown.el-popper {
  background-color: var(--index32);
  .popper__arrow {
    &::after {
      border-top-color: var(--index32);
      border-bottom-color: var(--index32);
    }
  }
  .el-select-dropdown__item {
    color: var(--index33);
    &.hover {
      color: var(--index34);
      background-color: var(--index35);
    }
    &.selected {
      color: var(--index34);
    }
  }
}
.el-message-box__wrapper {
  .confirm {
    padding-bottom: 0.375rem;
    width: 5.25rem;
    border-radius: 0.125rem;
    font-size: 0.225rem;
    background-color: var(--index36);
    .el-message-box__header {
      padding: 0.25rem 0.1875rem 0.125rem;
      .el-message-box__title {
        font-size: 0.25rem;
      }
      .el-message-box__headerbtn {
        top: 0.1875rem;
        right: 0.1875rem;
        font-size: 0.2rem;
        .el-message-box__close {
          color: var(--index37);
        }
        &:hover {
          .el-message-box__close {
            color: var(--index37);
          }
        }
      }
    }
    .el-message-box__content {
      padding: 0.125rem 0.3375rem;
    }
    .el-message-box__btns {
      padding: 0.0625rem 0.1875rem 0;
      .el-button--small {
        padding: 0;
        width: 0.55rem;
        height: 0.4rem;
        line-height: 0.4rem;
        font-size: 0.15rem;
        border-radius: 0.05rem;
        border: none;
        color: var(--index38);
        background-color: var(--index39);
        &:nth-child(2) {
          margin-left: 0.125rem;
          color: var(--index39);
          background-color: var(--index38);
        }
      }
    }
  }
}
.rightClickMenu.menu {
  border-radius: 0.0625rem;
  padding: 0;
  background-color: var(--index40);
  .menu_item {
    line-height: 0.4rem;
    padding: 0;
    font-size: 0.2rem;
    color: var(--index41);
    .menu_item_label {
      text-align: center;
    }
    .menu_item_expand_icon {
      display: none;
    }
    &:hover {
      border-radius: 0.0625rem;
      color: var(--index42);
      background-color: var(--index43);
    }
  }
}
</style>
