<template>
  <div id="dataDispose" v-resize="resize">
    <div class="main">
      <div class="sensor">
        <div class="title">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 30 30">
            <path
              class="st0"
              d="M24.16,6.06c2.43,2.43,3.79,5.72,3.79,9.15c0,3.34-1.28,6.54-3.59,8.95l-0.19,0.19l-1.28-1.28  c2.09-2.09,3.26-4.92,3.25-7.87c0-2.86-1.1-5.62-3.08-7.69l-0.18-0.19L24.16,6.06L24.16,6.06z M5.85,6.05l1.28,1.28  c-2.1,2.09-3.27,4.92-3.27,7.88c0,2.86,1.1,5.61,3.07,7.68l0.18,0.18l-1.28,1.28c-2.42-2.42-3.78-5.71-3.78-9.14  C2.05,11.73,3.44,8.46,5.85,6.05L5.85,6.05z M8.41,8.61l1.28,1.28c-1.42,1.41-2.21,3.33-2.21,5.33c0,1.96,0.75,3.8,2.08,5.19  l0.15,0.15l-1.27,1.28c-1.77-1.75-2.76-4.14-2.76-6.63C5.67,12.7,6.67,10.34,8.41,8.61L8.41,8.61z M21.6,8.61  c1.75,1.75,2.74,4.12,2.73,6.6c0,2.4-0.92,4.7-2.57,6.43l-0.17,0.17l-1.28-1.28c1.42-1.41,2.21-3.33,2.21-5.33  c0-1.92-0.73-3.77-2.06-5.17l-0.15-0.15L21.6,8.61z M10.94,11.18l1.28,1.27c-0.73,0.73-1.14,1.72-1.14,2.76c0,1,0.37,1.93,1.03,2.65  l0.12,0.12l-1.28,1.28c-1.08-1.07-1.68-2.53-1.68-4.05C9.28,13.7,9.88,12.25,10.94,11.18L10.94,11.18z M19.05,11.18  c1.07,1.07,1.67,2.52,1.67,4.04c0,1.44-0.54,2.83-1.52,3.89l-0.13,0.14l-1.28-1.27c0.73-0.73,1.14-1.72,1.14-2.76  c0-0.97-0.36-1.91-1.02-2.63l-0.12-0.13L19.05,11.18z M15,12.8c1.33,0,2.41,1.08,2.41,2.41c0,1.33-1.08,2.41-2.41,2.41  c-1.33,0-2.41-1.08-2.41-2.41C12.59,13.88,13.67,12.8,15,12.8L15,12.8z M15,12.8"
            />
          </svg>
          <div>传 感 器</div>
          <svg @click="openExtendDialog('sensor')" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 30 30">
            <path
              class="st0"
              d="M21.79,13.84h-6.1v-6.1c0-0.52-0.43-0.95-0.95-0.95s-0.95,0.43-0.95,0.95v6.1h-6.1c-0.52,0-0.95,0.43-0.95,0.95  c0,0.52,0.43,0.95,0.95,0.95h6.1v6.1c0,0.52,0.43,0.95,0.95,0.95s0.95-0.43,0.95-0.95v-6.1h6.1c0.52,0,0.95-0.43,0.95-0.95  C22.74,14.27,22.31,13.84,21.79,13.84z"
            />
          </svg>
        </div>
        <div class="content" :style="'width: ' + eleWidth + 'px'">
          <div
            v-for="(item, index) in sensorArr"
            :key="index"
            class="sensorItem"
            @mouseover="mouseHover(item.index, item.port)"
            @mouseout="mouseOut()"
          >
            <el-button
              v-show="item.index == advIndex && item.port == advPort ? true : false"
              icon="el-icon-remove"
              circle
              @click.stop="delItem(index, item.index, item.key, item.port, true)"
            ></el-button>
            <div class="value">{{ item.value }}</div>
            <div class="name">{{ item.port }}</div>
            <div class="key">{{ item.key }}</div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="actuator">
        <div class="title">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 30 30">
            <g>
              <g>
                <path
                  class="st0"
                  d="M12.31,14.52h-4.7c-1.26,0-2.28-1.02-2.28-2.28v-4.7c0-1.26,1.02-2.28,2.28-2.28h4.7    c1.26,0,2.28,1.02,2.28,2.28v4.7C14.59,13.5,13.57,14.52,12.31,14.52z M7.61,7.09c-0.25,0-0.45,0.2-0.45,0.45v4.7    c0,0.25,0.2,0.45,0.45,0.45h4.7c0.25,0,0.45-0.2,0.45-0.45v-4.7c0-0.25-0.2-0.45-0.45-0.45H7.61z"
                />
              </g>
              <g>
                <path
                  class="st0"
                  d="M12.31,25.29h-4.7c-1.26,0-2.28-1.02-2.28-2.28v-4.7c0-1.26,1.02-2.28,2.28-2.28h4.7    c1.26,0,2.28,1.02,2.28,2.28v4.7C14.59,24.27,13.57,25.29,12.31,25.29z M7.61,17.86c-0.25,0-0.45,0.2-0.45,0.45v4.7    c0,0.25,0.2,0.45,0.45,0.45h4.7c0.25,0,0.45-0.2,0.45-0.45v-4.7c0-0.25-0.2-0.45-0.45-0.45H7.61z"
                />
              </g>
              <g>
                <path
                  class="st0"
                  d="M23.39,25.29h-4.7c-1.26,0-2.28-1.02-2.28-2.28v-4.7c0-1.26,1.02-2.28,2.28-2.28h4.7    c1.26,0,2.28,1.02,2.28,2.28v4.7C25.66,24.27,24.64,25.29,23.39,25.29z M18.69,17.86c-0.25,0-0.45,0.2-0.45,0.45v4.7    c0,0.25,0.2,0.45,0.45,0.45h4.7c0.25,0,0.45-0.2,0.45-0.45v-4.7c0-0.25-0.2-0.45-0.45-0.45H18.69z"
                />
              </g>
              <g>
                <path
                  class="st0"
                  d="M24.36,8.77l-6.1-3.52c-0.65-0.38-1.47,0.09-1.47,0.85v7.04c0,0.75,0.81,1.22,1.47,0.85l6.1-3.52    C25.01,10.08,25.01,9.14,24.36,8.77z"
                />
              </g>
            </g>
          </svg>
          <div>执 行 器</div>
          <svg
            @click="openExtendDialog('actuator')"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 30 30"
          >
            <path
              class="st0"
              d="M21.79,13.84h-6.1v-6.1c0-0.52-0.43-0.95-0.95-0.95s-0.95,0.43-0.95,0.95v6.1h-6.1c-0.52,0-0.95,0.43-0.95,0.95  c0,0.52,0.43,0.95,0.95,0.95h6.1v6.1c0,0.52,0.43,0.95,0.95,0.95s0.95-0.43,0.95-0.95v-6.1h6.1c0.52,0,0.95-0.43,0.95-0.95  C22.74,14.27,22.31,13.84,21.79,13.84z"
            />
          </svg>
        </div>
        <div class="content" :style="'width: ' + eleWidth + 'px'">
          <div
            v-for="(item, index) in actuatorArr"
            :key="index"
            class="actuatorItem"
            @mouseover="mouseHover(item.index, item.port)"
            @mouseout="mouseOut()"
          >
            <el-button
              v-show="item.index == advIndex && item.port == advPort ? true : false"
              icon="el-icon-remove"
              circle
              @click.stop="delItem(index, item.index, item.key, item.port, false)"
            ></el-button>
            <div class="value">{{ item.value }}</div>
            <div class="name">{{ item.port }}</div>
            <div class="key">{{ item.key }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加传感器/执行器 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="dialogName == 'sensor' ? '传 感 器' : '执 行 器'"
      :visible.sync="dialogVisible"
      custom-class="addExtendNew"
      @close="handleClose"
    >
      <div slot="title" class="dialog-title">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 60 60">
          <circle class="st0" cx="30.02" cy="30.03" r="29.5" />
          <path
            class="st1"
            d="M30.02,60.03c-16.54,0-30-13.46-30-30c0-16.54,13.46-30,30-30s30,13.46,30,30  C60.02,46.57,46.57,60.03,30.02,60.03z M30.02,1.03c-15.99,0-29,13.01-29,29s13.01,29,29,29c15.99,0,29-13.01,29-29  S46.01,1.03,30.02,1.03z"
          />
          <g>
            <g>
              <path
                class="st2"
                d="M41.04,16.53H18.96c-1.35,0-2.45,1.1-2.45,2.45v22.09c0,1.35,1.1,2.45,2.45,2.45h22.09    c1.35,0,2.45-1.1,2.45-2.45V18.99C43.5,17.63,42.4,16.53,41.04,16.53z M18.96,41.08V18.99h22.09l0,22.09H18.96z"
              />
              <path
                class="st2"
                d="M31.23,14.08v-2.45c0-0.68-0.55-1.23-1.23-1.23c-0.68,0-1.23,0.55-1.23,1.23v2.45c0,0.68,0.55,1.23,1.23,1.23    C30.68,15.31,31.23,14.76,31.23,14.08L31.23,14.08z"
              />
              <path
                class="st2"
                d="M27.55,14.08v-2.45c0-0.68-0.55-1.23-1.23-1.23c-0.68,0-1.23,0.55-1.23,1.23v2.45c0,0.68,0.55,1.23,1.23,1.23    C27,15.31,27.55,14.76,27.55,14.08L27.55,14.08z"
              />
              <path
                class="st2"
                d="M23.86,14.08v-2.45c0-0.68-0.55-1.23-1.23-1.23c-0.68,0-1.23,0.55-1.23,1.23v2.45c0,0.68,0.55,1.23,1.23,1.23    C23.31,15.31,23.86,14.76,23.86,14.08L23.86,14.08z"
              />
              <path
                class="st2"
                d="M34.91,14.08v-2.45c0-0.68-0.55-1.23-1.23-1.23c-0.68,0-1.23,0.55-1.23,1.23v2.45c0,0.68,0.55,1.23,1.23,1.23    C34.36,15.31,34.91,14.76,34.91,14.08L34.91,14.08z"
              />
              <path
                class="st2"
                d="M38.59,14.08v-2.45c0-0.68-0.55-1.23-1.23-1.23c-0.68,0-1.23,0.55-1.23,1.23v2.45c0,0.68,0.55,1.23,1.23,1.23    C38.04,15.31,38.59,14.76,38.59,14.08L38.59,14.08z"
              />
              <path
                class="st2"
                d="M28.77,45.99v2.45c0,0.68,0.55,1.23,1.23,1.23c0.68,0,1.23-0.55,1.23-1.23v-2.45c0-0.68-0.55-1.23-1.23-1.23    C29.32,44.76,28.77,45.31,28.77,45.99L28.77,45.99z"
              />
              <path
                class="st2"
                d="M25.09,45.99v2.45c0,0.68,0.55,1.23,1.23,1.23c0.68,0,1.23-0.55,1.23-1.23v-2.45c0-0.68-0.55-1.23-1.23-1.23    C25.64,44.76,25.09,45.31,25.09,45.99L25.09,45.99z"
              />
              <path
                class="st2"
                d="M21.41,45.99v2.45c0,0.68,0.55,1.23,1.23,1.23c0.68,0,1.23-0.55,1.23-1.23v-2.45c0-0.68-0.55-1.23-1.23-1.23    C21.96,44.76,21.41,45.31,21.41,45.99L21.41,45.99z"
              />
              <path
                class="st2"
                d="M32.45,45.99v2.45c0,0.68,0.55,1.23,1.23,1.23c0.68,0,1.23-0.55,1.23-1.23v-2.45c0-0.68-0.55-1.23-1.23-1.23    C33,44.76,32.45,45.31,32.45,45.99L32.45,45.99z"
              />
              <path
                class="st2"
                d="M36.14,45.99v2.45c0,0.68,0.55,1.23,1.23,1.23c0.68,0,1.23-0.55,1.23-1.23v-2.45c0-0.68-0.55-1.23-1.23-1.23    C36.69,44.76,36.14,45.31,36.14,45.99L36.14,45.99z"
              />
              <path
                class="st2"
                d="M48.41,28.81h-2.45c-0.68,0-1.23,0.55-1.23,1.23c0,0.68,0.55,1.23,1.23,1.23h2.45c0.68,0,1.23-0.55,1.23-1.23    C49.64,29.35,49.09,28.81,48.41,28.81L48.41,28.81z"
              />
              <path
                class="st2"
                d="M48.41,25.12h-2.45c-0.68,0-1.23,0.55-1.23,1.23c0,0.68,0.55,1.23,1.23,1.23h2.45c0.68,0,1.23-0.55,1.23-1.23    C49.64,25.67,49.09,25.12,48.41,25.12L48.41,25.12z"
              />
              <path
                class="st2"
                d="M48.41,21.44h-2.45c-0.68,0-1.23,0.55-1.23,1.23c0,0.68,0.55,1.23,1.23,1.23h2.45c0.68,0,1.23-0.55,1.23-1.23    C49.64,21.99,49.09,21.44,48.41,21.44L48.41,21.44z"
              />
              <path
                class="st2"
                d="M48.41,32.49h-2.45c-0.68,0-1.23,0.55-1.23,1.23c0,0.68,0.55,1.23,1.23,1.23h2.45c0.68,0,1.23-0.55,1.23-1.23    C49.64,33.04,49.09,32.49,48.41,32.49L48.41,32.49z"
              />
              <path
                class="st2"
                d="M48.41,36.17h-2.45c-0.68,0-1.23,0.55-1.23,1.23c0,0.68,0.55,1.23,1.23,1.23h2.45c0.68,0,1.23-0.55,1.23-1.23    C49.64,36.72,49.09,36.17,48.41,36.17L48.41,36.17z"
              />
              <path
                class="st2"
                d="M11.59,31.26h2.45c0.68,0,1.23-0.55,1.23-1.23c0-0.68-0.55-1.23-1.23-1.23h-2.45c-0.68,0-1.23,0.55-1.23,1.23    C10.36,30.71,10.91,31.26,11.59,31.26L11.59,31.26z"
              />
              <path
                class="st2"
                d="M11.59,27.58h2.45c0.68,0,1.23-0.55,1.23-1.23c0-0.68-0.55-1.23-1.23-1.23h-2.45c-0.68,0-1.23,0.55-1.23,1.23    C10.36,27.03,10.91,27.58,11.59,27.58L11.59,27.58z"
              />
              <path
                class="st2"
                d="M11.59,23.9h2.45c0.68,0,1.23-0.55,1.23-1.23c0-0.68-0.55-1.23-1.23-1.23h-2.45c-0.68,0-1.23,0.55-1.23,1.23    C10.36,23.35,10.91,23.9,11.59,23.9L11.59,23.9z"
              />
              <path
                class="st2"
                d="M11.59,34.94h2.45c0.68,0,1.23-0.55,1.23-1.23c0-0.68-0.55-1.23-1.23-1.23h-2.45c-0.68,0-1.23,0.55-1.23,1.23    C10.36,34.39,10.91,34.94,11.59,34.94L11.59,34.94z"
              />
              <path
                class="st2"
                d="M14.05,38.62c0.68,0,1.23-0.55,1.23-1.23c0-0.68-0.55-1.23-1.23-1.23h-2.45c-0.68,0-1.23,0.55-1.23,1.23    c0,0.68,0.55,1.23,1.23,1.23H14.05z"
              />
              <path class="st2" d="M14.05,38.62" />
            </g>
            <g>
              <path
                class="st2"
                d="M21.41,22.25c0,0.68,0.55,1.23,1.23,1.23c0.68,0,1.23-0.55,1.23-1.23c0-0.68-0.55-1.23-1.23-1.23    C21.96,21.02,21.41,21.57,21.41,22.25L21.41,22.25z"
              />
              <path class="st2" d="M21.41,22.25" />
              <path
                class="st2"
                d="M36.14,22.25c0,0.68,0.55,1.23,1.23,1.23c0.68,0,1.23-0.55,1.23-1.23c0-0.68-0.55-1.23-1.23-1.23    C36.69,21.02,36.14,21.57,36.14,22.25L36.14,22.25z"
              />
              <path class="st2" d="M36.14,22.25" />
              <path
                class="st2"
                d="M36.14,37.82c0,0.68,0.55,1.23,1.23,1.23c0.68,0,1.23-0.55,1.23-1.23c0-0.68-0.55-1.23-1.23-1.23    C36.69,36.59,36.14,37.14,36.14,37.82L36.14,37.82z"
              />
              <path class="st2" d="M36.14,37.82" />
              <path
                class="st2"
                d="M21.41,37.82c0,0.68,0.55,1.23,1.23,1.23c0.68,0,1.23-0.55,1.23-1.23c0-0.68-0.55-1.23-1.23-1.23    C21.96,36.59,21.41,37.14,21.41,37.82L21.41,37.82z"
              />
              <path class="st2" d="M21.41,37.82" />
            </g>
          </g>
        </svg>
        <span class="title-text">{{ dialogName == 'sensor' ? '传 感 器' : '执 行 器' }}</span>
        <div class="square"></div>
      </div>
      <div class="tab">
        <div style="height: 1rem;background-color: var(--dataDispose20);">
          <div
            style="width: 100%;height: 100%;background-color: var(--dataDispose14);"
            :style="selectIndex == 0 ? 'border-radius: 0 0 0.25rem 0;' : ''"
          ></div>
        </div>
        <div
          class="tabName"
          :class="selectValue == item.value ? 'tabActive' : ''"
          v-for="(item, index) in options"
          :key="item.value"
          @click="advSelectChange(item.value, index)"
        >
          <div
            class="tabNameBox"
            :style="
              index == selectIndex + 1
                ? 'border-radius: 0 0.25rem 0 0'
                : index == selectIndex - 1
                ? 'border-radius: 0 0 0.25rem 0'
                : ''
            "
          >
            <div class="nameImg">
              <img :src="item.imgUrl" />
            </div>
            {{ item.label }}
          </div>
        </div>
        <div style="height: 0.375rem;background-color: var(--dataDispose20);">
          <div
            style="width: 100%;height: 100%;background-color: var(--dataDispose14);"
            :style="selectIndex == options.length - 1 ? 'border-radius: 0 0.25rem 0 0;' : ''"
          ></div>
        </div>
      </div>
      <div class="tabBox">
        <div v-if="dialogName == 'sensor'" class="tabContent">
          <div>
            <div
              :class="choose(item.index) ? 'borders ' : ''"
              @click="importAdv(item)"
              v-for="(item, index) in advSensor"
              :key="index"
              id="equ_inner_itemes"
            >
              <svg
                v-show="alreadyChoose(item.index)"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 22 22"
              >
                <circle class="st0" cx="11" cy="11.05" r="11" />
                <g>
                  <polygon class="st1" points="10.56,15.24 5.9,10.57 6.96,9.51 10.39,12.94 14.97,6.72 16.18,7.6  " />
                </g>
              </svg>
              <div class="itemPictures">
                <img :src="item.picture" />
              </div>
              <div class="itemTitles" :title="item.key">
                {{ item.key }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="tabContent">
          <div>
            <div
              :class="choose(item.index) ? 'borders ' : ''"
              @click="importAdv(item)"
              v-for="(item, index) in advActuator"
              :key="index"
              id="equ_inner_itemes"
            >
              <svg
                v-show="alreadyChoose(item.index)"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 22 22"
              >
                <circle class="st0" cx="11" cy="11.05" r="11" />
                <g>
                  <polygon class="st1" points="10.56,15.24 5.9,10.57 6.96,9.51 10.39,12.94 14.97,6.72 16.18,7.6  " />
                </g>
              </svg>
              <div class="itemPictures">
                <img :src="item.picture" />
              </div>
              <div class="itemTitles" :title="item.key">
                {{ item.key }}
              </div>
            </div>
          </div>
        </div>
        <el-button type="primary" class="cancel_btn" @click="handleClose">取 消</el-button>
        <el-button type="primary" class="confirm_btn" @click="confirmed">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { harmonySensor } from '../assets/js/harmonyAdvance/sensor'
import { harmonyActuator } from '../assets/js/harmonyAdvance/actuator'
import { harmonySensorE2 } from '../assets/js/harmonyAdvance/sensor_E2'
import { harmonyActuatorE2 } from '../assets/js/harmonyAdvance/actuator_E2'
import { harmonySensorS1 } from '../assets/js/harmonyAdvance/sensor_S1'
import { harmonyActuatorS1 } from '../assets/js/harmonyAdvance/actuator_S1'

export default {
  name: 'dataDispose',
  data() {
    return {
      dialogVisible: false,
      dialogName: 'sensor',
      selectIndex: 0,
      oldSelectIndex: 0,
      selectValue: 'E1',
      oldSelectValue: '',
      options: [
        {
          value: 'E1',
          label: 'E1天启扩展板',
          imgUrl: require('../assets/img/TQ_E1.png'),
        },
        {
          value: 'E2',
          label: 'E2天启扩展板',
          imgUrl: require('../assets/img/TQ_E2.png'),
        },
        {
          value: 'S1',
          label: 'S1天启集成板',
          imgUrl: require('../assets/img/TQ_S1.png'),
        },
      ],
      advSensor: harmonySensor,
      advActuator: harmonyActuator,
      advIndex: 'x',
      advPort: 'haobeibei',
      index: [], // 待添加器件索引
      indexAdv: [], // 待添加器件
      alreadyIndex: [], // 已添加器件索引
      alreadyNamePort: [
        // {
        //   key: '光线传感器',
        //   port: ['P1', 'P2']
        // }
      ], // 已添加器件索引及端口
      sensorArr: [
        // {
        //   time: '2023-05-09 15:59:04',
        //   categoryEng: 'Sensor',
        //   clientId: 'GID--TQ@@@2-Or-7G',
        //   server: 'ws://mqtt-cn-20p35tzwv02.mqtt.aliyuncs.com:80',
        //   topic: 'group/group1/1',
        //   index: 101,
        //   key: '光线传感器',
        //   port: 'P3',
        //   qos: 0,
        //   value: '123',
        // },
      ], // 已添加传感器
      actuatorArr: [
        // {
        //   time: '2023-05-09 15:59:04',
        //   categoryEng: 'Actuator',
        //   clientId: 'GID--TQ@@@2-Or-7G',
        //   server: 'ws://mqtt-cn-20p35tzwv02.mqtt.aliyuncs.com:80',
        //   topic: 'group/group1/1',
        //   index: 101,
        //   key: 'TT马达',
        //   port: 'P3',
        //   qos: 0,
        //   value: '20',
        // },
      ], // 已添加执行器
    }
  },
  watch: {
    selectIndex(newVal, oldVal) {
      this.oldSelectIndex = oldVal
    },
    selectValue(val, oldVal) {
      this.oldSelectValue = oldVal
    },
    mqttMessage(val) {
      try {
        let receiveMessage = val.filter(item => item.type == 'receive' && item.topic == this.nowTopic)
        this.filterMqttMessage(receiveMessage)
      } catch (error) {
        error
      }
    },
    nowTopic(val) {
      this.sensorArr = []
      this.actuatorArr = []
      this.alreadyIndex = []
      this.alreadyNamePort = []
      try {
        let receiveMessage = this.mqttMessage.filter(item => item.type == 'receive' && item.topic == val)
        this.filterMqttMessage(receiveMessage)
      } catch (error) {
        error
      }
    },
  },
  computed: {
    ...mapState(['nowTopic', 'mqttMessage']),

    eleWidth: {
      get() {
        let dataDisposeDiv = document.getElementById('dataDispose')
        return dataDisposeDiv - 20
      },
      set(newValue) {
        return newValue
      },
    },
  },
  directives: {
    resize: {
      bind(el, binding) {
        let width = '',
          height = ''
        function isReize() {
          const style = document.defaultView.getComputedStyle(el)
          if (width !== style.width || height !== style.height) {
            binding.value()
          }
          width = style.width
          height = style.height
        }

        el.__vueReize__ = setInterval(isReize, 40)
      },
      unbind(el) {
        clearInterval(el.__vueReize__)
      },
    },
  },
  mounted() {},
  methods: {
    resize() {
      let dataDisposeDiv = document.getElementById('dataDispose')
      this.eleWidth = dataDisposeDiv.offsetWidth - 20
    },

    // 鼠标移入移出显示删除
    mouseHover(index, port) {
      this.advIndex = index
      this.advPort = port
    },
    mouseOut() {
      this.advIndex = 'x'
      this.advPort = 'haobeibei'
    },

    delItem(index1, index2, key, port, flag) {
      if (flag) {
        this.sensorArr.splice(index1, 1)
      } else {
        this.actuatorArr.splice(index1, 1)
      }
      this.alreadyNamePort.forEach((item, index) => {
        if (item.key == key && item.port.length == 1) {
          this.alreadyIndex.splice(this.alreadyIndex.indexOf(index2), 1)
          this.alreadyNamePort.splice(index, 1)
        } else if (item.key == key && item.port.length > 1) {
          item.port.splice(item.port.indexOf(port), 1)
        } else if (item.key == key && item.port.length == 0) {
          this.alreadyIndex.splice(this.alreadyIndex.indexOf(index2), 1)
          this.alreadyNamePort.splice(index, 1)
        }
      })
    },

    openExtendDialog(data) {
      this.dialogName = data
      this.dialogVisible = true
    },

    // 切换扩展板
    advSelectChange(value, index) {
      this.selectIndex = index
      this.selectValue = value
      if (this.alreadyIndex.length > 0) {
        this.$confirm('切换扩展板将清除已添加的器件，是否继续？', '提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '是',
          cancelButtonText: '否',
          center: true,
          customClass: 'confirm',
        })
          .then(() => {
            this.index = []
            this.indexAdv = []
            this.alreadyIndex = []
            this.sensorArr = []
            this.actuatorArr = []
            switch (value) {
              case 'E1':
                this.advSensor = harmonySensor
                this.advActuator = harmonyActuator
                break
              case 'E2':
                this.advSensor = harmonySensorE2
                this.advActuator = harmonyActuatorE2
                break
              case 'S1':
                this.advSensor = harmonySensorS1
                this.advActuator = harmonyActuatorS1
                break
              default:
                this.advSensor = []
                this.advActuator = []
                break
            }
          })
          .catch(() => {
            this.selectIndex = this.oldSelectIndex
            this.selectValue = this.oldSelectValue
          })
      } else {
        switch (value) {
          case 'E1':
            this.advSensor = harmonySensor
            this.advActuator = harmonyActuator
            break
          case 'E2':
            this.advSensor = harmonySensorE2
            this.advActuator = harmonyActuatorE2
            break
          case 'S1':
            this.advSensor = harmonySensorS1
            this.advActuator = harmonyActuatorS1
            break
          default:
            this.advSensor = []
            this.advActuator = []
            break
        }
      }
    },

    // 点击选中待添加的器件
    importAdv(data) {
      // 此处理能阻止后续修改影响原数据
      let arr = JSON.parse(JSON.stringify(data))
      if (this.index.indexOf(arr.index) == -1) {
        if (!this.alreadyChoose(arr.index)) {
          this.index.push(arr.index)
          this.indexAdv.push(arr)
        } else {
          this.$notify({
            type: 'warning',
            title: '警告',
            message: '该扩展已添加',
          })
        }
      } else {
        this.index.splice(this.index.indexOf(arr.index), 1)
        for (let i = 0; i < this.indexAdv.length; i++) {
          if (arr.index == this.indexAdv[i].index) {
            this.indexAdv.splice(i, 1)
          }
        }
      }
    },

    // 选中器件添加边框
    choose(item) {
      if (this.index.indexOf(item) == -1) {
        return false
      } else {
        return true
      }
    },

    // 选中器件是否已经添加
    alreadyChoose(item) {
      if (this.alreadyIndex.indexOf(item) == -1) {
        return false
      } else {
        return true
      }
    },

    // 触发关闭事件
    handleClose() {
      this.index = []
      this.indexAdv = []
      this.dialogVisible = false
    },

    // 确认添加器件
    confirmed() {
      // 判断有无选中器件
      if (this.indexAdv.length > 0) {
        this.indexAdv.forEach(item => {
          this.alreadyIndex.push(item.index)
          this.alreadyNamePort.push({
            key: item.key,
            port: [],
          })
          if (item.categoryEng == 'Sensor') {
            this.sensorArr.push(item)
          } else {
            this.actuatorArr.push(item)
          }
        })
        this.handleClose()
      } else {
        this.$notify({
          type: 'warning',
          title: '警告',
          message: '请选择要添加的设备',
        })
      }
    },

    // 根据订阅主题过滤接收到的mqtt信息
    filterMqttMessage(message) {
      // console.log(message)
      message.forEach(item => {
        let itemMsg = JSON.parse(JSON.stringify(item))
        delete itemMsg.type
        itemMsg.port = itemMsg.port.toUpperCase()
        this.advSensor.forEach(itemAdv => {
          if (itemAdv.key != itemMsg.key) {
            return
          }
          if (this.alreadyIndex.indexOf(itemAdv.index) == -1) {
            this.alreadyIndex.push(itemAdv.index)
            this.alreadyNamePort.push({
              key: itemMsg.key,
              port: [itemMsg.port],
            })
            itemMsg.index = itemAdv.index
            itemMsg.categoryEng = itemAdv.categoryEng
            this.sensorArr.push(itemMsg)
          } else {
            this.alreadyNamePort.forEach(itemAlr => {
              if (itemAlr.key == itemMsg.key && itemAlr.port.indexOf(itemMsg.port) == -1) {
                itemMsg.index = itemAdv.index
                itemMsg.categoryEng = itemAdv.categoryEng
                if (itemAlr.port.length == 0) {
                  itemAlr.port = [itemMsg.port]
                  this.sensorArr.forEach(itemSen => {
                    if (itemSen.key == itemMsg.key) {
                      itemSen.value = itemMsg.value
                      itemSen.port = itemMsg.port
                    }
                  })
                } else {
                  itemAlr.port.push(itemMsg.port)
                  this.sensorArr.push(itemMsg)
                }
              } else if (itemAlr.key == itemMsg.key && itemAlr.port.indexOf(itemMsg.port) != -1) {
                this.sensorArr.forEach(itemSen => {
                  if (itemSen.key == itemMsg.key && itemSen.port == itemMsg.port) {
                    itemSen.value = itemMsg.value
                  }
                })
              }
            })
          }
        })
        this.advActuator.forEach(itemAdv => {
          if (itemAdv.key != itemMsg.key) {
            return
          }
          if (this.alreadyIndex.indexOf(itemAdv.index) == -1) {
            this.alreadyIndex.push(itemAdv.index)
            this.alreadyNamePort.push({
              key: itemMsg.key,
              port: [itemMsg.port],
            })
            itemMsg.index = itemAdv.index
            itemMsg.categoryEng = itemAdv.categoryEng
            this.actuatorArr.push(itemMsg)
          } else {
            this.alreadyNamePort.forEach(itemAlr => {
              if (itemAlr.key == itemMsg.key && itemAlr.port.indexOf(itemMsg.port) == -1) {
                itemMsg.index = itemAdv.index
                itemMsg.categoryEng = itemAdv.categoryEng
                if (itemAlr.port.length == 0) {
                  itemAlr.port = [itemMsg.port]
                  this.actuatorArr.forEach(itemAct => {
                    if (itemAct.key == itemMsg.key) {
                      itemAct.value = itemMsg.value
                      itemAct.port = itemMsg.port
                    }
                  })
                } else {
                  itemAlr.port.push(itemMsg.port)
                  this.actuatorArr.push(itemMsg)
                }
              } else if (itemAlr.key == itemMsg.key && itemAlr.port.indexOf(itemMsg.port) != -1) {
                this.actuatorArr.forEach(itemAct => {
                  if (itemAct.key == itemMsg.key && itemAct.port == itemMsg.port) {
                    itemAct.value = itemMsg.value
                  }
                })
              }
            })
          }
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
#dataDispose {
  height: calc(100% - 0.625rem);
  width: 100%;
  padding: 0 0.125rem 0.125rem 0;
  margin-top: 0.625rem;
  overflow: auto;
  .main {
    height: 100%;
    width: 100%;
    border-radius: 0 0.0625rem 0.0625rem 0.0625rem;
    background-color: var(--dataDispose0);
    box-shadow: 0.0625rem 0.0625rem 0.0625rem rgba(0, 0, 0, 0.25);
    .sensor,
    .actuator {
      position: relative;
      height: 50%;
      .title {
        display: flex;
        justify-content: flex-start;
        padding: 0.5rem 0.5rem 0.3125rem;
        text-align: left;
        font-size: 0.25rem;
        color: var(--dataDispose1);
        cursor: pointer;
        svg {
          width: 0.375rem;
          height: 0.375rem;
          .st0 {
            fill: var(--dataDispose1);
          }
        }
        div {
          height: 0.375rem;
          line-height: 0.375rem;
          margin: 0 0.125rem;
        }
      }
      .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-left: 0.9375rem;
        margin-right: 0.8625rem;
        overflow: auto;
        height: calc(100% - 1.6875rem);
        font-size: 0.225rem;
        .sensorItem,
        .actuatorItem {
          position: relative;
          width: 1.25rem;
          height: 1.25rem;
          background-color: var(--dataDispose2);
          border-radius: 0.0625rem;
          margin: 0.1875rem;
          padding: 0.25rem 0.125rem;
          .el-button::v-deep {
            position: absolute;
            top: -0.125rem;
            right: -0.125rem;
            width: 0.25rem;
            height: 0.25rem;
            font-size: 0.25rem;
            color: var(--dataDispose3);
            border: none;
            padding: 0;
            .el-icon-remove {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .value {
            height: 0.3rem;
            line-height: 0.3rem;
            font-size: 0.3rem;
            color: var(--dataDispose4);
          }
          .name {
            height: 0.15rem;
            line-height: 0.15rem;
            font-size: 0.15rem;
            color: var(--dataDispose5);
            margin-top: 0.1rem;
          }
          .key {
            height: 0.15rem;
            line-height: 0.15rem;
            font-size: 0.15rem;
            color: var(--dataDispose5);
            margin-top: 0.0625rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .line {
      height: 1px;
      margin: 0 0.5rem;
      border-radius: 1px;
      background: var(--dataDispose6);
    }
  }
  .el-dialog__wrapper::v-deep {
    font-size: 0.25rem;
    .el-dialog.addExtendNew {
      top: 50%;
      transform: translateY(-50%);
      width: 15rem;
      margin-top: 0 !important;
      border-radius: 0.375rem;
      overflow: hidden;
      background-color: var(--dataDispose7);
      .el-dialog__header {
        padding: 0;
        height: 1.375rem;
        line-height: 1.375rem;
        .dialog-title {
          position: relative;
          display: flex;
          justify-content: center;
          font-size: 0.25rem;
          color: var(--dataDispose8);
          height: 100%;
          svg {
            height: 0.75rem;
            width: 0.75rem;
            margin-top: 0.3125rem;
            margin-right: 0.25rem;
            .st0 {
              fill: var(--dataDispose9);
            }
            .st1 {
              fill: var(--dataDispose10);
            }
            .st2 {
              fill: var(--dataDispose11);
            }
          }
          .square {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 50%) rotate(45deg);
            height: 0.25rem;
            width: 0.25rem;
            background-color: var(--dataDispose12);
          }
        }
        .el-dialog__headerbtn {
          font-size: 0.25rem;
          top: 0.25rem;
          right: 0.25rem;
          .el-dialog__close {
            color: var(--dataDispose13);
          }
        }
        .el-dialog__headerbtn:focus .el-dialog__close,
        .el-dialog__headerbtn:hover .el-dialog__close {
          color: var(--dataDispose13);
        }
      }
      .el-dialog__body {
        display: flex;
        padding: 0.5rem 0.625rem 0.375rem;
        height: 8rem;
        border-radius: 0.375rem;
        background-color: var(--dataDispose14);
        .tab {
          width: 2.5rem;
          .tabName {
            height: 1rem;
            background-color: var(--dataDispose15);
            .tabNameBox {
              padding: 0.125rem;
              height: 100%;
              width: 100%;
              line-height: 0.75rem;
              font-size: 0.2rem;
              background-color: var(--dataDispose16);
              .nameImg {
                float: left;
                height: 100%;
                width: 0.75rem;
                border-radius: 0.125rem;
                border: 2px solid var(--dataDispose17);
                background-color: var(--dataDispose18);
                > img {
                  width: 100%;
                }
              }
            }
            &.tabActive {
              background-color: var(--dataDispose19);
              .tabNameBox {
                border-radius: 0.25rem 0 0 0.25rem;
                background-color: var(--dataDispose20);
              }
            }
          }
        }
        .tabBox {
          position: relative;
          height: 100%;
          width: 11.25rem;
          .tabContent {
            height: 6.125rem;
            width: 100%;
            padding: 0.375rem 0.025rem 0.375rem 0.375rem;
            border-radius: 0.375rem;
            background-color: var(--dataDispose21);
            > div {
              height: 100%;
              overflow: auto;
              &::-webkit-scrollbar-thumb {
                background-color: var(--dataDispose22);
              }
            }
            #equ_inner_itemes {
              float: left;
              margin: 0 0.25rem 0.25rem 0;
              width: 1.875rem;
              height: 2.25rem;
              border-radius: 0.0625rem;
              position: relative;
              background-color: var(--dataDispose22);
              &:hover {
                cursor: pointer;
              }
              > svg {
                position: absolute;
                top: 0.125rem;
                right: 0.125rem;
                height: 0.25rem;
                .st0 {
                  fill: var(--dataDispose23);
                }
                .st1 {
                  fill: var(--dataDispose24);
                }
              }
              .itemPictures {
                overflow: hidden;
                height: 1.875rem;
                width: 100%;
                text-align: center;
                img {
                  width: 100%;
                }
              }
              .itemTitles {
                width: 100%;
                height: 0.375rem;
                font-size: 0.2rem;
                color: var(--dataDispose25);
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                border-radius: 0 0 0.1rem 0.1rem;
                padding: 0 0.0625rem;
              }
            }
            #equ_inner_itemes.borders {
              border: 1px solid var(--dataDispose26);
            }
          }
          .cancel_btn,
          .confirm_btn {
            position: absolute;
            box-sizing: border-box;
            bottom: 0;
            margin-bottom: 0;
            width: 1.5rem;
            height: 0.5rem;
            line-height: 0.5rem;
            border-radius: 0.0625rem;
            padding: 0;
            text-align: center;
            font-size: 0.2rem;
            font-weight: 500;
            word-spacing: 0.125rem;
            cursor: pointer;
            color: var(--dataDispose27);
            background-color: var(--dataDispose28);
            border: none;
          }
          .cancel_btn {
            right: 1.8375rem;
            color: var(--dataDispose29);
            background-color: var(--dataDispose30);
          }
          .confirm_btn {
            right: 0;
          }
        }
      }
    }
  }
}
</style>
