<template>
  <div id="dataQuery">
    <div class="queryContent">
      <el-form :inline="true" :model="queryCondition">
        <el-form-item label="Time">
          <el-date-picker
            v-model="queryCondition.time"
            type="datetimerange"
            :picker-options="pickerOptions"
            start-placeholder="开始日期"
            range-separator="至"
            end-placeholder="结束日期"
            value-format="yyyy-MM-ddTHH:mm:ss+08:00"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item class="fromItem" label="Host">
          <el-input v-model="queryCondition.server"></el-input>
        </el-form-item>
        <el-form-item class="fromItem" label="Topic">
          <el-input v-model="queryCondition.topic"></el-input>
        </el-form-item>
        <el-form-item class="fromItem" label="Client ID">
          <el-input v-model="queryCondition.client_id"></el-input>
        </el-form-item>
        <el-form-item class="fromItem" label="Port">
          <el-input v-model="queryCondition.port"></el-input>
        </el-form-item>
        <el-form-item class="fromItem" label="Key">
          <el-input v-model="queryCondition.key"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query">查 询</el-button>
          <el-button type="primary" @click="download">下 载</el-button>
        </el-form-item>
      </el-form>
      <div class="queryTableBox" style="height: calc(100% - 1rem)">
        <el-table ref="table" :data="tableData" border stripe height="calc(100% - 0.4375rem)" style="width: 100%;">
          <el-table-column type="selection" align="center" width="40"></el-table-column>
          <el-table-column type="index" label="No" align="center" width="50"> </el-table-column>
          <el-table-column prop="time" label="Time" align="center">
            <template slot-scope="scope">
              {{ scope.row.time.replaceAll('T', ' ').substring(0, 19) }}
            </template>
          </el-table-column>
          <el-table-column prop="server" label="Host" align="center"></el-table-column>
          <el-table-column prop="topic" label="Topic" align="center"></el-table-column>
          <el-table-column prop="client_id" label="Client ID" align="center"></el-table-column>
          <el-table-column prop="port" label="Port" align="center"></el-table-column>
          <el-table-column prop="key" label="Key" align="center"></el-table-column>
          <el-table-column prop="value" label="Value" align="center"></el-table-column>
        </el-table>
        <el-pagination
          v-show="tableData.length > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[50, 100, 300, 500]"
          :page-size="50"
          popper-class="queryPage"
          layout="sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dataQuery',
  data() {
    return {
      queryCondition: {
        time: [],
        server: '',
        topic: '',
        client_id: '',
        port: '',
        key: '',
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      tableData: [
        // {
        //   id: '62',
        //   server: 'ws://mqtt-cn-20p35tzwv02.mqtt.aliyuncs.com:80',
        //   time: '2023-05-05T10:43:05+08:00',
        //   topic: 'group/group1/1',
        //   client_id: 'GID_TIANQI@@@1-4934640704446587267',
        //   port: 'P1',
        //   key: '光线传感器',
        //   value: '999',
        // },
      ],
      currentPage: 1,
      total: 200,
      offset: 0,
      limit: 50,
    }
  },
  mounted() {},
  methods: {
    getNowDate() {
      let myDate = new Date()
      return (
        myDate.getFullYear() +
        '' +
        [myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1] +
        '' +
        [myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()] +
        [myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours()] +
        '' +
        [myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes()] +
        '' +
        [myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds()]
      )
    },

    handleSizeChange(val) {
      this.limit = val
    },
    handleCurrentChange(val) {
      this.offset = (val - 1) * this.limit
      this.query()
    },

    // 处理查询/下载接口入参
    getParams() {
      let params = {
        offset: this.offset,
        limit: this.limit,
      }
      // 示例
      // params = {
      //   start_time: this.queryCondition.time[0],
      //   end_time: this.queryCondition.time[1],
      //   server: 'ws://mqtt-cn-20p35tzwv02.mqtt.aliyuncs.com:80',
      //   topic: this.queryCondition.topic,
      //   client_id: this.queryCondition.clientID,
      //   port: this.queryCondition.port,
      //   key: this.queryCondition.key,
      //   value: '1023',
      //   offset: 0,
      //   limit: 20,
      // }
      try {
        if (this.queryCondition.time.length == 2) {
          params['start_time'] = this.queryCondition.time[0]
          params['end_time'] = this.queryCondition.time[1]
          let obj = Object.keys(this.queryCondition)
          obj.forEach(item => {
            if (isEmptyStr(this.queryCondition[item]) && item != 'time') {
              params[item] = this.queryCondition[item]
            }
          })
          return params
        }
      } catch (error) {
        error
      }
      function isEmptyStr(str) {
        if (typeof str == 'string' && str.trim().length > 0) {
          return true
        }
        return false
      }
    },

    // 查询数据
    query() {
      if (this.getParams()) {
        this.$get('/mqtt/user_record/list', this.getParams())
          .then(res => {
            if (res.code == 200) {
              this.tableData = res.data.records
              this.total = res.data.total
            } else {
              this.$notify({
                type: 'warning',
                title: '警告',
                message: '请输入正确查询条件',
              })
            }
          })
          .catch(err => {
            this.$notify.error({
              title: '报错',
              message: err,
            })
          })
      } else {
        this.$notify({
          type: 'warning',
          title: '警告',
          message: '未选择时间',
        })
      }
    },

    exportData() {
      let table = JSON.parse(JSON.stringify(this.$refs.table.selection))
      table.forEach(item => {
        item.time = item.time
          .replaceAll('T', ' ')
          .replaceAll('-', '/')
          .substring(0, 19)
      })
      require.ensure([], () => {
        const { export_json_to_excel } = require('../assets/js/excel/Export2Excel')
        export_json_to_excel(
          ['Server', 'Time', 'Topic', 'ClientID', 'Port', 'Key', 'Value'], // 表头
          this.formatJson(
            ['server', 'time', 'topic', 'client_id', 'port', 'key', 'value'], // 对应表头的key
            table, // 选中数据
          ),
          '天启云平台历史数据-' + this.getNowDate(), // 导出的Excel名称
        )
      })
    },

    download() {
      if (this.$refs.table.selection.length > 0) {
        // 前端导出数据
        this.exportData()
      } else {
        // 调用后台接口下载
        if (this.getParams()) {
          this.$http
            .request({
              url: 'https://ai.stemedu.cn/dfck/blocklyCMS/iotcp/mqtt/user_record/download',
              responseType: 'blob',
              method: 'get',
              params: this.getParams(),
            })
            .then(res => {
              this.$refs.table.toggleAllSelection()
              let blob = new Blob([res.data]) //response.data为后端传的流文件
              let downloadFilename = '天启云平台历史数据-' + this.getNowDate() + '.xlsx'
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                //兼容ie浏览器
                window.navigator.msSaveOrOpenBlob(blob, downloadFilename)
              } else {
                //谷歌,火狐等浏览器
                let url = window.URL.createObjectURL(blob)
                let downloadElement = document.createElement('a')
                downloadElement.style.display = 'none'
                downloadElement.href = url
                downloadElement.download = downloadFilename
                document.body.appendChild(downloadElement)
                downloadElement.click()
                document.body.removeChild(downloadElement)
                window.URL.revokeObjectURL(url)
              }
            })
            .catch(function(err) {
              this.$notify.error({
                title: '报错',
                message: err,
              })
            })
        } else {
          this.$notify({
            type: 'warning',
            title: '警告',
            message: '未选择时间',
          })
        }
      }
    },

    formatJson(filterVal, Data) {
      return Data.map(v => filterVal.map(j => v[j]))
    },
  },
}
</script>
<style lang="scss" scoped>
#dataQuery {
  height: calc(100% - 0.625rem);
  width: 100%;
  padding: 0 0.125rem 0.125rem 0;
  margin-top: 0.625rem;
  user-select: text;
  > div {
    height: 100%;
    width: 100%;
    background-color: var(--dataQuery0);
    border-radius: 0 0.0625rem 0.0625rem 0.0625rem;
    .el-form {
      position: relative;
      font-size: 0;
      height: 1rem;
      background-color: var(--dataQuery0);
      border-radius: 0 0.1rem 0 0;
      border-bottom: 1px solid var(--dataQuery1);
      text-align: left;
      padding-right: 3rem;
      .el-form-item::v-deep {
        padding: 0.125rem 0;
        margin: 0;
        &:nth-child(1),
        &:nth-child(2) {
          width: 50%;
          padding-bottom: 0;
        }
        &:nth-child(3),
        &:nth-child(4) {
          width: 25%;
        }
        &:nth-child(4) {
          .el-form-item__label {
            width: 1.25rem;
          }
          .el-form-item__content {
            width: calc(100% - 1.25rem);
          }
        }
        &:nth-child(5) {
          width: 20%;
        }
        &:nth-child(6) {
          width: 30%;
        }
        &:nth-child(7) {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 3rem;
          .el-form-item__content {
            text-align: center;
            width: 100%;
          }
        }
        .el-form-item__label {
          width: 1rem;
          font-size: 0.175rem;
          color: var(--dataQuery2);
          line-height: 0.25rem;
          padding: 0;
          text-align: center;
        }
        .el-form-item__content {
          line-height: 0.5rem;
          width: calc(100% - 1rem);
          &:last-child {
            line-height: 0;
            vertical-align: middle;
          }
        }
        .el-range-editor .el-range-input,
        .el-input__inner {
          height: 0.3125rem;
          line-height: 0.3125rem;
          font-size: 0.175rem;
          border: none;
          color: var(--dataQuery3);
          background-color: var(--dataQuery4);
        }
        .el-range-editor .el-range-input {
          width: 39%;
        }
        .el-date-editor .el-range__icon,
        .el-date-editor .el-range-separator {
          font-size: 0.175rem;
          line-height: 0.225rem;
        }
        .el-date-editor .el-range-separator {
          width: 8%;
          color: var(--dataQuery5);
        }
        .el-date-editor--datetimerange.el-input__inner {
          width: 100%;
        }
        .el-date-editor .el-range__close-icon {
          font-size: 0.175rem;
          line-height: 0.225rem;
        }
        .el-button {
          width: 0.9375rem;
          height: 0.75rem;
          padding: 0;
          background-color: var(--dataQuery6);
          border: none;
          font-size: 0.2rem;
          border-radius: 0.0625rem;
        }
        .el-button + .el-button {
          margin-left: 0.125rem;
        }
      }
    }
    .queryTableBox {
      .el-pagination::v-deep {
        padding: 0.0625rem 0;
        font-size: 0.2rem;
        height: 0.4375rem;
        line-height: 0.4375rem;
        border-radius: 0 0 0.0625rem 0.0625rem;
        .el-pagination__sizes {
          font-size: 0.1625rem;
          min-width: 0.4375rem;
          height: 0.3125rem;
          line-height: 0.3125rem;
          // margin-right: 0.125rem;
          .el-input {
            font-size: 0.15rem;
            width: 1.25rem;
            margin: 0 0.0625rem;
            .el-input__inner {
              padding-left: 0.1rem;
              padding-right: 0.3125rem;
              border-radius: 0.0625rem;
              font-size: 0.1625rem;
              height: 0.3125rem;
              line-height: 0.3125rem;
              color: var(--dataQuery7);
              background-color: transparent;
              border-color: var(--dataQuery8);
            }
            .el-select__caret {
              color: var(--dataQuery9);
              font-size: 0.175rem;
              &::before {
                content: '\e78f';
              }
            }
            .el-input__icon {
              width: 0.3125rem;
              line-height: 0.35rem;
            }
          }
          .el-input.is-focus .el-input__inner {
            border-color: var(--dataQuery10);
          }
        }
        > button {
          padding: 0 0.075rem;
          font-size: 0.15rem;
          background-size: 0.2rem;
          min-width: 0.4375rem;
          height: 0.3125rem;
          line-height: 0.3125rem;
          color: var(--dataQuery11);
          background-color: transparent;
          &:hover {
            color: var(--dataQuery12);
          }
          .el-icon {
            font-size: 0.15rem;
          }
        }
        .btn-prev {
          padding-right: 0.15rem;
        }
        .btn-next {
          padding-left: 0.15rem;
        }
        .el-pager {
          line-height: 0.3125rem;
          li {
            padding: 0 0.05rem;
            font-size: 0.1625rem;
            min-width: 0.4375rem;
            height: 0.3125rem;
            line-height: 0.3125rem;
            color: var(--dataQuery13);
            background-color: transparent;
            &:hover {
              color: var(--dataQuery14);
            }
          }
          .btn-quicknext,
          .btn-quickprev {
            line-height: 0.35rem;
          }
          .more::before {
            line-height: 0.375rem;
          }
          .active {
            color: var(--dataQuery15);
            cursor: default;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.queryTableBox,
.logTableBox {
  .el-table.el-table--border {
    overflow: auto;
    font-size: 0.175rem;
    border-color: var(--dataQuery16);
    th.el-table__cell.is-leaf {
      border-color: var(--dataQuery17);
      &:first-child {
        border-left: 1px solid var(--dataQuery17);
      }
    }
    &::before {
      height: 0;
    }
    &.el-table--border::after {
      background-color: var(--dataQuery17);
    }
    .cell {
      text-align: center;
      line-height: 0.2875rem;
      padding: 0 0.0625rem;
    }
    th.gutter {
      width: 0.075rem;
    }
    colgroup col[name='gutter'] {
      width: 0.075rem;
    }
    th.el-table__cell.gutter:last-of-type {
      border-bottom: 1px solid var(--dataQuery17);
    }
    .el-checkbox {
      font-size: 0.175rem;
    }
    .el-checkbox__inner {
      font-size: 0.2rem;
      width: 0.175rem;
      height: 0.175rem;
      border: 1px solid var(--dataQuery18);
      background-color: var(--dataQuery19);
    }
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      border-color: var(--dataQuery20);
      background-color: var(--dataQuery20);
    }
    .el-checkbox__inner:hover,
    .el-checkbox__input:focus,
    .el-checkbox__input.is-focus .el-checkbox__inner {
      border-color: var(--dataQuery21);
    }
    .el-checkbox__inner::after {
      height: 0.0875rem;
      width: 0.0375rem;
      top: 0.0125rem;
      left: 0.05rem;
      border: 0.0125rem solid var(--dataQuery22);
      border-left: 0;
      border-top: 0;
    }
    .el-table__body {
      width: 100% !important;
    }
    th.el-table__cell {
      padding: 0.0375rem 0;
      color: var(--dataQuery23);
      font-weight: normal;
      background-color: var(--dataQuery24);
    }
    .el-table__cell {
      border-right: 1px solid var(--dataQuery25);
    }
    td.el-table__cell {
      padding: 0.0375rem 0;
      color: var(--dataQuery26);
      background-color: var(--dataQuery27);
      border-bottom: 1px solid var(--dataQuery28);
    }
    .el-table__body tr.el-table__row--striped td.el-table__cell {
      background-color: var(--dataQuery29);
    }
    .el-table__body tr:hover > td.el-table__cell {
      background-color: var(--dataQuery30);
    }
    .el-table__body-wrapper {
      background-color: var(--dataQuery31);
    }
  }
}
.el-picker-panel.el-date-range-picker {
  width: 9.45rem;
  border: none;
  border-radius: 0.0625rem;
  line-height: 0.375rem;
  color: var(--dataQuery32);
  background-color: var(--dataQuery33);
  .popper__arrow {
    border-bottom-color: var(--dataQuery33);
    &::after {
      border-bottom-color: var(--dataQuery33);
    }
  }
  .el-picker-panel__body-wrapper {
    .el-picker-panel__sidebar {
      width: 1.25rem;
      border-color: var(--dataQuery34);
      background-color: var(--dataQuery35);
      padding-top: 0.075rem;
      border-radius: 0.0625rem 0 0 0;
      .el-picker-panel__shortcut {
        font-size: 0.175rem;
        line-height: 0.35rem;
        padding-left: 0.15rem;
        color: var(--dataQuery36);
        &:hover {
          color: var(--dataQuery37);
        }
      }
    }
    .el-picker-panel__sidebar + .el-picker-panel__body {
      margin-left: 1.375rem;
    }
    .el-picker-panel__body {
      min-width: 6.4125rem;
      .el-date-range-picker__time-header {
        font-size: 0.15rem;
        padding: 0.1rem 0.0625rem 0.0625rem;
        border-color: var(--dataQuery38);
        .el-date-range-picker__time-picker-wrap {
          .el-input__inner {
            color: var(--dataQuery39);
            background-color: var(--dataQuery40);
            border-radius: 0.05rem;
            border-color: var(--dataQuery40);
            height: 0.4rem;
            line-height: 0.4rem;
            padding: 0 0.1875rem;
            &.el-input.is-active,
            &:focus {
              border-color: var(--dataQuery41);
            }
          }
          .el-time-panel {
            width: 2.25rem;
            margin: 0.0625rem 0;
            border-color: var(--dataQuery42);
            background-color: var(--dataQuery43);
            .el-time-panel__content {
              &::after,
              &::before {
                margin-top: -0.125rem;
                height: 0.4rem;
                line-height: 0.4rem;
                padding-top: 0.075rem;
                border-color: var(--dataQuery44);
              }
              .el-scrollbar__wrap {
                margin-bottom: -0.2125rem !important;
                margin-right: -0.2125rem !important;
                &:not(.el-scrollbar__wrap--hidden-default) {
                  padding-bottom: 0.1875rem;
                }
                .el-time-spinner__item {
                  height: 0.4rem;
                  line-height: 0.4rem;
                  font-size: 0.15rem;
                  color: var(--dataQuery45);
                  &.active {
                    color: var(--dataQuery46);
                  }
                  &:hover:not(.disabled):not(.active) {
                    background-color: var(--dataQuery47);
                  }
                }
              }
            }
            .el-time-panel__footer {
              padding: 0.05rem;
              height: 0.45rem;
              line-height: 0.3125rem;
              border-color: var(--dataQuery48);
              .el-time-panel__btn {
                line-height: 0.35rem;
                padding: 0 0.0625rem;
                margin: 0 0.0625rem;
                font-size: 0.15rem;
                color: var(--dataQuery49);
                &.confirm {
                  color: var(--dataQuery50);
                }
              }
            }
          }
        }
        .is-vertical {
          width: 0.075rem !important;
          top: 0.025rem !important;
        }
        .is-vertical {
          width: 0.075rem !important;
          top: 0.025rem !important;
        }
        .el-icon-arrow-right {
          color: var(--dataQuery51);
        }
      }
      .el-date-range-picker__content {
        padding: 0.2rem;
        &.is-left {
          border-color: var(--dataQuery52);
        }
        .el-picker-panel__icon-btn {
          font-size: 0.15rem;
          margin-top: 0.1rem;
          color: var(--dataQuery53);
          &:hover {
            color: var(--dataQuery54);
          }
        }
        .el-date-range-picker__header {
          height: 0.35rem;
          div {
            margin: 0 0.625rem;
            font-size: 0.2rem;
          }
        }
        .el-date-table {
          font-size: 0.15rem;
          th {
            padding: 0.0625rem;
            border-bottom: 1px solid var(--dataQuery55);
            color: var(--dataQuery56);
          }
          td {
            width: 0.4rem;
            height: 0.375rem;
            padding: 0.05rem 0;
            div {
              height: 0.375rem;
              padding: 0.0375rem 0;
            }
            span {
              width: 0.3rem;
              height: 0.3rem;
              line-height: 0.3rem;
            }
            &.available:hover {
              color: var(--dataQuery57);
            }
            &.prev-month,
            &.next-month {
              color: var(--dataQuery58);
            }
            &.today {
              span {
                color: var(--dataQuery59);
              }
            }
            &.start-date,
            &.end-date {
              span {
                color: var(--dataQuery60);
                background-color: var(--dataQuery61);
              }
            }
            &.in-range {
              div {
                background-color: var(--dataQuery62);
              }
            }
          }
        }
      }
    }
  }
  .el-picker-panel__footer {
    padding: 0.05rem;
    border-color: var(--dataQuery63);
    background-color: var(--dataQuery64);
    .el-button {
      font-size: 0.15rem;
      border-radius: 0.0375rem;
      padding: 0.0875rem 0.1875rem;
      &.el-button--text {
        color: var(--dataQuery65);
      }
    }
    .el-button.is-plain {
      margin-left: 0.125rem;
      color: var(--dataQuery66);
      border-color: var(--dataQuery67);
      background-color: var(--dataQuery68);
      &:focus,
      &:hover {
        color: var(--dataQuery69);
        border-color: var(--dataQuery70);
        background-color: var(--dataQuery71);
      }
    }
  }
}
.queryPage {
  min-width: 1.375rem !important;
  margin: 0.0625rem 0;
  box-shadow: 0 0.025rem 0.15rem 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 0.15rem;
  border: none;
  background-color: var(--dataQuery72);
  &.el-popper {
    .popper__arrow {
      border-top-color: var(--dataQuery72);
      &::after {
        border-top-color: var(--dataQuery72);
      }
    }
  }
  .el-scrollbar__wrap {
    .el-select-dropdown__list {
      padding: 0;
      .el-select-dropdown__item {
        width: auto;
        font-size: 0.175rem;
        padding: 0.125rem;
        text-align: center;
        height: auto;
        line-height: 1;
        color: var(--dataQuery73);
        &.hover {
          color: var(--dataQuery74);
          background-color: var(--dataQuery75);
        }
      }
      .el-select-dropdown__item.selected {
        color: var(--dataQuery76);
      }
    }
  }
  .is-horizontal {
    height: 0.075rem !important;
    left: 0.025rem !important;
  }
  .is-vertical {
    width: 0.075rem !important;
    top: 0.025rem !important;
  }
}
</style>
