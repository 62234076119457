export const harmonyActuatorS1 = [
  {
    index: 200,
    categoryEng: 'Actuator',
    key: 'LED',
    picture: require('../../img/harmaony/actuator/LED.png'),
    port: '',
    value: 0,
  },
  {
    index: 201,
    categoryEng: 'Actuator',
    key: '全彩灯',
    picture: require('../../img/harmaony/actuator/multicolorLight.png'),
    port: '',
    value: 0,
  },
  {
    index: 202,
    categoryEng: 'Actuator',
    key: '蜂鸣器',
    picture: require('../../img/harmaony/actuator/buzzer.png'),
    port: '',
    value: 0,
  },
]
