export const harmonySensorE2 = [
  {
    index: 100,
    categoryEng: 'Sensor',
    key: '磁敏传感器',
    picture: require('../../img/harmaony/sensor/magnet_switch.png'),
    port: '',
    value: 0,
  },
  {
    index: 101,
    categoryEng: 'Sensor',
    key: '超声波传感器',
    picture: require('../../img/harmaony/sensor/ultrasonic.png'),
    port: '',
    value: 0,
  },
  {
    index: 102,
    categoryEng: 'Sensor',
    key: '数字火焰传感器',
    picture: require('../../img/harmaony/sensor/digitalFlame.png'),
    port: '',
    value: 0,
  },
  {
    index: 103,
    categoryEng: 'Sensor',
    key: '火焰传感器',
    picture: require('../../img/harmaony/sensor/flame.png'),
    port: '',
    value: 0,
  },
  {
    index: 104,
    categoryEng: 'Sensor',
    key: '人体红外传感器',
    picture: require('../../img/harmaony/sensor/human_infrared.png'),
    port: '',
    value: 0,
  },
  {
    index: 105,
    categoryEng: 'Sensor',
    key: '寻迹传感器',
    picture: require('../../img/harmaony/sensor/wayGrayscale.png'),
    port: '',
    value: 0,
  },
  {
    index: 106,
    categoryEng: 'Sensor',
    key: '双路寻迹传感器',
    picture: require('../../img/harmaony/sensor/twoway_grayscale.png'),
    port: '',
    value: 0,
  },
  {
    index: 107,
    categoryEng: 'Sensor',
    key: '数字水滴传感器',
    picture: require('../../img/harmaony/sensor/digitalWaterDrop.png'),
    port: '',
    value: 0,
  },
  {
    index: 108,
    categoryEng: 'Sensor',
    key: '水滴传感器',
    picture: require('../../img/harmaony/sensor/waterDrop.png'),
    port: '',
    value: 0,
  },
  {
    index: 109,
    categoryEng: 'Sensor',
    key: '温湿度传感器',
    picture: require('../../img/harmaony/sensor/temperature_humidity.png'),
    port: '',
    value: 0,
  },
  {
    index: 110,
    categoryEng: 'Sensor',
    key: '颜色传感器',
    picture: require('../../img/harmaony/sensor/color.png'),
    port: '',
    value: 0,
  },
  {
    index: 111,
    categoryEng: 'Sensor',
    key: '数字烟雾传感器',
    picture: require('../../img/harmaony/sensor/digitalSmoke.png'),
    port: '',
    value: 0,
  },
  {
    index: 112,
    categoryEng: 'Sensor',
    key: '烟雾传感器',
    picture: require('../../img/harmaony/sensor/smoke.png'),
    port: '',
    value: 0,
  },
  {
    index: 413,
    categoryEng: 'Sensor',
    key: '2.4g 无线遥控器',
    picture: require('../../img/harmaony/sensor/remoteControl.png'),
    port: '',
    value: 0,
  },
  {
    index: 114,
    categoryEng: 'Sensor',
    key: '光线传感器',
    picture: require('../../img/harmaony/sensor/optical_line.png'),
    port: '',
    value: 0,
  },
  {
    index: 115,
    categoryEng: 'Sensor',
    key: '触摸传感器',
    picture: require('../../img/harmaony/sensor/touchsensor.png'),
    port: '',
    value: 0,
  },
  {
    index: 116,
    categoryEng: 'Sensor',
    key: '土壤湿度传感器',
    picture: require('../../img/harmaony/sensor/soil.png'),
    port: '',
    value: 0,
  },
  {
    index: 117,
    categoryEng: 'Sensor',
    key: '水位传感器',
    picture: require('../../img/harmaony/sensor/waterLevel.png'),
    port: '',
    value: 0,
  },
  {
    index: 118,
    categoryEng: 'Sensor',
    key: '按钮传感器',
    picture: require('../../img/harmaony/sensor/button.png'),
    port: '',
    value: 0,
  },
]
