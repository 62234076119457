<template>
  <div
    id="leftbar"
    :style="mqttDisplay ? (groupDisplay ? 'width: 5.75rem' : 'width: 3rem') : groupDisplay ? 'width: 3rem' : 'width: 0'"
  >
    <div
      class="central"
      :style="
        mqttDisplay ? (groupDisplay ? 'width: 5.5rem' : 'width: 2.75rem') : groupDisplay ? 'width: 2.75rem' : 'width: 0'
      "
    >
      <i
        :class="groupDisplay ? 'el-icon-arrow-left' : 'el-icon-arrow-right'"
        :style="
          mqttDisplay
            ? groupDisplay
              ? 'right: 2.75rem'
              : 'right: 2.625rem;border-radius: 0 .05rem .05rem 0;'
            : groupDisplay
            ? 'right: 0'
            : 'right: -0.0625rem;border-radius: 0 .05rem .05rem 0;'
        "
        @click="groupDisplay = !groupDisplay"
      ></i>
      <i
        :class="mqttDisplay ? 'el-icon-arrow-left' : 'el-icon-arrow-right'"
        :style="
          mqttDisplay
            ? groupDisplay
              ? 'right: 0rem'
              : 'right: 0rem;'
            : groupDisplay
            ? 'right: 0'
            : 'right: -0.0625rem;border-radius: 0 .05rem .05rem 0;'
        "
        @click="mqttDisplay = !mqttDisplay"
      ></i>
      <div class="joinGroup" @click="updateNowGroup('')" :style="groupDisplay ? 'z-index: 1' : ''">
        <div style="height: 100%;overflow: hidden" :style="groupDisplay ? 'width: 2.725rem' : 'width: 0'">
          <div class="title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 30 30"
              :style="groupDisplay ? 'z-index: 1' : 'z-index: -1'"
            >
              <g>
                <path
                  class="st0"
                  d="M23.93,18.08c1.73-0.77,2.94-2.5,2.94-4.51c0-2.72-2.22-4.94-4.94-4.94s-4.94,2.22-4.94,4.94   c0,1.7,0.86,3.2,2.17,4.09c-0.32,0.05-0.64,0.1-0.96,0.19l0.5,1.91c0.65-0.17,1.32-0.25,2-0.25c3.34,0,6.33,2.12,7.45,5.27   L30,24.11C28.98,21.23,26.71,19.05,23.93,18.08z M21.93,10.61c1.63,0,2.96,1.33,2.96,2.96s-1.33,2.96-2.96,2.96   c-1.63,0-2.96-1.33-2.96-2.96S20.29,10.61,21.93,10.61z"
                />
                <path
                  class="st0"
                  d="M13.48,16.25c1.38-1.09,2.27-2.77,2.27-4.66c0-3.27-2.66-5.93-5.93-5.93S3.89,8.33,3.89,11.6   c0,1.89,0.89,3.57,2.27,4.66c-3.3,1.31-5.75,4.36-6.16,8.08l1.96,0.22c0.44-4.01,3.82-7.03,7.85-7.03s7.41,3.02,7.85,7.03   l1.96-0.22C19.23,20.61,16.78,17.57,13.48,16.25z M5.87,11.6c0-2.18,1.77-3.95,3.95-3.95s3.95,1.77,3.95,3.95S12,15.55,9.82,15.55   S5.87,13.78,5.87,11.6z"
                />
              </g>
            </svg>
            <div>创建/加入小组</div>
          </div>
          <div class="connectionList">
            <div class="connectionType" @click="show = !show">
              <svg
                :style="show ? '' : 'transform: rotate(-90deg)'"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 20 20"
              >
                <polygon xmlns="http://www.w3.org/2000/svg" class="st0" points="10,13.02 13.49,6.98 6.51,6.98 " />
              </svg>
              <div>我创建的小组</div>
            </div>
            <!-- style="max-height: calc(100% - 1rem);overflow: auto;" -->
            <div>
              <el-collapse-transition>
                <div v-show="show">
                  <div
                    class="connectionItem"
                    :class="item.group_name == nowGroup ? 'groupActive' : ''"
                    v-for="item in groupList.admin"
                    :key="item.index"
                    @click.stop="chooseGroup(item.group_name)"
                  >
                    <div class="connectionValue" :title="item.group_name">{{ item.group_name }}{{ item.index }}</div>
                    <div class="connectionLine"></div>
                    <el-dropdown class="connectionDropdown">
                      <span class="el-dropdown-link">
                        <i class="el-icon-more"></i>
                      </span>
                      <el-dropdown-menu class="connectionDropdownMenu" slot="dropdown">
                        <el-dropdown-item @click.native="editGroup(item.group_name, 'view')">查看</el-dropdown-item>
                        <el-dropdown-item @click.native="editGroup(item.group_name, 'delete')">解散</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
            <div class="connectionType" @click="show1 = !show1">
              <svg
                :style="show1 ? '' : 'transform: rotate(-90deg)'"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 20 20"
              >
                <polygon xmlns="http://www.w3.org/2000/svg" class="st0" points="10,13.02 13.49,6.98 6.51,6.98 " />
              </svg>
              <div>我加入的小组</div>
            </div>
            <!-- style="max-height: calc(100% - 1rem);overflow: auto;" -->
            <div>
              <el-collapse-transition>
                <div v-show="show1">
                  <div
                    class="connectionItem"
                    :class="item.group_name == nowGroup ? 'groupActive' : ''"
                    v-for="item in groupList.common"
                    :key="item.index"
                    @click.stop="chooseGroup(item.group_name)"
                  >
                    <div class="connectionValue" :title="item.group_name">{{ item.group_name }}{{ item.index }}</div>
                    <div class="connectionLine"></div>
                    <el-dropdown class="connectionDropdown">
                      <span class="el-dropdown-link">
                        <i class="el-icon-more"></i>
                      </span>
                      <el-dropdown-menu class="connectionDropdownMenu" slot="dropdown">
                        <el-dropdown-item @click.native="editGroup(item.group_name, 'exit')">退出</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
          </div>
          <div class="addBtn" @click.stop="$emit('openDialog', { handle: 'creat', name: 'jionGroup' })">
            <i class="el-icon-plus"></i>
          </div>
        </div>
      </div>

      <transition name="el-zoom-in-center">
        <div class="transition-box centralLine" v-show="groupDisplay ? (mqttDisplay ? true : false) : false"></div>
      </transition>

      <div class="mqttAndTopic">
        <div
          style="height: calc(50% - 0.0625rem);overflow: hidden;"
          :style="mqttDisplay ? 'width: 2.725rem' : 'width: 0'"
        >
          <div class="title">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 30 30">
              <g>
                <path
                  class="st0"
                  d="M17.52,12.97c-0.16-0.16-0.32-0.3-0.49-0.43l0,0c-0.18-0.17-0.42-0.27-0.68-0.27c-0.54,0-0.98,0.44-0.98,0.98   c0,0.24,0.09,0.46,0.23,0.63c0,0,0,0,0,0c0.07,0.08,0.15,0.15,0.24,0.21c0.09,0.08,0.18,0.15,0.27,0.24l0.08,0.08   c1.02,1.01,0.71,2.76-0.3,3.78l-4.33,4.33c-1.02,1.01-2.67,1.01-3.68,0l-0.08-0.08c-1.02-1.02-1.02-2.67,0-3.68l1.91-1.91   c0.25-0.19,0.4-0.5,0.4-0.83c0-0.59-0.48-1.06-1.06-1.06c-0.22,0-0.43,0.07-0.6,0.18c0,0,0,0,0,0l-0.02,0.02   c-0.08,0.05-0.14,0.12-0.2,0.19l-1.99,1.86c-1.87,1.87-1.87,4.93,0,6.8l0.08,0.08c1.87,1.87,4.93,1.87,6.8,0l4.33-4.33   c1.87-1.87,2.03-4.82,0.16-6.69L17.52,12.97z"
                />
                <path
                  class="st0"
                  d="M23.77,6.62l-0.08-0.08c-1.87-1.87-4.93-1.87-6.8,0l-4.33,4.33c-1.87,1.87-1.97,4.57-0.1,6.44l0.08,0.08   c0.08,0.08,0.17,0.16,0.26,0.24c0.07,0.07,0.14,0.14,0.23,0.18c0,0,0,0,0,0l0,0c0.14,0.08,0.29,0.12,0.46,0.12   c0.51,0,0.92-0.41,0.92-0.92c0-0.14-0.03-0.28-0.09-0.4c-0.12-0.27-0.35-0.44-0.51-0.6l-0.08-0.08c-1.02-1.02-0.63-2.49,0.39-3.51   l4.33-4.33c1.01-1.02,2.66-1.02,3.68,0l0.08,0.08c1.02,1.02,1.02,2.67,0,3.68l-1.91,1.91c-0.26,0.19-0.43,0.5-0.43,0.85   c0,0.58,0.47,1.06,1.06,1.06c0.2,0,0.39-0.06,0.55-0.16c0,0,0,0,0,0.01l0.03-0.03c0.09-0.06,0.17-0.14,0.24-0.22l2.01-1.86   C25.64,11.55,25.64,8.49,23.77,6.62L23.77,6.62z"
                />
              </g>
            </svg>
            <div>连接MQTT</div>
          </div>
          <div class="connectionList">
            <div
              class="connectionItem"
              :class="item.clientId == mqttList[nowMqttIndex].clientId ? 'connectionActive' : ''"
              v-for="(item, index) in mqttList"
              :key="index"
              @click="updateMqttIndex(index)"
            >
              <div class="connectionValue" :class="item.client.connected ? 'connected' : ''" :title="item.host">
                {{ item.host }}
              </div>
              <div class="connectionLine" :class="item.client.connected ? 'connectedLine' : ''"></div>
              <el-dropdown class="connectionDropdown">
                <span class="el-dropdown-link">
                  <i class="el-icon-more" :class="item.client.connected ? 'connectedIcon' : ''"></i>
                </span>
                <el-dropdown-menu class="connectionDropdownMenu" slot="dropdown">
                  <el-dropdown-item @click.native="connect(item)">连接</el-dropdown-item>
                  <el-dropdown-item @click.native="destroyConnection(item)">断开</el-dropdown-item>
                  <el-dropdown-item @click.native="editConnect(item)">编辑</el-dropdown-item>
                  <el-dropdown-item @click.native="deleteConnect(item, index)">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div class="addBtnl" @click="$emit('openDialog', { handle: 'creat', type: 'my', name: 'MQTTconnect' })">
            <i class="el-icon-plus"></i>
          </div>
          <div class="addBtnr" @click="$emit('openDialog', { handle: 'creat', type: 'other', name: 'MQTTconnect' })">
            <i class="el-icon-plus"></i>
          </div>
        </div>
        <div class="mqttAndTopicLine" :style="mqttDisplay ? 'width: 2.45rem' : 'width: 0'"></div>
        <div
          style="height: calc(50% - 0.0625rem);overflow: hidden;"
          :style="mqttDisplay ? 'width: 2.725rem' : 'width: 0'"
        >
          <div class="title">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 30 30">
              <g>
                <path
                  class="st0"
                  d="M19.54,5.13h-9.12c-1.46,0-2.65,1.19-2.65,2.65v15.35c0,0.86,0.49,1.61,1.28,1.95   c0.28,0.12,0.56,0.18,0.85,0.18c0.53,0,1.04-0.2,1.45-0.59l3.63-3.45l3.63,3.45c0.62,0.59,1.5,0.75,2.29,0.41   c0.79-0.34,1.28-1.09,1.28-1.95V7.78C22.19,6.32,21,5.13,19.54,5.13z M20.35,23.13c0,0.15-0.09,0.22-0.17,0.26   c-0.08,0.03-0.19,0.05-0.3-0.05l-4.9-4.65l-4.9,4.65c-0.11,0.1-0.22,0.09-0.3,0.05c-0.08-0.03-0.17-0.1-0.17-0.26V7.78   c0-0.45,0.36-0.81,0.81-0.81h9.12c0.45,0,0.81,0.36,0.81,0.81V23.13z"
                />
                <polygon
                  class="st0"
                  points="15.9,9.86 14.06,9.86 14.06,12.3 11.53,12.3 11.53,14.14 14.06,14.14 14.06,16.43 15.9,16.43    15.9,14.14 18.42,14.14 18.42,12.3 15.9,12.3  "
                />
              </g>
            </svg>
            <div>订阅主题</div>
          </div>
          <div class="connectionList">
            <div
              class="connectionItem"
              v-for="(item, index) in topicListprocess"
              :key="index"
              :class="item.topicName == nowTopic ? 'groupActive' : ''"
              @click="chooseTopic(item.topicName)"
            >
              <div class="connectionValue" :title="item.topicName">{{ item.topicName }}</div>
              <div class="connectionLine"></div>
              <el-dropdown class="connectionDropdown">
                <i class="el-icon-more"></i>
                <el-dropdown-menu class="connectionDropdownMenu" slot="dropdown">
                  <el-dropdown-item @click.native="doUnSubscribe(item.topicName)">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div class="addBtn" @click="$emit('openDialog', { handle: 'creat', name: 'subscribeTopic' })">
            <i class="el-icon-plus"></i>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      custom-class="viewPwdDialog"
      :before-close="handleClose"
    >
      <div slot="title" class="dialog-title">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 60 60">
          <g>
            <circle class="st0" cx="30.02" cy="30" r="29.5" />
            <path
              class="st1"
              d="M30.02,60c-16.54,0-30-13.46-30-30c0-16.54,13.46-30,30-30s30,13.46,30,30C60.02,46.54,46.56,60,30.02,60z    M30.02,1c-15.99,0-29,13.01-29,29s13.01,29,29,29c15.99,0,29-13.01,29-29S46.01,1,30.02,1z"
            />
            <g>
              <path
                class="st2"
                d="M30.46,29.22c-3.39,0-6.16,2.76-6.16,6.16c0,2.79,1.86,5.14,4.41,5.9v3.07c0,0.97,0.78,1.75,1.75,1.75    s1.75-0.78,1.75-1.75v-3.07c2.54-0.76,4.41-3.11,4.41-5.9C36.61,31.98,33.85,29.22,30.46,29.22z M30.46,38.03    c-1.46,0-2.66-1.19-2.66-2.66s1.19-2.66,2.66-2.66s2.66,1.19,2.66,2.66S31.92,38.03,30.46,38.03z"
              />
              <g>
                <path
                  class="st2"
                  d="M50.99,21.15c0.04,0.09,0.07,0.2,0.1,0.32c0.03,0.12,0.04,0.25,0.04,0.38c0,0.13-0.03,0.26-0.08,0.39     c-0.05,0.13-0.13,0.26-0.24,0.38c-0.12,0.12-0.23,0.23-0.33,0.32c-0.1,0.09-0.18,0.17-0.25,0.24c-0.08,0.08-0.16,0.15-0.23,0.21     l-2.84-2.84c0.12-0.11,0.27-0.25,0.43-0.4c0.17-0.16,0.31-0.28,0.42-0.37c0.14-0.11,0.29-0.19,0.44-0.24     c0.15-0.05,0.3-0.07,0.45-0.06c0.15,0.01,0.29,0.03,0.43,0.07c0.14,0.04,0.26,0.09,0.36,0.14c0.21,0.11,0.45,0.31,0.71,0.58     C50.65,20.56,50.85,20.85,50.99,21.15L50.99,21.15z"
                />
                <path
                  class="st2"
                  d="M39.16,28.56c0.06-0.06,0.2-0.2,0.43-0.43c0.22-0.23,0.5-0.51,0.83-0.84l5.6-5.6l2.84,2.85L45.6,27.8     l-1.21,1.23c-0.4,0.39-0.77,0.76-1.09,1.09c-0.32,0.33-0.59,0.6-0.8,0.81c-0.21,0.21-0.34,0.33-0.38,0.36     c-0.1,0.09-0.22,0.19-0.35,0.29c-0.13,0.1-0.27,0.18-0.41,0.24c-0.14,0.07-0.35,0.16-0.62,0.26c-0.27,0.1-0.56,0.2-0.86,0.3     c-0.3,0.1-0.58,0.18-0.85,0.25c-0.27,0.07-0.47,0.12-0.6,0.14c-0.27,0.03-0.46-0.01-0.55-0.12c-0.09-0.11-0.12-0.3-0.08-0.56     c0.02-0.14,0.07-0.35,0.14-0.61s0.16-0.55,0.24-0.83c0.09-0.29,0.17-0.55,0.25-0.8c0.08-0.24,0.15-0.41,0.2-0.5     c0.06-0.14,0.13-0.27,0.2-0.39C38.91,28.83,39.02,28.7,39.16,28.56L39.16,28.56z"
                />
              </g>
              <path
                class="st2"
                d="M43.95,35.39v7.66c0,2.26-1.83,4.08-4.08,4.08h-19.7c-2.26,0-4.09-1.83-4.09-4.09V31.11    c0-2.26,1.83-4.09,4.09-4.09h14.65c0.75,0,1.46-0.3,1.99-0.82l1.9-1.9c0.29-0.29,0.08-0.78-0.32-0.78H22.06l0-4.14    c0-4.06,2.94-7.67,6.98-8.15c4.8-0.57,8.9,3.16,8.93,7.85c0,0.84,0.6,1.62,1.43,1.77c1.11,0.2,2.07-0.65,2.07-1.72    c0-6.68-5.75-12.04-12.56-11.4c-5.93,0.56-10.35,5.78-10.35,11.74l0,4.24c-3.41,0.74-5.98,3.78-5.98,7.41v11.94    c0,4.19,3.4,7.58,7.58,7.58h19.7c4.18,0,7.59-3.4,7.59-7.58c0,0-0.01-8.2-0.02-11.22c0-0.41-0.49-0.61-0.78-0.32l-1.88,1.88    C44.25,33.92,43.95,34.64,43.95,35.39z"
              />
            </g>
          </g>
        </svg>
        <span class="title-text">查看密码</span>
        <div class="square"></div>
      </div>
      <el-form ref="password" class="password" hide-required-asterisk>
        <el-form-item label="原密码 :">
          <el-input v-model="oldPassword" :type="oldInput ? 'text' : 'password'">
            <i slot="suffix" :class="oldInput ? 'icon-zhengyan' : 'icon-biyan'" @click="oldInput = !oldInput"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="新密码 :">
          <el-input placeholder="请输入密码" v-model="newPassword" :type="newInput ? 'text' : 'password'">
            <i
              slot="suffix"
              v-show="newPassword.length > 0"
              :class="newInput ? 'icon-zhengyan' : 'icon-biyan'"
              @click="newInput = !newInput"
            ></i>
          </el-input>
        </el-form-item>
        <div>
          <el-button type="primary" @click="changePassword">修 改</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'leftbar',
  data() {
    return {
      mqttDisplay: true,
      groupDisplay: true,
      dialogName: '',
      activeIndex: '1',
      show: true,
      show1: true,
      dialogVisible: false,
      oldInput: false,
      newInput: false,
      oldPassword: '',
      newPassword: '',
    }
  },
  props: {
    groupList: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState(['mqttList', 'nowGroup', 'nowMqttIndex', 'nowTopic', 'mqttMessage']),
    topicListprocess: function() {
      if (this.mqttList.length > 0) {
        return this.mqttList[this.nowMqttIndex].topicList
      }
      return []
    },
    listenConnected: function() {
      try {
        if (this.mqttList.length > 0) {
          return this.mqttList[this.nowMqttIndex].client.connected
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
  },
  watch: {
    mqttDisplay(val) {
      if (val && this.groupDisplay) {
        this.$parent.tabPositionLeft = 460
        this.updateLefbarDisplay(false)
      } else {
        if (val || this.groupDisplay) {
          this.$parent.tabPositionLeft = 235
          this.updateLefbarDisplay(false)
        } else {
          this.$parent.tabPositionLeft = 10
          this.updateLefbarDisplay(true)
        }
      }
    },
    groupDisplay(val) {
      if (val && this.mqttDisplay) {
        this.$parent.tabPositionLeft = 460
        this.updateLefbarDisplay(false)
      } else {
        if (val || this.mqttDisplay) {
          this.$parent.tabPositionLeft = 235
          this.updateLefbarDisplay(false)
        } else {
          this.$parent.tabPositionLeft = 10
          this.updateLefbarDisplay(true)
        }
      }
    },
    nowMqttIndex(val) {
      this.$emit('updataTopicPrefix', this.mqttList[val].thirdparty)
      sessionStorage.setItem('nowMqttIndex', val)
      if (this.mqttList[val].topicList.length > 0) {
        this.updateNowTopic(this.mqttList[val].topicList[0].topicName)
      }
    },
    listenConnected(val) {
      if (!val) {
        this.clearTopicList()
      }
    },
  },
  mounted() {},
  methods: {
    ...mapMutations([
      'delMqttList',
      'updateNowGroup',
      'updateMqttIndex',
      'updateNowTopic',
      'delTopicList',
      'clearTopicList',
      'updateLefbarDisplay',
      'updateMqttMessage',
    ]),

    // 选择小组
    chooseGroup(name) {
      this.$parent.topicPrefix = 'group/' + name + '/'
      this.updateNowGroup(name)
      sessionStorage.setItem('nowGroup', name)
    },

    // group设置
    editGroup(data, type) {
      switch (type) {
        case 'view':
          this.dialogVisible = true
          this.$get('group/admin/view_password', {
            group_name: data,
          })
            .then(res => {
              if (res.code == 200) {
                this.oldPassword = res.data.Password
              } else {
                this.$notify({
                  type: 'warning',
                  title: '警告',
                  message: res.msg,
                })
              }
            })
            .catch(err => {
              this.$notify.error({
                title: '报错',
                message: err,
              })
            })
          break
        case 'delete':
          this.$post('group/admin/delete', {
            group_name: data,
          })
            .then(res => {
              if (res.code == 200) {
                this.$notify({
                  title: '解散成功',
                  type: 'success',
                })
                this.$get('/group/list')
                  .then(res => {
                    if (res.code == 200) {
                      this.$emit('updateGroupList', res.data.groups)
                    }
                  })
                  .catch(err => {
                    this.$notify.error({
                      title: '报错',
                      message: err,
                    })
                  })
              } else {
                this.$notify({
                  type: 'warning',
                  title: '警告',
                  message: res.msg,
                })
              }
            })
            .catch(err => {
              this.$notify.error({
                title: '报错',
                message: err,
              })
            })
          break
        case 'exit':
          this.$post('group/exit', {
            group_name: data,
          })
            .then(res => {
              if (res.code == 200) {
                this.$notify({
                  title: '退出成功',
                  type: 'success',
                })
                this.$get('/group/list')
                  .then(res => {
                    if (res.code == 200) {
                      this.$emit('updateGroupList', res.data.groups)
                    }
                  })
                  .catch(err => {
                    this.$notify.error({
                      title: '报错',
                      message: err,
                    })
                  })
              } else {
                this.$notify({
                  type: 'warning',
                  title: '警告',
                  message: res.msg,
                })
              }
            })
            .catch(err => {
              this.$notify.error({
                title: '报错',
                message: err,
              })
            })
          break
        default:
          break
      }
    },

    handleClose(done) {
      this.oldPassword = ''
      this.newPassword = ''
      done()
    },

    changePassword() {
      if (this.newPassword.length > 0) {
        this.$post('group/admin/change_password', {
          group_name: this.nowGroup,
          new_password: this.newPassword,
        })
          .then(res => {
            if (res.msg == 'ok') {
              this.$notify({
                title: '修改成功',
                type: 'success',
              })
              this.oldPassword = ''
              this.newPassword = ''
              this.dialogVisible = false
            } else {
              this.$notify({
                type: 'warning',
                title: '警告',
                message: res.msg,
              })
            }
          })
          .catch(err => {
            this.$notify.error({
              title: '报错',
              message: err,
            })
          })
      } else {
        this.$notify({
          type: 'warning',
          title: '警告',
          message: '新密码不能为空',
        })
      }
    },

    // 选择主题已连接MQTT
    // expansionConnection(data) {
    //   this.updateMqttIndex(data.clientId)
    // },

    // 连接主题
    connect(data) {
      if (!data.client.connected) {
        this.$parent.createConnection(data, 'reconnection')
      }
    },

    // 断开MQTT连接
    destroyConnection(data) {
      if (data.client.connected) {
        try {
          data.client.end(false, () => {
            data.client = {
              connected: false,
            }
            this.clearTopicList()
            this.$notify({
              title: '断开连接成功',
              type: 'success',
            })
          })
        } catch (error) {
          this.$notify.error({
            title: '断开连接失败',
            message: error.toString(),
          })
        }
      }
    },

    // 打开连接编辑弹框
    editConnect(data) {
      this.$parent.openDialogFun(
        {
          handle: 'edit',
          type: 'my',
          name: 'MQTTconnect',
        },
        data,
      )
    },

    // 删除MQTT连接
    deleteConnect(data, index) {
      if (data.client.connected) {
        try {
          data.client.end(false, () => {
            data.client = {
              connected: false,
            }
            this.updateMqttMessage(
              this.mqttMessage.filter(item => {
                return item.clientId != data.clientId
              }),
            )
            this.clearTopicList()
            this.delMqttList(index)
            this.$notify({
              title: '删除连接成功',
              type: 'success',
            })
          })
        } catch (error) {
          this.$notify.error({
            title: '删除连接失败',
            message: error.toString(),
          })
        }
      } else {
        this.updateMqttMessage(
          this.mqttMessage.filter(item => {
            return item.clientId != data.clientId
          }),
        )
        this.delMqttList(index)
      }
    },

    // 选择主题
    chooseTopic(data) {
      this.updateNowTopic(data)
    },

    // 打开主题编辑弹框
    editTopic(key, value) {
      let obj = {}
      this.$set(obj, key, value)
      this.$emit('openDialog', {
        type: 'edit',
        name: 'subscribeTopic',
        data: obj,
      })
    },

    // 退订主题
    doUnSubscribe(name) {
      this.mqttList[this.nowMqttIndex].client.unsubscribe(name, error => {
        if (error) {
          this.$notify.error({
            title: '报错',
            message: error,
          })
        }
        this.mqttList[this.nowMqttIndex].topicList.forEach((item, index) => {
          if (item.topicName == name) {
            this.delTopicList(index)
          }
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
#leftbar {
  float: left;
  height: calc(100% - 0.75rem);
  transition: 0.3s width ease-in-out;
  .central {
    position: relative;
    height: calc(100% - 0.125rem);
    background-color: var(--leftbar0);
    margin: 0 0.125rem 0.125rem 0.125rem;
    border-radius: 0.0625rem;
    transition: 0.3s width ease-in-out;
    box-shadow: 0.0125rem 0.0125rem 0.0625rem 0 rgba(0, 0, 0, 0.25);
    > i {
      position: absolute;
      top: 50%;
      padding: 0.1rem 0;
      border-radius: 0.0625rem 0 0 0.0625rem;
      z-index: 2;
      transition: 0.3s right ease-in-out;
      transform: translateY(-170%);
      font-size: 0.2rem;
      font-weight: 800;
      cursor: pointer;
      color: var(--leftbar1);
      background-color: var(--leftbar2);
      &:hover {
        background-color: var(--leftbar3);
      }
      &:last-of-type {
        transform: translateY(-70%) !important;
      }
    }
    .centralLine {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0.0125rem;
      height: calc(100% - 0.25rem);
      border-radius: 0.0625rem;
      background-color: var(--leftbar4);
    }
    .joinGroup,
    .mqttAndTopic {
      position: absolute;
      height: 100%;
      width: 2.725rem;
      overflow: hidden;
      transition: 0.3s width ease-in-out;
      color: var(--leftbar5);
      .title {
        display: flex;
        justify-content: center;
        height: 0.625rem;
        line-height: 0.625rem;
        font-size: 0.25rem;
        color: var(--leftbar6);
        background-color: var(--leftbar7);
        border-radius: 0.0625rem 0.0625rem 0 0;
        overflow: hidden;
        svg {
          fill: var(--leftbar8);
          height: 0.375rem;
          width: 0.375rem;
          margin-top: 0.125rem;
        }
        div {
          margin-left: 0.125rem;
        }
      }
      .connectionList {
        height: calc(100% - 1.25rem);
        background-color: var(--leftbar9);
        font-size: 0.2rem;
        overflow: auto;
        .connectionType {
          display: flex;
          justify-content: left;
          height: 0.5rem;
          line-height: 0.5rem;
          font-size: 0.2rem;
          color: var(--leftbar10);
          svg {
            height: 0.25rem;
            width: 0.25rem;
            margin-top: 0.125rem;
            transition: all 0.2s;
            .st0 {
              fill: var(--leftbar11);
            }
          }
        }
        .connectionItem {
          position: relative;
          width: 2.5rem;
          height: 1rem;
          line-height: 1rem;
          color: var(--leftbar12);
          background-color: var(--leftbar13);
          border-radius: 0.0625rem;
          margin: 0 auto 0.1rem;
          .connectionValue {
            width: 1.975rem;
            margin: 0 auto;
            font-size: 0.2rem;
            font-weight: 700;
            line-height: 0.675rem;
            overflow: hidden;
            white-space: nowrap;
            text-align: left;
            text-overflow: ellipsis;
          }
          .connectionLine {
            position: absolute;
            bottom: 0.2rem;
            left: 0.2875rem;
            width: 0.5rem;
            height: 0.125rem;
            background-color: var(--leftbar14);
            border-radius: 0.125rem;
          }
          .connectionDropdown::v-deep {
            position: absolute;
            bottom: 0.0625rem;
            right: 0.225rem;
            width: 0.5rem;
            height: 0.375rem;
            line-height: 0.375rem;
            .el-icon-more {
              vertical-align: top;
              font-size: 0.325rem;
              color: var(--leftbar14);
            }
            .el-icon-more.connectedIcon {
              color: var(--leftbar15);
            }
          }
          .topicName {
            padding: 0 0.825rem 0 0.35rem;
            text-align: right;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .connected {
            color: var(--leftbar15);
          }
          .connectionLine.connectedLine {
            background-color: var(--leftbar15);
          }
        }
        .groupActive {
          color: var(--leftbar15);
          background-color: var(--leftbar16);
          .connectionLine {
            background-color: var(--leftbar15);
          }
          .connectionDropdown::v-deep {
            .el-icon-more {
              color: var(--leftbar15);
            }
          }
        }
        .connectionActive {
          background-color: var(--leftbar16);
        }
      }
      .addBtn,
      .addBtnl,
      .addBtnr {
        position: relative;
        margin: 0.125rem auto 0;
        width: 2.5rem;
        height: 0.375rem;
        background-color: var(--leftbar17);
        border-radius: 0.0625rem;
        cursor: pointer;
        .el-icon-plus {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-weight: 800;
          font-size: 0.1875rem;
          color: var(--leftbar18);
        }
      }
      .addBtnl,
      .addBtnr {
        float: left;
        width: 44%;
        margin-left: 4%;
      }
    }
    .mqttAndTopic {
      right: 0;
      .mqttAndTopicLine {
        height: 0.025rem;
        width: 2.45rem;
        border-radius: 0.1rem;
        margin: 0 auto 0.1125rem;
        background-color: var(--leftbar19);
      }
      .title {
        img {
          margin-bottom: 0;
          width: 0.2rem;
        }
      }
      .connectionList {
        padding-top: 0.125rem;
      }
    }
  }
}
.connectionDropdownMenu::v-deep {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0.0625rem;
  box-shadow: 0.0125rem 0.0125rem 0.0625rem 0 rgba(0, 0, 0, 0.25);
  background-color: var(--leftbar20);
  .popper__arrow {
    border-bottom-color: var(--leftbar20);
    &::after {
      border-bottom-color: var(--leftbar20);
    }
  }
  .el-dropdown-menu__item {
    padding: 0.125rem;
    color: var(--leftbar21);
    font-size: 0.2rem;
    line-height: 1;
    border-radius: 0.0625rem;
    &:focus,
    &:not(.is-disabled):hover {
      color: var(--leftbar22);
      background-color: var(--leftbar23);
    }
  }
}
.el-dialog__wrapper::v-deep {
  font-size: 0.25rem;
  .el-dialog {
    top: 50%;
    transform: translateY(-50%);
    width: 7.5rem;
    margin-top: 0 !important;
    border-radius: 0.375rem;
    background-color: var(--loginView0);
    .el-dialog__header {
      padding: 0;
      height: 1.375rem;
      line-height: 1.375rem;
      .dialog-title {
        position: relative;
        display: flex;
        justify-content: center;
        font-size: 0.25rem;
        color: var(--loginView5);
        height: 100%;
        svg {
          height: 0.75rem;
          width: 0.75rem;
          margin-top: 0.3125rem;
          margin-right: 0.25rem;
          .st0 {
            fill: var(--loginView6);
          }
          .st1 {
            fill: var(--loginView7);
          }
          .st2 {
            fill: var(--loginView8);
          }
        }
        .square {
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 50%) rotate(45deg);
          height: 0.25rem;
          width: 0.25rem;
          background-color: var(--loginView0);
        }
      }
      .el-dialog__headerbtn {
        font-size: 0.25rem;
        top: 0.25rem;
        right: 0.25rem;
        .el-dialog__close {
          color: var(--loginView5);
        }
      }
      .el-dialog__headerbtn:focus .el-dialog__close,
      .el-dialog__headerbtn:hover .el-dialog__close {
        color: var(--loginView5);
      }
    }
    .el-dialog__body {
      padding: 0.625rem 1.25rem;
      font-size: 0.175rem;
      overflow: hidden;
      border-radius: 0.375rem;
      background-color: var(--loginView9);
      .password {
        .el-form-item {
          display: flex;
          justify-content: center;
          height: 0.375rem;
          line-height: 0.375rem;
          margin-bottom: 0.25rem;
          font-size: 0.2rem;
          .el-form-item__label {
            width: 1rem;
            font-size: 0.2rem;
            color: var(--index19);
            line-height: 0.375rem;
            padding: 0 0.1625rem 0 0;
          }
          .el-form-item__content {
            display: inline-block;
            font-size: 0.2rem;
            flex: 1;
            line-height: 0.375rem;
            .el-input {
              font-size: 0.2rem;
              height: 0.375rem;
              line-height: 0.375rem;
              .el-input__suffix {
                right: 0.0625rem;
                .el-input__icon {
                  width: 0.3125rem;
                }
              }
            }
            .el-input__icon {
              font-size: 0.2rem;
              line-height: 0.375rem;
            }
          }
          .el-input__inner {
            font-size: 0.2rem;
            height: 0.375rem;
            line-height: 0.375rem;
            border: none;
            border-radius: 0.0625rem;
            color: var(--index20);
            background-color: var(--index21);
            padding: 0 0.1875rem;
          }
        }
        .el-button {
          font-size: 0.2rem;
          width: 2.5rem;
          height: 0.5rem;
          background-color: var(--index25);
          border: none;
          border-radius: 0.0625rem;
          word-spacing: 0.125rem;
          margin-top: 0.25rem;
          padding: 0.15rem 0.25rem;
        }
      }
    }
  }
}
</style>
