<template>
  <div id="mainview" :style="lefbarDisplay ? 'padding-left: 0.125rem' : 'padding-left: 0'">
    <div style="display: flex;width: 100%;height: 100%">
      <div :style="widthChange ? 'width: calc(100% - 6.25rem)' : 'width: 100%'">
        <div class="tab">
          <div
            class="tabname"
            :class="radio == 'dataMonitor' ? 'active' : ''"
            :style="radio == 'dataQuery' ? 'border-radius: 0.0625rem 0 0 0' : 'border-radius: 0.0625rem 0 0.0625rem 0'"
            @click="radio = 'dataMonitor'"
          >
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 30 30">
                <path
                  class="st0"
                  d="M20.81,5.13H9C6.7,5.13,4.83,7,4.83,9.3v11.81c0,2.3,1.87,4.17,4.17,4.17h11.81c2.3,0,4.17-1.87,4.17-4.17V9.3  C24.97,7,23.1,5.13,20.81,5.13z M9,7.25h11.81c1.13,0,2.05,0.92,2.05,2.05v4.31l-4.24,1.06l-3.18-4.24l-4.24,5.3l-2.12-3.18  l-2.12,1.88V9.3C6.95,8.17,7.87,7.25,9,7.25z M20.81,23.15H9c-1.13,0-2.05-0.92-2.05-2.05V16.9l2.12-1.17l3.18,2.12l3.18-4.24  l3.18,4.24l4.24-1.7v4.95C22.85,22.24,21.93,23.15,20.81,23.15z"
                />
              </svg>
              <div>数据监控</div>
            </div>
          </div>
          <div
            class="tabname"
            :class="radio == 'dataDispose' ? 'active' : ''"
            :style="
              radio == 'dataDispose'
                ? 'border-radius: 0 0 0.0625rem 0.0625rem'
                : radio == 'dataMonitor'
                ? 'border-radius: 0 0 0 0.0625rem'
                : 'border-radius: 0 0 0.0625rem 0'
            "
            @click="radio = 'dataDispose'"
          >
            <div style="justify-content: space-between;">
              <div v-show="radio == 'dataQuery'" class="tabnameLine"></div>
              <div style="display: flex;justify-content: center;flex: 1">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 30 30">
                  <g>
                    <g>
                      <g>
                        <path
                          class="st0"
                          d="M16.78,25.25h-3.6c-0.74,0-1.37-0.53-1.49-1.26l-0.26-1.85c-0.24-0.12-0.47-0.26-0.69-0.4l-1.71,0.69     c-0.69,0.28-1.49,0-1.86-0.65l-1.8-3.12C4.98,18,5.13,17.19,5.72,16.72l1.45-1.14c-0.01-0.13-0.01-0.27-0.01-0.4s0-0.27,0.01-0.4     l-1.45-1.13c-0.6-0.49-0.75-1.3-0.37-1.94l1.8-3.12C7.53,7.94,8.3,7.67,9,7.93l1.73,0.7c0.22-0.15,0.46-0.28,0.7-0.4l0.26-1.82     c0.13-0.76,0.75-1.29,1.5-1.29h3.6c0.73,0,1.35,0.52,1.49,1.24l0.27,1.87c0.24,0.12,0.47,0.26,0.69,0.4l1.71-0.69     c0.69-0.28,1.49,0,1.86,0.64l1.8,3.12c0.37,0.66,0.22,1.47-0.37,1.94l-1.45,1.14c0.01,0.13,0.01,0.27,0.01,0.4     c0,0.14,0,0.27-0.01,0.4l1.45,1.13c0.6,0.49,0.75,1.3,0.37,1.94l-1.8,3.12c-0.37,0.64-1.15,0.91-1.84,0.66l-1.73-0.7     c-0.22,0.15-0.46,0.28-0.7,0.41l-0.26,1.82C18.14,24.73,17.52,25.25,16.78,25.25z M13.7,23.14h2.55l0.35-2.45l0.56-0.23     c0.46-0.19,0.89-0.44,1.28-0.75l0.48-0.37l2.31,0.93l1.27-2.2l-1.95-1.53l0.08-0.6c0.03-0.25,0.05-0.49,0.05-0.75     c0-0.25-0.02-0.5-0.05-0.75l-0.08-0.6l1.96-1.53l-1.27-2.2l-2.31,0.93l-0.48-0.37c-0.41-0.31-0.84-0.56-1.29-0.75L16.6,9.69     l-0.35-2.45H13.7l-0.35,2.45L12.8,9.92c-0.46,0.19-0.89,0.44-1.28,0.75l-0.48,0.37l-2.31-0.93l-1.27,2.2l1.95,1.53l-0.07,0.59     c-0.03,0.25-0.05,0.49-0.05,0.75c0,0.25,0.02,0.5,0.06,0.75l0.07,0.59l-1.95,1.53l1.27,2.2l2.31-0.93l0.48,0.37     c0.41,0.31,0.84,0.56,1.29,0.75l0.56,0.23L13.7,23.14z M22.76,17.6C22.76,17.6,22.76,17.6,22.76,17.6L22.76,17.6z M7.19,12.76     L7.19,12.76C7.19,12.77,7.19,12.77,7.19,12.76z"
                        />
                      </g>
                    </g>
                    <g>
                      <path
                        class="st0"
                        d="M14.98,19.4c-2.32,0-4.21-1.89-4.21-4.21s1.89-4.21,4.21-4.21c2.32,0,4.21,1.89,4.21,4.21    S17.3,19.4,14.98,19.4z M14.98,13.09c-1.15,0-2.09,0.94-2.09,2.09s0.94,2.09,2.09,2.09c1.15,0,2.09-0.94,2.09-2.09    S16.13,13.09,14.98,13.09z"
                      />
                    </g>
                  </g>
                </svg>
                <div>数据看板</div>
              </div>
              <div v-show="radio == 'dataMonitor'" class="tabnameLine"></div>
            </div>
          </div>
          <div
            class="tabname"
            :class="radio == 'dataQuery' ? 'active' : ''"
            :style="
              radio == 'dataMonitor' ? 'border-radius: 0 0.0625rem 0 0' : 'border-radius: 0 0.0625rem 0 0.0625rem'
            "
            style="width: 33.34%"
            @click="radio = 'dataQuery'"
          >
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 30 30">
                <path
                  class="st0"
                  d="M15.02,5.11c-3.95,0-7.16,3.21-7.16,7.16c0,2,0.83,3.81,2.16,5.11l-3.5,5.29c-0.49,0.75-0.29,1.75,0.46,2.24  c0.28,0.18,0.59,0.27,0.89,0.27c0.53,0,1.04-0.25,1.35-0.73l3.57-5.4c0.7,0.23,1.46,0.36,2.24,0.36c3.95,0,7.16-3.21,7.16-7.16  S18.97,5.11,15.02,5.11z M15.02,17.28c-2.76,0-5-2.24-5-5s2.24-5,5-5s5,2.24,5,5S17.78,17.28,15.02,17.28z"
                />
              </svg>
              <div>数据查询</div>
            </div>
          </div>
        </div>

        <dataMonitor v-show="radio == 'dataMonitor'" ref="dataMonitor" :receiveNews="receiveNews" />
        <dataDispose v-show="radio == 'dataDispose'" ref="dataDispose" />
        <dataQuery v-show="radio == 'dataQuery'" ref="dataQuery" />
      </div>
      <logModule ref="logModule" :tabName="radio" @logDisplay="logDisplayFun" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import dataMonitor from '@/component/dataMonitor'
import dataDispose from '@/component/dataDispose'
import dataQuery from '@/component/dataQuery'
import logModule from '@/component/logModule'

export default {
  name: 'mainview',
  data() {
    return {
      radio: 'dataMonitor',
      widthChange: false,
    }
  },
  components: {
    dataMonitor,
    dataDispose,
    dataQuery,
    logModule,
  },
  props: ['receiveNews'],
  watch: {
    // nowMqttIndex(value) {
    //   this.connectionList.forEach((item, index) => {
    //     if (item.clientId == value) {
    //       this.connectionListIndex = index
    //       this.groupList = item.groupList
    //     }
    //   })
    // },
  },
  computed: {
    ...mapState(['loginStatus', 'nowMqttIndex', 'nowTopic', 'lefbarDisplay']),
  },
  mounted() {
    this.radio = 'dataMonitor'
  },
  methods: {
    ...mapMutations(['updateMqttIndex', 'updateNowTopic']),

    getNowDate() {
      let myDate = new Date()
      return (
        myDate.getFullYear() +
        '-' +
        (myDate.getMonth() + 1) +
        '-' +
        myDate.getDate() +
        ' ' +
        myDate.getHours() +
        ':' +
        myDate.getMinutes() +
        ':' +
        myDate.getSeconds()
      )
    },

    logDisplayFun(data) {
      this.widthChange = data
    },
  },
}
</script>
<style lang="scss" scoped>
#mainview {
  position: relative;
  height: calc(100% - 0.75rem);
  overflow: hidden;
  .tab {
    position: absolute;
    top: 0;
    display: flex;
    height: 0.625rem;
    width: 44.45%;
    min-width: 6.5rem;
    line-height: 0.5rem;
    transition: 0.3s left ease-in-out;
    z-index: 1;
    color: var(--mainview0);
    background-color: var(--mainview1);
    box-shadow: 0.0125rem 0 0.0625rem rgba(0, 0, 0, 0.25);
    border-radius: 0.125rem 0.125rem 0px 0px;
    .tabname {
      width: 33.33%;
      height: 0.6rem;
      line-height: 0.625rem;
      font-size: 0.25rem;
      letter-spacing: 0.0625rem;
      background-color: var(--mainview2);
      > div {
        display: flex;
        justify-content: center;
        border-radius: 0;
        svg {
          height: 0.375rem;
          width: 0.375rem;
          margin-top: 0.125rem;
          margin-right: 0.0625rem;
          .st0 {
            fill: var(--mainview3);
          }
        }
        .tabnameLine {
          float: left;
          height: 0.375rem;
          width: 0.025rem;
          background-color: var(--mainview4);
          margin: 0.125rem 0;
          &:last-child {
            float: right;
          }
        }
      }
    }
    .active {
      color: var(--mainview5);
    }
    .active > div {
      width: 100%;
      background-color: var(--mainview6);
      border-radius: 0.0625rem 0.0625rem 0 0;
      svg {
        .st0 {
          fill: var(--mainview7);
        }
      }
    }
  }
}
</style>
